import { defineMessages } from 'react-intl';

export default defineMessages({
  errorMessageRequired: {
    id: 'error.message.required',
    defaultMessage: '$label is required.'
  },
  errorMessageNoAlphabeticalChar: {
    id: 'error.message.no.alphabetical.char',
    defaultMessage: '$label must contain at least 1 alphabetical character.'
  },
  errorMessageNoNumericChar: {
    id: 'error.message.no.numeric.char',
    defaultMessage: '$label must contain at least 1 numerical character.'
  },
  errorMessageNoSpecialChar: {
    id: 'error.message.no.special.char',
    defaultMessage: '$label must contain at least 1 special character.'
  },
  errorMessageNotEqual: {
    id: 'error.message.not.equal',
    defaultMessage: '$label is not equal.'
  },
  errorMessageMinLength: {
    id: 'error.message.min.length',
    defaultMessage: '$label is too short.'
  },
  errorMessageMaxLength: {
    id: 'error.message.max.length',
    defaultMessage: '$label is too long.'
  },
  errorMessageEmail: {
    id: 'error.message.email',
    defaultMessage: '$label is not valid.'
  },
  errorMessageRequiredCheckbox: {
    id: 'error.message.required.checkbox',
    defaultMessage: '$label must be checked.'
  },
  errorMessageRequiredCheckboxGroup: {
    id: 'error.message.required.checkbox.group',
    defaultMessage: 'At least 1 item must be checked.'
  },
  errorMessageMinArrayLength: {
    id: 'error.message.min.array.length',
    defaultMessage: '$label is required.'
  },
  errorMessageRadioButton: {
    id: 'error.message.radio.button',
    defaultMessage: '$label is required.'
  },
  errorMessageFileUploadFailed: {
    id: 'error.message.file.upload.failed',
    defaultMessage: '$filename failed to upload.'
  },
  errorMessageFileUpdateFailed: {
    id: 'error.message.file.update.failed',
    defaultMessage: '$filename failed to update.'
  },
  errorMessageFileDeleteFailed: {
    id: 'error.message.file.delete.failed',
    defaultMessage: '$filename failed to delete.'
  },
  warningMessageNoOptions: {
    id: 'warning.message.no.options',
    defaultMessage: 'No options'
  },
  warningMessageCreateValue: {
    id: 'warning.message.create.value',
    defaultMessage: 'Create $value'
  },
  defaultMessagePlaceholder: {
    id: 'default.message.placeholder',
    defaultMessage: 'Enter $label...'
  },
  buttonLabelSave: {
    id: 'button.label.save',
    defaultMessage: 'Save'
  },
  buttonLabelCancel: {
    id: 'button.label.cancel',
    defaultMessage: 'Cancel'
  },
  buttonLabelOK: {
    id: 'button.label.ok',
    defaultMessage: 'OK'
  },
  buttonLabelLogin: {
    id: 'button.label.login',
    defaultMessage: 'Login'
  },
  buttonLabelLogout: {
    id: 'button.label.logout',
    defaultMessage: 'Logout'
  },
  buttonLabelBack: {
    id: 'button.label.back',
    defaultMessage: 'Back'
  },
  buttonLabelForgotPassword: {
    id: 'button.label.forgot.password',
    defaultMessage: 'Forgot password?'
  },
  buttonLabelResetPassword: {
    id: 'button.label.reset.password',
    defaultMessage: 'Reset password'
  },
  buttonLabelCreate: {
    id: 'button.label.create',
    defaultMessage: 'Create'
  },
  buttonLabelRemove: {
    id: 'button.label.remove',
    defaultMessage: 'Remove'
  },
  buttonLabelDelete: {
    id: 'button.label.delete',
    defaultMessage: 'Delete'
  },
  buttonLabelEdit: {
    id: 'button.label.edit',
    defaultMessage: 'Edit'
  },
  buttonLabelUpdate: {
    id: 'button.label.update',
    defaultMessage: 'Update'
  },
  buttonLabelReset: {
    id: 'button.label.reset',
    defaultMessage: 'Reset'
  },
  buttonLabelDetail: {
    id: 'button.label.detail',
    defaultMessage: 'Detail'
  },
  buttonLabelView: {
    id: 'button.label.view',
    defaultMessage: 'View'
  },
  buttonLabelUpload: {
    id: 'button.label.upload',
    defaultMessage: 'Upload'
  },
  buttonLabelSelectFiles: {
    id: 'button.label.select.files',
    defaultMessage: 'Select file(s)'
  },
  formLabelPassword: {
    id: 'form.label.password',
    defaultMessage: 'Password'
  },
  formLabelEmail: {
    id: 'form.label.email',
    defaultMessage: 'E-mail address'
  },
  formLabelConfirmPassword: {
    id: 'form.label.confirm.password',
    defaultMessage: 'Confirm password'
  },
  titleLabelLoginTitle: {
    id: 'title.label.login.title',
    defaultMessage: 'Welcome,'
  },
  titleLabelLoginSubtitle: {
    id: 'title.label.login.subtitle',
    defaultMessage: 'Login to your account..'
  },
  titleLabelForgotLoginTitle: {
    id: 'title.label.forgot.login.title',
    defaultMessage: 'Forgot your password?!'
  },
  titleLabelForgotLoginSubtitle: {
    id: 'title.label.forgot.login.subtitle',
    defaultMessage: 'Receive an e-mail for password reset'
  },
  titleLabelForgotLoginSuccessTitle: {
    id: 'title.label.forgot.login.success.title',
    defaultMessage: 'Forgot your password?!'
  },
  titleLabelForgotLoginSuccessSubtitle: {
    id: 'title.label.forgot.login.success.subtitle',
    defaultMessage: 'Receive an e-mail for password reset'
  },
  titleLabelSetPasswordTitle: {
    id: 'title.label.set.password.title',
    defaultMessage: 'Hi $name'
  },
  titleLabelSetPasswordSubtitle: {
    id: 'title.label.set.password.subtitle',
    defaultMessage: 'Create a new password to log in to your account'
  },
  cardLabelForgotLoginSuccess: {
    id: 'card.label.forgot.login.successs',
    defaultMessage: 'E-mail sent to: $email'
  },
  cardLabelLoading: {
    id: 'card.label.loading',
    defaultMessage: 'Loading...'
  }
});
