import gql from 'graphql-tag';
import { doMutation } from '../api';

export const QUERY_GET_ME = gql`
  query Me {
    getMe {
      id
      firstname
      lastname
      email
      personEntities {
        id
        entityId
        entity {
          id
          slug
        }
      }
    }
  }
`;

export const QUERY_GET_PERSONS_BY_ENTITY_ID = gql`
  query getEntity($entityId: ID!) {
    getEntity(entityId: $entityId) {
      id
      personEntities {
        id
        personId
        person {
          id
          firstname
          lastname
        }
      }
    }
  }
`;

export const QUERY_GET_PERSON_BY_ID = gql`
  query getPerson($personId: ID!) {
    getPerson(personId: $personId) {
      id
      firstname
      lastname
      email
      meta
    }
  }
`;

export const MUTATION_CREATE_LOGIN = gql`
  mutation createLogin($personId: ID!) {
    createLogin(personId: $personId)
  }
`;

export const createLogin = async (variables) => {
  const mutation = MUTATION_CREATE_LOGIN;
  return await doMutation({
    mutation,
    variables,
  });
};

export const MUTATION_RESET_LOGIN = gql`
  mutation resetLogin($personId: ID!) {
    resetLogin(personId: $personId)
  }
`;

export const resetLogin = async (variables) => {
  const mutation = MUTATION_RESET_LOGIN;
  return await doMutation({
    mutation,
    variables,
  });
};

export const MUTATION_ADD_PERSON_ENTITY = gql`
  mutation createPersonEntity(
    $personId: ID!
    $entityId: ID!
    $role: PersonEntityRoles
  ) {
    createPersonEntity(
      input: { personId: $personId, entityId: $entityId, role: $role }
    ) {
      id
      personId
    }
  }
`;

export const MUTATION_ADD_PERSON = gql`
  mutation createPerson(
    $email: String
    $firstname: String!
    $lastname: String!
    $meta: AWSJSON
  ) {
    createPerson(
      input: {
        email: $email
        firstname: $firstname
        lastname: $lastname
        meta: $meta
      }
    ) {
      id
    }
  }
`;

export const MUTATION_UPDATE_PERSON = gql`
  mutation editPerson(
    $id: ID!
    $email: String
    $firstname: String
    $lastname: String
    $meta: AWSJSON
  ) {
    editPerson(
      input: {
        id: $id
        email: $email
        firstname: $firstname
        lastname: $lastname
        meta: $meta
      }
    ) {
      id
    }
  }
`;

export const MUTATION_REMOVE_PERSON = gql`
  mutation archivePersonEntity($personEntityId: ID!) {
    archivePersonEntity(personEntityId: $personEntityId)
  }
`;
