import React from 'react';

import classNames from 'classnames';

import Icon from '../../icon/Icon';
import { Button } from '../../button';

class PageHeader extends React.Component {
  render() {
    const { fixed, showMenu, children } = this.props;
    return (
      <header
        className={classNames('c-page__header o-wrapper', {
          'c-page__header--fixed': fixed
        })}
      >
        {showMenu && (
          <Button
            // onClick={showMenu}
            ghost
            extraClassNames="c-button--sidebar-trigger"
          >
            <Icon id="close" />
          </Button>
        )}
        {children}
      </header>
    );
  }
}

export default PageHeader;
