import React from 'react';
import classNames from 'classnames';
import './NotificationPill.scss';

class NotificationPill extends React.Component {
  render() {
    const { children, extraClassNames, show, success, error } = this.props;
    return (
      <span
        className={classNames('c-notification-pill', extraClassNames, {
          'c-notification-pill--show': show,
          'c-notification-pill--success': success,
          'c-notification-pill--error': error
        })}
      >
        {children}
      </span>
    );
  }
}

export default NotificationPill;
