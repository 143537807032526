import React from 'react';

import classNames from 'classnames';

class CardThumbnail extends React.Component {
  render() {
    const { src, alt } = this.props;
    return (
      <img className={classNames('c-card__thumbnail')} alt={alt} src={src} />
    );
  }
}

export default CardThumbnail;
