import { defineMessages } from 'react-intl';

export default defineMessages({
  titleLabelFilemanager: {
    id: 'title.label.filemanager',
    defaultMessage: 'File manager'
  },
  fileManagerUploadFilesLabel: {
    id: 'filemanager.upload.files.label',
    defaultMessage: 'Upload file(s)'
  },
  modalConfirmFileManagerRemoveFileTitle: {
    id: 'modal.confirm.filemanager.remove.file.title',
    defaultMessage: 'Remove file'
  },
  modalConfirmFileManagerRemoveFileMessage: {
    id: 'modal.confirm.filemanager.remove.file.message',
    defaultMessage: 'Are you sure you want to delete this file?'
  }
});
