import React from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { Switch, Route } from 'react-router-dom';
import {
  ROUTE_TOPICS,
  ROUTE_SLUG_CARDS,
  ROUTE_TOPIC_ADD,
  ROUTE_TOPIC_EDIT,
  ROUTE_TOPIC
} from '../../../../routes/RouteList';
import Topics from './Topics';
import CardsSlugPage from './cards';
import AddTopic from './AddTopic';
import Topic from './Topic';

const TopicsPage = () => {
  return (
    <Switch>
      <Route path={ROUTE_SLUG_CARDS} component={() => <CardsSlugPage />} />
      <Route
        path={ROUTE_TOPIC_ADD}
        component={props => (
          <AddTopic groupSlug={props.match.params.groupSlug} />
        )}
      />
      <Route
        path={ROUTE_TOPIC_EDIT}
        component={props => (
          <Topic
            edit={true}
            groupSlug={props.match.params.groupSlug}
            topicSlug={props.match.params.topicSlug}
          />
        )}
      />
      <Route
        path={ROUTE_TOPIC}
        component={props => (
          <Topic
            edit={false}
            groupSlug={props.match.params.groupSlug}
            topicSlug={props.match.params.topicSlug}
          />
        )}
      />
      <Route
        path={ROUTE_TOPICS}
        component={props => <Topics groupSlug={props.match.params.groupSlug} />}
      />
    </Switch>
  );
};
export default hooksObserver(TopicsPage);
