import React, { useContext } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { StoresContext } from '../../../stores';
import { form } from '@dc-framework/js-utils';
import { MutationForm, Form } from '../../../lib/containers/form';
import { ButtonsGroup } from '../../../lib/components/button';
import { defaultFormMessages } from '../../../lib/helpers/copy/default-copy';
import {
  MUTATION_UPDATE_TAG,
  MUTATION_ADD_TAG,
  QUERY_GET_TAGS
} from '../../../services/graphql/tag-query';
import messages from './messages';
import { injectIntl } from 'react-intl';

const {
  VALIDATE_TYPES: { ERROR_UNKNOWN }
} = form;

const TagForm = ({
  intl,
  edit = true,
  onEdit,
  rootEntityId,
  tag,
  loading,
  refetchQueries,
  onCancel,
  onMutationComplete
}) => {
  const { uiState } = useContext(StoresContext);
  const MUTATION = tag ? MUTATION_UPDATE_TAG : MUTATION_ADD_TAG;
  return (
    <>
      <MutationForm
        edit={edit}
        loading={loading}
        mutation={MUTATION}
        onCancel={onCancel}
        onEdit={onEdit}
        errorMessage={defaultFormMessages[ERROR_UNKNOWN]}
        extraMutationVariables={{
          id: tag ? tag.id : undefined
        }}
        getMutationVariables={data => {
          data.name = data.name.trim();
          return data;
        }}
        refetchQueries={
          refetchQueries
            ? refetchQueries
            : [
                {
                  query: QUERY_GET_TAGS
                }
              ]
        }
        onMutationComplete={() => {
          uiState.showNotification({
            message: intl.formatMessage(messages.notificationMessageTagSaved)
          });
          onMutationComplete();
        }}
      >
        <Form.ItemWrapper
          formItems={[
            {
              key: 'name',
              label: 'Name',
              value: tag ? tag.name : '',
              required: true
            }
          ]}
        />
        <ButtonsGroup>
          <Form.EditButton />
          <Form.SubmitButton primary />
          <Form.CancelButton secondary />
        </ButtonsGroup>
      </MutationForm>
    </>
  );
};
export default injectIntl(hooksObserver(TagForm));
