import React, { useContext } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { useQuery } from 'react-apollo-hooks';
import { FormattedMessage, injectIntl } from 'react-intl';
import { HashLink } from 'react-router-hash-link';
import { StoresContext } from '../../../stores';
import { defaultFormButtons } from '../../../lib/helpers/copy/default-copy';
import { Wrapper } from '../../../lib/components/page-layout';
import {
  QUERY_GET_CARD_BY_SLUG,
  refetchCard
} from '../../../services/graphql/card-query';
import CardForm from '../groups/topics/cards/CardForm';
import { ButtonsGroup } from '../../../lib/components/button/ButtonsGroup';
import { getPath } from './path-generator';
import messages from './messages';
import { ROUTE_CARD_EDIT, ROUTE_CARDS } from '../../../routes/RouteList';
import { Icon } from '../../../lib/components/icon';
import { default as ModelCard } from '../../../models/Card';
import Loader from '../../../components/loader/Loader';

const Card = ({ intl, cardSlug, edit }) => {
  const { routing, uiState } = useContext(StoresContext);

  const { data, error, loading } = useQuery(QUERY_GET_CARD_BY_SLUG, {
    variables: { cardSlug }
  });

  if (loading)
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  if (error) return <Wrapper>{`Error! ${error.message}`}</Wrapper>;

  const onNoEdit = () => routing.push(getPath(ROUTE_CARDS));
  const onEdit = () => routing.push(getPath(ROUTE_CARD_EDIT, cardSlug));

  return (
    <>
      <div className="o-layout">
        <div className="o-layout__item u-1-of-5-at-medium">
          <h2 className="u-margin-bottom-medium">
            <FormattedMessage {...messages.titleLabelEditCard} />
          </h2>
          <ButtonsGroup extraClassNames="u-margin-bottom-large">
            <HashLink
              className="c-button c-button--ghost c-button--icon-left c-button--back-link"
              to={`${ROUTE_CARDS}#${data.getCardBySlug.id}`}
            >
              <Icon id="left" />
              {defaultFormButtons.BACK}
            </HashLink>
          </ButtonsGroup>
        </div>
        <div className="o-layout__item u-4-of-5-at-medium">
          <CardForm
            edit={edit}
            loading={loading}
            card={new ModelCard(data.getCardBySlug)}
            onMutationComplete={async data => {
              await refetchCard(data.slug);
              routing.push(getPath(ROUTE_CARD_EDIT, data.slug));
              uiState.showNotification({
                message: intl.formatMessage(
                  messages.notificationMessageCardSaved
                )
              });
            }}
            onCancel={onNoEdit}
            onEdit={onEdit}
          />
        </div>
      </div>
    </>
  );
};
export default injectIntl(hooksObserver(Card));
