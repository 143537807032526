import React, { useContext } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { StoresContext } from '../../../stores';
import { FormattedMessage, injectIntl } from 'react-intl';
import Wrapper from '../../../lib/components/page-layout/Wrapper';
import UserForm from './UserForm';
import { ROUTE_USERS } from '../../../routes/RouteList';
import { useQuery } from 'react-apollo-hooks';
import { QUERY_GET_PERSON_BY_ID } from '../../../services/graphql/user-query';
import messages from './messages';
import { defaultCardLabels } from '../../../lib/helpers/copy/default-copy';
import Loader from '../../../components/loader/Loader';

const EditUser = ({ intl, personId }) => {
  const { authStore, routing, uiState } = useContext(StoresContext);
  const entityId = authStore.user.rootEntityId;

  const { data, error, loading } = useQuery(QUERY_GET_PERSON_BY_ID, {
    variables: { personId }
  });
  if (loading)
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  if (error) return <Wrapper>{`Error! ${error.message}`}</Wrapper>;

  const goBack = () => routing.push(ROUTE_USERS);
  return (
    <Wrapper>
      <h1>
        <FormattedMessage {...messages.titleLabelEditUser} />
      </h1>
      <UserForm
        entityId={entityId}
        loading={loading}
        user={data.getPerson}
        onMutationComplete={() => {
          goBack();
          uiState.showNotification({
            message: intl.formatMessage(messages.notificationMessageUserSaved)
          });
        }}
        onCancel={goBack}
      />
    </Wrapper>
  );
};
export default injectIntl(hooksObserver(EditUser));
