import { setAWSAppSyncClient } from './app-sync';
import {
  connectCognito,
  login,
  logout,
  getCurrentSession,
  getCurrentAuthenticatedUser,
  setNewPassword,
  forgotPassword,
} from './cognito';

export let cognitoUser = null;
export let appSyncClient = null;

/**
 * Connect cognito and get appsync client
 */
export const awsConfigure = async ({ awsConfig, appSyncConfig }) => {
  await connectCognito(awsConfig);
  appSyncClient = await setAWSAppSyncClient(appSyncConfig);
};

/**
 * Login cognito
 */
export const awsLogin = async (email, password) => {
  try {
    cognitoUser = await login(email, password);
    if (
      cognitoUser.challengeName &&
      cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED'
    ) {
      console.log('User must reset password!');
      return { error: true, newPasswordRequired: true, cognitoUser };
    } else {
      await getCurrentSession();
      const currerUserResponse = await awsSetCurrentUser();
      if (currerUserResponse.error) {
        return { error: currerUserResponse.error };
      }
      return { error: false, cognitoUser };
    }
  } catch (error) {
    console.log('ERROR: loginCognito', error);
    return { error };
  }
};

/**
 * Set current user (when authenticated)
 */
export const awsSetCurrentUser = async () => {
  try {
    await getCurrentAuthenticatedUser();
    await setAWSAppSyncClient();
    return { error: false };
  } catch (error) {
    return { error };
  }
};

export const awsLoginSetNewPassword = async (user = cognitoUser, password) => {
  try {
    await setNewPassword(user, password);
    await getCurrentSession();
    const response = await awsSetCurrentUser();
    if (response.error) {
      return response;
    }
    return { error: false };
  } catch (error) {
    console.log('ERROR: loginSetNewPassword', error);
    return { error };
  }
};

/**
 * Logout user
 */
export const awsLogout = async () => {
  try {
    await logout();
  } catch (error) {
    console.log('ERROR: logoutCognito', error);
    return { error };
  }
};

/**
 * Logout user
 */
export const awsForgotPassword = async (email) => {
  try {
    await forgotPassword(email);
  } catch (error) {
    console.log('ERROR: ForgotPassword', error);
    return { error };
  }
};

export const awsSignup = async () => {};
