import React from 'react';
import classNames from 'classnames';
import './Input-Checkbox-Radiobutton.scss';
import { Notification } from '../notification';

const Checkbox = React.forwardRef((props, ref) => {
  const {
    id,
    name,
    checked = false,
    defaultChecked,
    onChange,
    children,
    disabled,
    secondary,
    tertiary,
    inverted,
    hasError,
    errorMessage,
    readOnly,
    uploadedImage,
    ...inputProps
  } = props;
  return (
    <>
      <div
        className={classNames('c-input--checkbox', {
          'c-input--checkbox-secondary': secondary,
          'c-input--checkbox-tertiary': tertiary,
          'c-input--checkbox-inverted': inverted,
          'c-input--disabled': disabled,
          'c-input--readonly': readOnly,
          'c-input--error': hasError,
          'c-input-checkbox--uploaded-image': uploadedImage
        })}
      >
        <input
          {...inputProps}
          ref={ref}
          id={id}
          name={name}
          type="checkbox"
          className={classNames('c-input__field-checkbox')}
          defaultChecked={defaultChecked}
          onChange={onChange ? onChange : e => e.stopPropagation()}
          disabled={disabled || readOnly}
          checked={checked}
        />

        <label htmlFor={id} className={classNames('c-input__label')}>
          {children}
        </label>
      </div>
      {errorMessage && <Notification errorMessage>{errorMessage}</Notification>}
    </>
  );
});

export default Checkbox;
