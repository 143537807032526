import React, { useContext } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { StoresContext } from '../../../stores';
import Loader from '../../../components/loader/Loader';

const AppLoaderWrapper = () => {
  const {
    uiState: { hasPendingRequests }
  } = useContext(StoresContext);
  return <>{hasPendingRequests && <Loader />}</>;
};
export default hooksObserver(AppLoaderWrapper);
