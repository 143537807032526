import { defaultFormButtons } from '../lib/helpers/copy/default-copy';
export const MODAL_TYPES = Object.freeze({
  ALERT: 'alert',
  WARNING: 'warning'
});

export default class ModalData {
  constructor({
    title = '',
    message = '',
    okLabel = defaultFormButtons.OK,
    okHandler = null,
    dismissHandler = null,
    dismissLabel = defaultFormButtons.CANCEL,
    dismissButton = true,
    mutationData = null,
    type = MODAL_TYPES.WARNING
  }) {
    this.type = type;
    this.title = title;
    this.message = message;
    this.okLabel = okLabel;
    this.okHandler = okHandler;
    this.dismissHandler = dismissHandler;
    this.dismissButton = dismissButton;
    this.dismissLabel = dismissLabel;
    this.mutationData = mutationData;
  }
}
