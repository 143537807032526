import React, { Fragment, useEffect, useState } from 'react';
import { List, ListItem } from '../../../lib/components/list';
import Category from './Category';
import {
  sort,
  SORT_DATA_TYPES,
} from '@dc-framework/js-utils/src/lib/utils/array';
import { closestCenter, DndContext, useDraggable } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  AnimateLayoutChanges,
  defaultAnimateLayoutChanges,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  restrictToVerticalAxis,
  restrictToFirstScrollableAncestor,
} from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import SubCategory from './SubCategory';
import { ButtonDragger } from '../../../lib/components/button/ButtonDragger';

const SubCategoryGroup = ({ category, parentReorder, onDragEnd }) => {
  const [sortedCategories, setSortedCategories] = useState([]);

  useEffect(() => {
    setSortedCategories(
      sort(category.children, {
        keys: [{ key: 'order', dataType: SORT_DATA_TYPES.NUMBER }],
      })
    );
  }, [category.children]);

  useEffect(() => {
    if (sortedCategories) {
      onDragEnd(sortedCategories);
    }
  }, [sortedCategories]);

  const handleDragEnd = (event) => {
    const { active, over, items } = event;

    if (active.id !== over.id) {
      setSortedCategories((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        return arrayMove(items, oldIndex, newIndex).map((cat, i) => ({
          ...cat,
          order: i,
        }));
      });
    }
  };

  return (
    <DndContext
      onDragEnd={handleDragEnd}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
    >
      <SortableContext
        items={sortedCategories}
        strategy={verticalListSortingStrategy}
      >
        <List extraClassNames="c-list__categories c-list__subcategories">
          {sortedCategories.map((subCategory, i) => (
            <DraggableSubCategoryListItem
              key={subCategory.id}
              subCategory={subCategory}
              index={i}
              parentReorder={parentReorder}
            />
          ))}
        </List>
      </SortableContext>
    </DndContext>
  );
};
export default SubCategoryGroup;

const DraggableSubCategoryListItem = ({ subCategory, parentReorder }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: subCategory.id,
    });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };
  return (
    <ListItem
      ref={setNodeRef}
      style={style}
      extraClassNames={parentReorder && 'c-draggable__item'}
    >
      {parentReorder && <ButtonDragger {...listeners} {...attributes} />}
      <SubCategory category={subCategory} parentReorder={parentReorder} />
    </ListItem>
  );
};
