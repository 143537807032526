import React from 'react';

import classNames from 'classnames';
import './ContentBlock.scss';

export const ContentBlock = ({ children, extraClassNames, draggable }) => (
  <div
    className={classNames('c-content-block', extraClassNames, {
      'c-content-block--draggable': draggable
    })}
  >
    {children}
  </div>
);
