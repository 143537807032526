import React, { useContext } from 'react';
import { StoresContext } from '../../../stores';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

import { ROUTE_TAGS } from '../../../routes/RouteList';
import TagForm from './TagForm';

const AddGroup = () => {
  const { authStore, routing } = useContext(StoresContext);
  const rootEntityId = authStore.user.rootEntityId;
  const goBack = () => routing.push(ROUTE_TAGS);
  return (
    <>
      <div className="o-layout">
        <div className="o-layout__item u-1-of-5-at-medium">
          <h2 className="u-margin-bottom-medium">
            <FormattedMessage {...messages.titleLabelAddTag} />
          </h2>
        </div>
        <div className="o-layout__item u-4-of-5-at-medium">
          <TagForm
            rootEntityId={rootEntityId}
            onMutationComplete={goBack}
            onCancel={goBack}
          />
        </div>
      </div>
    </>
  );
};
export default AddGroup;
