import { defineMessages } from 'react-intl';

export default defineMessages({
  articleEditorAddYoutubeVideoButton: {
    id: 'article.editor.add.youtube.video.button',
    defaultMessage: 'Add Youtube video'
  },
  articleEditorAddMultiSkillzVideoButton: {
    id: 'article.editor.add.multiskillz.video.button',
    defaultMessage: 'Add MultiSkillz video'
  },
  articleEditorAddImageButton: {
    id: 'article.editor.add.image.button',
    defaultMessage: 'Add image(s)'
  },
  articleEditorAddFileButton: {
    id: 'article.editor.add.file.button',
    defaultMessage: 'Add file(s)'
  },
  articleEditorAddTextButton: {
    id: 'article.editor.add.text.button',
    defaultMessage: 'Add text block'
  },
  articleEditorLabelTextEditor: {
    id: 'article.editor.label.text.editor',
    defaultMessage: 'Text editor'
  },
  articleEditorFilesLabel: {
    id: 'article.editor.files.label',
    defaultMessage: 'File(s)'
  },
  articleEditorFilesPlaceholder: {
    id: 'article.editor.files.placeholder',
    defaultMessage: 'Upload your file please'
  },
  articleEditorFilesManagerLabel: {
    id: 'article.editor.files.placeholder',
    defaultMessage: 'or choose existing file(s)'
  },
  articleEditorImagesLabel: {
    id: 'article.editor.images.label',
    defaultMessage: 'Image(s)'
  },
  articleEditorImagesPlaceholder: {
    id: 'article.editor.images.placeholder',
    defaultMessage: 'Upload your image please'
  },
  articleEditorFilesImageLabel: {
    id: 'article.editor.files.placeholder',
    defaultMessage: 'or choose existing image(s)'
  },
  articleEditorYoutubeLabel: {
    id: 'article.editor.youtube.label',
    defaultMessage: 'Youtube Video (youtube ID, url, share link, ...)'
  },
  articleEditorYoutubePlaceholder: {
    id: 'article.editor.youtube.placeholder',
    defaultMessage: 'https://link.to.youtube.video'
  },
  articleEditorMultiSkillzLabel: {
    id: 'article.editor.multiskillz.label',
    defaultMessage: 'MultiSkillz Video link'
  },
  articleEditorMultiSkillzPlaceholder: {
    id: 'article.editor.multiskillz.placeholder',
    defaultMessage: 'https://link.to.multiSkillz.video'
  },
  modalConfirmRemoveCardItemTitle: {
    id: 'modal.confirm.remove.card.item.title',
    defaultMessage: 'Remove item'
  },
  modalConfirmRemoveCardItemMessage: {
    id: 'modal.confirm.remove.card.item.message',
    defaultMessage: 'Are you sure you want to delete this item?'
  },
  modalConfirmRemoveFileTitle: {
    id: 'modal.confirm.remove.file.title',
    defaultMessage: 'Remove file'
  },
  modalConfirmRemoveFileMessage: {
    id: 'modal.confirm.remove.file.message',
    defaultMessage: 'Are you sure you want to delete this file?'
  }
});
