import { defineMessages } from 'react-intl';

export default defineMessages({
  titleLabelCards: {
    id: 'title.label.cards',
    defaultMessage: 'Cards'
  },
  titleLabelAllCards: {
    id: 'title.label.allcards',
    defaultMessage: 'All Cards'
  },
  titleLabelAddCard: {
    id: 'title.label.add.card',
    defaultMessage: 'Add card'
  },
  titleLabelExportCards: {
    id: 'title.label.export.cards',
    defaultMessage: 'Export cards'
  },
  titleLabelEditCard: {
    id: 'title.label.edit.card',
    defaultMessage: 'Edit card'
  },
  modalConfirmRemoveCardTitle: {
    id: 'modal.confirm.remove.card.title',
    defaultMessage: 'Remove card'
  },
  modalConfirmRemoveCardMessage: {
    id: 'modal.confirm.remove.card.message',
    defaultMessage: 'Are you sure you want to delete this card?'
  },
  notificationMessageCardSaved: {
    id: 'notification.message.card.saved',
    defaultMessage: 'Card saved'
  },
  notificationMessageCardRemoved: {
    id: 'notification.message.card.removed',
    defaultMessage: 'Card removed'
  },
  messageNoCardsResultFoundFor: {
    id: 'message.no.cards.result.found.for',
    defaultMessage: 'No cards found for "{search}"'
  },
  messageCardsResultFoundFor: {
    id: 'message.cards.result.found.for',
    defaultMessage: '{count} cards found for "{search}"'
  }
});
