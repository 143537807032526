import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { StoresContext } from '../../../stores/contexts';
import {
  ROUTE_CATEGORY_ADD,
  ROUTE_CATEGORY_EDIT,
} from '../../../routes/RouteList';
import messages from './messages';
import {
  defaultFormButtons,
  defaultFormMessages,
} from '../../../lib/helpers/copy/default-copy';
import { Button, Row, ButtonsGroup, Icon } from '../../../lib/components';
import CategoryTree from '../../partials/category-tree/CategoryTree';
import { useCategoryTree } from '../../../services/hooks/useCategoryTree';
import { Form } from '../../../lib/containers/form';
import EditableCategoryTree from '../../partials/category-tree/EditableCategoryTree';
import { ContentBlock } from '../../../lib/components/content-block/ContentBlock';
import { Input } from '../../../lib/components/input';
import { Select, Option } from '../../../lib/components/input-select';
import CategoryForm from './CategoryForm';

export const Categories = injectIntl(({ intl }) => {
  const { authStore } = useContext(StoresContext);
  const { categories, loading, error } = useCategoryTree(
    authStore.user.rootEntityId
  );
  const [addCat, setAddCat] = useState(false);

  if (!categories) return <></>;

  return (
    <>
      <div className="o-flex o-flex--align-start o-flex--justify-space-between">
        <h2 className="u-margin-none">
          <FormattedMessage {...messages.titleLabelCategories} />
        </h2>
        <Button
          primary
          onClick={() => {
            setAddCat(!addCat);
          }}
        >
          <FormattedMessage {...messages.titleLabelAddCategory} />
        </Button>
      </div>
      {addCat && <CategoryForm onCancel={() => setAddCat(false)} />}
      <Row>
        <EditableCategoryTree categories={categories} />
      </Row>
    </>
  );
});
