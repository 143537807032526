import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Redirect, Route } from 'react-router';
import { withRouter } from 'react-router-dom';
import { withErrorBoundary } from '../lib/containers/utils/ErrorBoundary';

import {
  ROUTE_LOGIN,
  ROUTE_GROUPS,
  ROUTE_CARDS,
  ROUTE_USERS,
  ROUTE_FILE_MANAGER,
  ROUTE_TAGS,
  ROUTE_CATEGORIES
} from './RouteList';

// Route types
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';

// Public pages
import loginRoutes from './login-routes';

// Private pages
import GroupsPage from '../containers/pages/groups';
import UsersPage from '../containers/pages/users';
import TagsPage from '../containers/pages/tags';
import FileManagerPage from '../containers/pages/filemanager';
import CardsPage from '../containers/pages/cards';
import CategoriesPage from '../containers/pages/categories';

class AppRoute extends Component {
  render() {
    const {
      rootStore: { authStore }
    } = this.props;
    return (
      <>
        <PublicRoutes>
          {loginRoutes(authStore)}
          <Redirect to={ROUTE_LOGIN} />
        </PublicRoutes>
        <PrivateRoutes>
          <Route
            path={ROUTE_FILE_MANAGER}
            component={() => <FileManagerPage />}
          />
          <Route path={ROUTE_CARDS} component={() => <CardsPage />} />
          <Route path={ROUTE_GROUPS} component={() => <GroupsPage />} />
          <Route path={ROUTE_USERS} component={() => <UsersPage />} />
          <Route path={ROUTE_TAGS} component={() => <TagsPage />} />
          <Route path={ROUTE_CATEGORIES} component={() => <CategoriesPage />} />
          <Redirect to={ROUTE_GROUPS} />
        </PrivateRoutes>
      </>
    );
  }
}

export default withErrorBoundary(inject('rootStore')(withRouter(AppRoute)));
