import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { form } from '@dc-framework/js-utils';
import {
  defaultFormButtons,
  defaultFormLabels,
  defaultTitleLabels
} from '../../../helpers/copy/default-copy';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter
} from '../../../components/card';
import { Button, ButtonsGroup } from '../../../components/button';
import Form from '../../form/Form';
import { INPUT_TYPES } from '../../form';
import { Notification } from '../../../components/notification';

const { isEmailValid } = form;

export default class LoginView extends Component {
  static propTypes = {
    emailValidator: PropTypes.func,
    loading: PropTypes.bool,
    email: PropTypes.string,
    password: PropTypes.string,
    error: PropTypes.string,
    labelForgotPassword: PropTypes.string,
    labelLogin: PropTypes.string,
    labelPassword: PropTypes.string,
    labelSubmitButton: PropTypes.string,
    labelSubtitle: PropTypes.string,
    labelTitle: PropTypes.string,
    onForgotPassword: PropTypes.func,
    onSubmit: PropTypes.func,
    noCard: PropTypes.bool
  };

  handleForgotClick = e => {
    e.preventDefault();
    const { onForgotPassword } = this.props;
    if (onForgotPassword) onForgotPassword();
  };

  handleSubmit = ({ email, password }) => {
    const { onSubmit } = this.props;
    onSubmit && onSubmit(email, password);
  };

  render() {
    const {
      noCard,
      loading = false,
      email,
      password,
      emailValidator = isEmailValid,
      labelTitle = defaultTitleLabels.LOGIN_TITLE,
      labelSubtitle = defaultTitleLabels.LOGIN_SUBTITLE,
      labelLogin = defaultFormLabels.EMAIL,
      labelPassword = defaultFormLabels.PASSWORD,
      labelForgotPassword = defaultFormButtons.FORGOT_PASSWORD,
      labelSubmitButton = defaultFormButtons.LOGIN,
      error
    } = this.props;

    const Wrapper = noCard ? Fragment : Card;
    return (
      <Wrapper>
        <CardHeader>
          {labelTitle && <h1 className="u-h3">{labelTitle}</h1>}
          {labelSubtitle && <p>{labelSubtitle}</p>}
        </CardHeader>
        <Form onSubmit={this.handleSubmit} loading={loading}>
          <CardBody>
            <Form.ItemWrapper
              formItems={[
                {
                  key: 'email',
                  label: labelLogin,
                  value: email,
                  inputType: INPUT_TYPES.EMAIL,
                  validator: emailValidator,
                  required: true
                },
                {
                  key: 'password',
                  label: labelPassword,
                  value: password,
                  inputType: INPUT_TYPES.PASSWORD,
                  required: true
                }
              ]}
            />
            {error && !loading && (
              <Notification errorMessage show>
                {error}
              </Notification>
            )}
          </CardBody>
          <CardFooter>
            <ButtonsGroup>
              <Form.SubmitButton primary label={labelSubmitButton} />
              <Button
                link
                type="button"
                disabled={loading}
                onClick={e => this.handleForgotClick(e)}
              >
                {labelForgotPassword}
              </Button>
            </ButtonsGroup>
          </CardFooter>
        </Form>
      </Wrapper>
    );
  }
}
