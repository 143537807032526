import { RouterStore } from 'mobx-react-router';
import { AuthStore, UiState } from '.';

export default class RootStore {
  stores = {};
  constructor() {
    this.routing = new RouterStore(this);
    this.uiState = new UiState(this);
    this.authStore = new AuthStore(this);

    this.stores = {
      authStore: this.authStore,
      routing: this.routing,
      uiState: this.uiState
    };
  }
}
