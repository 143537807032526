import React from 'react';
import classNames from 'classnames';

import './Table.scss';

class Table extends React.Component {
  render() {
    const { children, extraClassNames, fixed, stripped } = this.props;
    return (
      <>
        <div className="table-container">
          <table
            className={classNames('o-table c-table', extraClassNames, {
              'o-table--fixed': fixed,
              'c-table--stripped': stripped
            })}
          >
            {children}
          </table>
        </div>
      </>
    );
  }
}

export default Table;
