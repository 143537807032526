import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import Icon from '../icon/Icon';
class SubNav extends React.Component {
  render() {
    const { subNavTitle, subNavLink, subNavLabel, children } = this.props;
    return (
      <nav
        aria-labelledby={subNavLabel ? subNavLabel : ''}
        className={classNames('c-nav-subnav')}
      >
        {subNavLink ? (
          <NavLink
            to={subNavLink}
            className={classNames('c-nav__link')}
            id={subNavLabel ? subNavLabel : ''}
            activeClassName="is-selected"
          >
            {subNavTitle}
            <div
              className={classNames('c-nav-subnav__toggler')}
              aria-expanded="false"
            >
              <Icon
                id="down"
                extraClassNames="c-nav-subnav__icon-collapsed"
                title="Expand Submenu"
                titleId="icon-expand"
              />
              <Icon
                id="close"
                extraClassNames="c-nav-subnav__icon-expanded"
                title="Collapse Submenu"
                titleId="icon-collapse"
              />
            </div>
          </NavLink>
        ) : (
          <button
            className={classNames('c-nav__link')}
            id={subNavLabel ? subNavLabel : ''}
          >
            {subNavTitle}
            <div
              className={classNames('c-nav-subnav__toggler')}
              aria-expanded="false"
            >
              <Icon
                id="down"
                extraClassNames="c-nav-subnav__icon-collapsed"
                title="Expand Submenu"
                titleId="icon-expand"
              />
              <Icon
                id="close"
                extraClassNames="c-nav-subnav__icon-expanded"
                title="Collapse Submenu"
                titleId="icon-collapse"
              />
            </div>
          </button>
        )}

        <ul className={classNames('c-nav-subnav__list')} hidden="hidden">
          {children}
        </ul>
      </nav>
    );
  }
}

export default SubNav;
