import React from 'react';
import classNames from 'classnames';
import { Wrapper, Row } from './';

import './PageLayout.scss';

class PageLayout extends React.Component {
  render() {
    const {
      children,
      fullHeight,
      oneElementFullyCentered,
      ...props
    } = this.props;
    return (
      <div
        className={classNames('c-page', {
          'c-page--full-height': fullHeight,
          'c-page--full-height c-page--centered': oneElementFullyCentered
        })}
        {...props}
      >
        {/* a Wrapper could be use as children */}
        {/* or a Row, and a Wrapper within could be use as children */}
        {children}
      </div>
    );
  }
}

export default PageLayout;
