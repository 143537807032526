import React from 'react';
import classNames from 'classnames';
import './Image.scss';

const Image = ({ src, alt, extraClassNames, placeholder, ...props }) => (
  <img
    alt={placeholder && !alt ? 'Add a thumbnail' : alt}
    src={placeholder && !src ? '/assets/images/placeholder.svg' : src}
    className={classNames(
      //'c-image',
      extraClassNames,
      {
        'c-image--placeholder': placeholder
      }
    )}
  />
);
export default Image;
