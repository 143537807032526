import React, { useContext } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { StoresContext } from '../../../stores';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { useQuery } from 'react-apollo-hooks';
import { QUERY_GET_GROUP_BY_SLUG } from '../../../services/graphql/group-query';
import {
  defaultCardLabels,
  defaultFormButtons
} from '../../../lib/helpers/copy/default-copy';
import { ROUTE_GROUP_EDIT, ROUTE_GROUPS } from '../../../routes/RouteList';
import { PageContent } from '../../../lib/components/page-layout';
import GroupForm from './GroupForm';
import { Button } from '../../../lib/components/button/Button';
import { getPath } from './path-generator';
import { Icon } from '../../../lib/components/icon';
import { ButtonsGroup } from '../../../lib/components/button/ButtonsGroup';
import Loader from '../../../components/loader/Loader';

const Group = ({ groupSlug, edit }) => {
  const { authStore, routing } = useContext(StoresContext);
  const rootEntityId = authStore.user.rootEntityId;
  const queryProps = {
    query: QUERY_GET_GROUP_BY_SLUG,
    variables: { slug: groupSlug }
  };
  const { data, error, loading } = useQuery(queryProps.query, {
    variables: queryProps.variables
  });

  if (loading)
    return (
      <PageContent>
        <Loader />
      </PageContent>
    );
  if (error) return <PageContent>{`Error! ${error.message}`}</PageContent>;

  const onNoEdit = () => routing.push(getPath(ROUTE_GROUPS));
  const onEdit = () => routing.push(getPath(ROUTE_GROUP_EDIT, groupSlug));

  return (
    <>
      <div className="o-layout">
        <div className="o-layout__item u-1-of-5-at-medium">
          <h2 className="u-margin-bottom-medium">
            <FormattedMessage {...messages.titleLabelEditGroup} />
          </h2>
          <ButtonsGroup extraClassNames="u-margin-bottom-large">
            <Button ghost iconLeft onClick={() => routing.push(ROUTE_GROUPS)}>
              <Icon id="left" />
              {defaultFormButtons.BACK}
            </Button>
          </ButtonsGroup>
        </div>
        <div className="o-layout__item u-4-of-5-at-medium">
          <GroupForm
            edit={edit}
            rootEntityId={rootEntityId}
            loading={loading}
            group={data.getEntityBySlug}
            onMutationComplete={onNoEdit}
            onCancel={onNoEdit}
            onEdit={onEdit}
          />
        </div>
      </div>
    </>
  );
};
export default hooksObserver(Group);
