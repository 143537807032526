import React, { useContext, useState } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { injectIntl } from 'react-intl';
import { StoresContext } from '../../../../stores';
import { form, file } from '@dc-framework/js-utils';
import {
  MutationForm,
  Form,
  FORM_ITEM_TYPES
} from '../../../../lib/containers/form';
import { ButtonsGroup } from '../../../../lib/components/button';
import { defaultFormMessages } from '../../../../lib/helpers/copy/default-copy';
import {
  MUTATION_UPDATE_TOPIC,
  MUTATION_ADD_TOPIC,
  QUERY_GET_TOPICS_BY_SLUG
} from '../../../../services/graphql';
import { useTags } from '../../../../services/hooks/useTags';
import { getInputTagFormat } from '../../../../utils/tags';
import { handleTags } from '../../../../services/graphql/tag-query';
import { uploader } from '../../../../services/uploader';
import File, { FILE_TYPES } from '../../../../models/File';
import messages from './messages';
import appMessages from '../../../../messages';
import { MAX_DESCRIPTION_LENGTH } from '../../../../constants';

const { MIME_TYPES_PRESETS, getMimeTypesByPreset } = file;

const {
  VALIDATE_TYPES: { ERROR_UNKNOWN },
  isMaxLengthValid
} = form;

const TopicForm = ({
  intl,
  edit = true,
  onEdit,
  groupSlug,
  topic,
  loading,
  refetchQueries,
  onCancel,
  onMutationComplete
}) => {
  const { uiState } = useContext(StoresContext);
  const MUTATION = topic ? MUTATION_UPDATE_TOPIC : MUTATION_ADD_TOPIC;

  const [thumb, setThumb] = useState(
    (topic && topic.thumb && [new File(topic.thumb)]) || []
  );

  const [tagLoading, setTagLoading] = useState(false);
  const { dbTags, refetch } = useTags();
  const tags = topic ? getInputTagFormat(topic.tags) : [];
  let tagsToSave = null;

  let description = '';
  if (topic) {
    try {
      description = JSON.parse(topic.meta).description;
    } catch (er) {}
  }

  const refetcher = [
    {
      query: QUERY_GET_TOPICS_BY_SLUG,
      variables: { slug: groupSlug }
    },
    ...(refetchQueries || [])
  ];

  const tagsHandler = async (entityId, refetchQueries) => {
    await handleTags({
      currentTags: tagsToSave,
      previousTags: tags,
      entityId,
      refetchQueries
    });
    refetch();
  };

  return (
    <>
      <MutationForm
        edit={edit}
        loading={loading || tagLoading}
        mutation={MUTATION}
        onCancel={onCancel}
        onEdit={onEdit}
        errorMessage={defaultFormMessages[ERROR_UNKNOWN]}
        getMutationVariables={async data => {
          // tags
          tagsToSave = data.tags;
          data.tags = undefined;
          if (topic) {
            setTagLoading(true);
            await tagsHandler(topic.id);
            setTagLoading(false);
          }

          // description via meta
          data.meta = JSON.stringify({ description: data.description });

          // thumb
          data.thumbFileId = data.thumb.length ? data.thumb[0].id : undefined;
          return data;
        }}
        extraMutationVariables={{
          parentSlug: groupSlug,
          id: topic ? topic.id : undefined
        }}
        refetchQueries={topic ? () => refetcher : undefined}
        mutationDataKey={topic ? 'editEntity' : 'createEntity'}
        onMutationComplete={async data => {
          let hasToRefetch = false;
          if (!topic) {
            if (tagsToSave && tagsToSave.length) {
              setTagLoading(true);
              await tagsHandler(data.id, refetcher);
              setTagLoading(false);
            } else {
              hasToRefetch = true;
            }
          }

          uiState.showNotification({
            message: intl.formatMessage(messages.notificationMessageTopicSaved)
          });
          onMutationComplete(data, hasToRefetch);
        }}
      >
        <Form.ItemWrapper
          formItems={[
            {
              key: 'thumb',
              type: FORM_ITEM_TYPES.FILE_UPLOADER,
              label: intl.formatMessage(appMessages.formLabelThumbnail),
              value: thumb,
              uploader,
              multiple: false,
              required: false,
              fileUploadIcon: 'image',
              accept: getMimeTypesByPreset(MIME_TYPES_PRESETS.IMAGE),
              canDelete: false,
              isGallery: true,
              controlledValue: true,
              labelFileManager: intl.formatMessage(
                messages.topicSelectExistingImage
              ),
              onBrowseFileManager: () => {
                uiState.showSelectFileModal({
                  ignoreFiles: thumb,
                  type: FILE_TYPES.IMAGE,
                  onSelect: files => setThumb(files),
                  multiple: false
                });
              }
            },
            {
              key: 'name',
              label: intl.formatMessage(appMessages.formLabelName),
              value: topic ? topic.name : '',
              required: true
            },
            {
              key: 'description',
              label: intl.formatMessage(appMessages.formLabelDescription, {
                maxLength: MAX_DESCRIPTION_LENGTH
              }),
              value: description,
              type: FORM_ITEM_TYPES.TEXTAREA,
              maxLength: MAX_DESCRIPTION_LENGTH,
              validator: ({ value }) =>
                isMaxLengthValid({ value, maxLength: MAX_DESCRIPTION_LENGTH })
            },
            {
              key: 'tags',
              label: intl.formatMessage(appMessages.formLabelTags),
              value: tags,
              type: FORM_ITEM_TYPES.TAG_INPUT,
              options: dbTags
            }
          ]}
        />
        <ButtonsGroup>
          <Form.EditButton />
          <Form.SubmitButton primary />
          <Form.CancelButton secondary />
        </ButtonsGroup>
      </MutationForm>
    </>
  );
};
export default injectIntl(hooksObserver(TopicForm));
