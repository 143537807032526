import React from 'react';
import { Tabs as ReactTabs } from 'react-tabs';

import './Tabs.scss';

const Tabs = props => (
  <div className="p-tabs">
    <ReactTabs {...props}>{props.children}</ReactTabs>
  </div>
);

export default Tabs;
