import { injectIntl } from 'react-intl';
import React, { forwardRef, useContext, useState } from 'react';
import { StoresContext } from '../../../stores';
import {
  QUERY_GET_CATEGORIES,
  updateCategory,
  updateCategoryOrder,
} from '../../../services/graphql/categories-query';
import messages from '../../pages/categories/messages';
import {
  Accordion,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  Icon,
  Input,
} from '../../../lib/components';
import { DndWrapper } from '../../../lib/containers/dnd';
import ButtonsGroupForCategories from './ButtonsGroupForCategories';
import { ref } from 'yup';
import SubCategoryGroup from './SubCategoryGroup';

const SubCategory = injectIntl(
  forwardRef(({ category, index, intl, parentReorder }, ref) => {
    const { uiState } = useContext(StoresContext);
    const [categoryName, setCategoryName] = useState();
    const [edit, setEdit] = useState(false);
    const onCategoryUpdate = async () => {
      const categoryId = await updateCategory({
        refetchQueries: [{ query: QUERY_GET_CATEGORIES }],
        variables: { id: category.id, name: categoryName },
      });
      uiState.showNotification({
        message: intl.formatMessage(messages.notificationMessageCategorySaved),
      });
    };

    return (
      <div className="c-category c-category--childless" ref={ref}>
        <div className="c-category__title">
          {edit ? (
            <Input
              id={`input-${category.id}`}
              type="text"
              value={categoryName}
              extraClassNames={'c-input--small c-input--category'}
              autoFocus
              onKeyDown={(e) => {
                if (e.keyCode === 27) {
                  e.preventDefault();
                  setEdit(false);
                }
                if (e.keyCode === 13) {
                  e.preventDefault();
                  onCategoryUpdate();
                  setEdit(false);
                }
              }}
              onChange={(e) => setCategoryName(e.target.value)}
            />
          ) : (
            <span>{category.name}</span>
          )}
        </div>
        {!parentReorder && (
          <ButtonsGroupForCategories
            category={category}
            setEdit={setEdit}
            edit={edit}
            onUpdate={() => onCategoryUpdate()}
            onEdit={() => setCategoryName(category.name)}
          />
        )}
      </div>
    );
  })
);
export default SubCategory;
