export default class Tag {
  id;
  name;
  slug;
  meta;

  constructor({ id, name, slug, meta, tagEntity }) {
    this.id = id;
    this.slug = slug;
    this.name = name;
    this.meta = meta;
    this.tagEntity = tagEntity;
  }

  get value() {
    return this.name;
  }

  set value(val) {
    this.name = val;
  }

  get label() {
    return this.name;
  }
}
