import { defineMessages } from 'react-intl';

export default defineMessages({
  titleLabelCards: {
    id: 'title.label.cards.slug',
    defaultMessage: 'Cards'
  },
  titleLabelAddCard: {
    id: 'title.label.add.card.slug',
    defaultMessage: 'Add card'
  },
  titleLabelEditCard: {
    id: 'title.label.edit.card.slug',
    defaultMessage: 'Edit card'
  },
  modalConfirmRemoveCardTitle: {
    id: 'modal.confirm.remove.card.slug.title',
    defaultMessage: 'Remove card from topic'
  },
  modalConfirmRemoveCardMessage: {
    id: 'modal.confirm.remove.card.slug.message',
    defaultMessage: 'Are you sure you want to delete this card from this topic?'
  },
  notificationMessageCardSaved: {
    id: 'notification.message.card.slug.saved',
    defaultMessage: 'Card saved'
  },
  notificationMessageCardRemoved: {
    id: 'notification.message.card.slug.removed',
    defaultMessage: 'Card removed from topic'
  },
  cardSelectExistingImage: {
    id: 'card.select.existing.image',
    defaultMessage: 'or choose existing image'
  },
  cardUploadThumbPlaceholder: {
    id: 'card.upload.thumb.placeholder',
    defaultMessage: 'Upload your thumbnail please'
  }
});
