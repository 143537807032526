import React from 'react';
// import classNames from 'classnames';

class HeaderSubtitle extends React.Component {
  render() {
    const { children } = this.props;
    return <p className="c-page__title-subtitle">{children}</p>;
  }
}
export default HeaderSubtitle;
