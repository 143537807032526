import React from 'react';

import classNames from 'classnames';

class CardActions extends React.Component {
  render() {
    const { children, login } = this.props;
    return (
      <div
        className={classNames('c-card__actions', {
          'c-card__actions-login': login
        })}
      >
        {children}
      </div>
    );
  }
}

export default CardActions;
