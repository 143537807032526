import React from 'react';
import { inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { List } from '../../../lib/components/list/List';
import { DndWrapper } from '../../../lib/containers/dnd/index';
import { Card, CardBody } from '../../../lib/components/card';
import { ListItem } from '../../../lib/components/list/list-item/ListItem';
import { ContentBlock } from '../../../lib/components/content-block/ContentBlock';
import Icon from '../../../lib/components/icon/Icon';
import { Button } from '../../../lib/components/button/Button';
import { CardItemType } from '../../../constants';
import { ContentBlockItem } from './ContentBlockItem';
import CardItem from '../../../models/CardItem';
import { array } from '@dc-framework/js-utils';
import messages from './messages';
import mainMessages from '../../../messages';
import { FILE_TYPES } from '../../../models/File';
import CardItemFile from '../../../models/CardItemFile';

const { sort, SORT_DATA_TYPES, removeItemFromArray } = array;

export const ArticleEditor = inject('rootStore')(
  ({ rootStore, value = [], onChange, readOnly }) => {
    const { uiState } = rootStore;

    const contentBlocks = value;

    const onAddItem = type => {
      const cardItem = new CardItem({});
      cardItem.id = new Date().getTime();
      cardItem.type = type;
      cardItem.__isNew__ = true;
      cardItem.order = contentBlocks.length
        ? Math.max(...contentBlocks.map(o => o.order)) + 1
        : 0;
      const newContentBlocks = [...contentBlocks, cardItem];
      onChange && onChange(newContentBlocks);
    };

    const onDeleteItem = contentBlock => {
      removeItemFromArray(contentBlocks, contentBlock);
      onChange && onChange(contentBlocks);
    };

    const onChangeItem = contentBlock => {
      if (contentBlock) {
        contentBlock.__isUpdated__ = true;
      }
      onChange && onChange(contentBlocks);
    };

    const onBrowseFileManager = contentBlock => {
      const type =
        contentBlock.type === CardItemType.IMAGE
          ? FILE_TYPES.IMAGE
          : FILE_TYPES.FILE;
      uiState.showSelectFileModal({
        type,
        ignoreFiles: contentBlock.files.map(f => f.file),
        multiple: true,
        onSelect: files => {
          contentBlock.files.push(
            ...files.map(file => new CardItemFile({ file, fileId: file.id }))
          );
          onChangeItem(contentBlock);
        }
      });
    };

    const sortedContentBlocks = sort(contentBlocks, {
      keys: [{ key: 'order', dataType: SORT_DATA_TYPES.NUMBER }]
    });

    return (
      <>
        <List extraClassNames={'o-layout'}>
          <DndWrapper
            type="card-item"
            items={sortedContentBlocks}
            onDragUpdate={items => {
              items.forEach((item, i) => {
                const isChange = item.order !== i;
                item.order = i;
                if (isChange) item.__isUpdated__ = true;
              });
              onChangeItem();
            }}
            dropComponent={() => (
              <Card horizontal empty contentBlock>
                <CardBody>
                  <FormattedMessage {...mainMessages.listDragDropHereLabel} />
                </CardBody>
              </Card>
            )}
            placeholderOpacity={1}
          >
            {sortedContentBlocks.map((contentBlock, i) => (
              <ListItem
                key={contentBlock.id}
                extraClassNames={'o-layout__item'}
              >
                <DndWrapper.Item id={contentBlock.id} index={i}>
                  <ContentBlock draggable>
                    <DndWrapper.Grabzone>
                      <Icon id="drag" small secondary />
                    </DndWrapper.Grabzone>
                    <ContentBlockItem
                      disabled={readOnly}
                      contentBlock={contentBlock}
                      onDeleteItem={onDeleteItem}
                      onChange={onChangeItem}
                      onBrowseFileManager={onBrowseFileManager}
                    />
                  </ContentBlock>
                </DndWrapper.Item>
              </ListItem>
            ))}
          </DndWrapper>
        </List>
        <div className="o-flex o-flex--wrap o-flex--justify-space-between u-margin-vertical-large">
          <Button
            disabled={readOnly}
            addBlock
            onClick={() => onAddItem(CardItemType.YOUTUBE)}
            type="button"
          >
            <Icon id="video" />
            <FormattedMessage
              {...messages.articleEditorAddYoutubeVideoButton}
            />
          </Button>
          <Button
            disabled={readOnly}
            addBlock
            onClick={() => onAddItem(CardItemType.MULTISKILLZ)}
            type="button"
          >
            <Icon id="video" />
            <FormattedMessage
              {...messages.articleEditorAddMultiSkillzVideoButton}
            />
          </Button>
          <Button
            disabled={readOnly}
            addBlock
            type="button"
            onClick={() => onAddItem(CardItemType.TEXT)}
          >
            <Icon id="text" />
            <FormattedMessage {...messages.articleEditorAddTextButton} />
          </Button>
          <Button
            disabled={readOnly}
            addBlock
            type="button"
            onClick={() => onAddItem(CardItemType.IMAGE)}
          >
            <Icon id="image" />
            <FormattedMessage {...messages.articleEditorAddImageButton} />
          </Button>
          <Button
            disabled={readOnly}
            addBlock
            type="button"
            onClick={() => onAddItem(CardItemType.FILE)}
          >
            <Icon id="doc" />
            <FormattedMessage {...messages.articleEditorAddFileButton} />
          </Button>
        </div>
      </>
    );
  }
);
