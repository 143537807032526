import React from 'react';
import classNames from 'classnames';

export const ButtonsGroup = (props) => {
  const { right, center, spaceAround, spaceBetween, extraClassNames, style } =
    props;
  return (
    <div
      className={classNames('c-button__group', extraClassNames, {
        'o-flex--justify-end': right,
        'o-flex--justify-center': center,
        'o-flex--justify-space-around': spaceAround,
        'o-flex--justify-space-between': spaceBetween,
      })}
      style={style}
    >
      {props.children}
    </div>
  );
};
