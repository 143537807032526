import gql from 'graphql-tag';
import { doMutation, doQuery } from '../api';

export const QUERY_GET_GROUP_TREE = gql`
  query getEntityTree {
    getEntityTree {
      id
      name
      slug
      subEntities {
        id
        name
        slug
      }
    }
  }
`;

export const refetchGroups = async entityId => {
  await doQuery({
    query: QUERY_GET_GROUPS_BY_ENTITY_ID,
    variables: { entityId }
  });
};

export const QUERY_GET_GROUPS_BY_ENTITY_ID = gql`
  query getEntity($entityId: ID!) {
    getEntity(entityId: $entityId) {
      id
      name
      slug
      meta
      parentId
      order
      subEntities {
        id
        name
        slug
        meta
        order
        tags {
          id
          slug
          name
          meta
          tagEntity {
            id
          }
        }
        thumb {
          id
          linkId
          url
          key
          filename
          type
          status
          label
        }
        subEntities {
          id
        }
      }
    }
  }
`;

export const QUERY_GET_GROUP_BY_SLUG = gql`
  query getEntityBySlug($slug: String!) {
    getEntityBySlug(slug: $slug) {
      id
      name
      slug
      config
      meta
      parentId
      thumb {
        id
        linkId
        url
        key
        filename
        type
        status
        label
      }
      tags {
        id
        slug
        name
        meta
        tagEntity {
          id
        }
      }
    }
  }
`;

export const MUTATION_ADD_GROUP = gql`
  mutation createEntity(
    $parentId: ID
    $name: String!
    $meta: AWSJSON
    $config: AWSJSON
    $thumbFileId: ID
  ) {
    createEntity(
      input: {
        parentId: $parentId
        name: $name
        meta: $meta
        config: $config
        thumbFileId: $thumbFileId
      }
    ) {
      id
      slug
      name
      meta
      tags {
        id
        slug
        name
        meta
        tagEntity {
          id
        }
      }
    }
  }
`;

export const MUTATION_UPDATE_GROUP = gql`
  mutation editEntity(
    $id: ID!
    $name: String
    $meta: AWSJSON
    $config: AWSJSON
    $thumbFileId: ID
  ) {
    editEntity(
      input: {
        id: $id
        name: $name
        meta: $meta
        config: $config
        thumbFileId: $thumbFileId
      }
    ) {
      id
      name
      slug
      meta
      tags {
        id
        slug
        name
        meta
        tagEntity {
          id
        }
      }
    }
  }
`;

export const MUTATION_REMOVE_GROUP = gql`
  mutation archiveEntity($entityId: ID!) {
    archiveEntity(entityId: $entityId)
  }
`;

export const MUTATION_UPDATE_GROUP_ORDER = gql`
  mutation sortEntities($sortItemInput: [SortItemInput]) {
    sortEntities(input: $sortItemInput)
  }
`;

export const updateGroupsOrder = async (variables, refetchQueries) => {
  return new Promise(async (resolve, reject) => {
    const mutation = MUTATION_UPDATE_GROUP_ORDER;
    return await doMutation({
      mutation,
      variables,
      update: (cache, { data, data: { sortEntities } }) => {
        if (sortEntities) {
          resolve(sortEntities);
        }
      },
      refetchQueries
    });
  });
};
