import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { form } from '@dc-framework/js-utils';
import BasicForm from './BasicForm';
import Form from './Form';
import { defaultFormMessages } from '../../helpers/copy/default-copy';

const {
  VALIDATE_TYPES: { ERROR_UNKNOWN }
} = form;

export default class MutationForm extends Component {
  static propTypes = {
    basic: PropTypes.bool,
    children: PropTypes.any,
    errorMessage: PropTypes.string,
    extraMutationVariables: PropTypes.object,
    getMutationVariables: PropTypes.func,
    loading: PropTypes.bool,
    mutation: PropTypes.object,
    mutationDataKey: PropTypes.string,
    onCompleted: PropTypes.func,
    onError: PropTypes.func,
    onMutationComplete: PropTypes.func,
    onSubmit: PropTypes.func,
    refetchQueries: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
    update: PropTypes.func
  };

  onSubmit = async (mutation, data) => {
    const {
      getMutationVariables,
      extraMutationVariables,
      onSubmit
    } = this.props;
    onSubmit && onSubmit(data);
    let variables = data;
    if (getMutationVariables) {
      variables = await getMutationVariables(data);
    }
    mutation({
      variables: {
        ...variables,
        ...extraMutationVariables
      }
    });
  };
  render() {
    const {
      children,
      mutation,
      update,
      refetchQueries,
      mutationDataKey,
      onCompleted,
      onError,
      onMutationComplete,
      basic,
      errorMessage = defaultFormMessages[ERROR_UNKNOWN],
      innerRef,
      ...props
    } = this.props;

    const FormWrapper = basic ? BasicForm : Form;
    return (
      <Mutation
        mutation={mutation}
        refetchQueries={refetchQueries}
        onCompleted={onCompleted}
        onError={onError}
        update={
          update ||
          ((cache, { data, ...props }, ...other) => {
            if (mutationDataKey) {
              if (data && data[mutationDataKey] && data[mutationDataKey].id) {
                onMutationComplete && onMutationComplete(data[mutationDataKey]);
              }
            } else if (data) {
              onMutationComplete && onMutationComplete(data);
            }
          })
        }
      >
        {(mutation, data) => {
          return (
            <FormWrapper
              {...props}
              ref={innerRef}
              loading={data.loading || props.loading}
              onSubmit={data => this.onSubmit(mutation, data)}
              errorMessage={data.error && errorMessage}
            >
              {children}
            </FormWrapper>
          );
        }}
      </Mutation>
    );
  }
}
