import React from 'react';
import classNames from 'classnames';
import { Notification } from '../notification';

const Radiobutton = React.forwardRef((props, ref) => {
  const {
    id,
    name,
    checked,
    defaultChecked,
    onChange,
    children,
    disabled,
    secondary,
    tertiary,
    hasError,
    errorMessage,
    readOnly,
    ...inputProps
  } = props;
  return (
    <>
      <div
        className={classNames('c-input--radiobutton', {
          'c-input--radiobutton-secondary': secondary,
          'c-input--radiobutton-tertiary': tertiary,
          'c-input--disabled': disabled,
          'c-input--readonly': readOnly,
          'c-input--error': hasError
        })}
      >
        <input
          ref={ref}
          id={id}
          name={name}
          type="radio"
          className={classNames('c-input__field-radiobutton')}
          defaultChecked={defaultChecked}
          onChange={onChange ? onChange : e => e.stopPropagation()}
          onClick={onChange ? onChange : e => e.stopPropagation()}
          disabled={disabled || readOnly}
          {...inputProps}
        />

        <label htmlFor={id} className={classNames('c-input__label')}>
          {children}
        </label>
      </div>
      {errorMessage && <Notification errorMessage>{errorMessage}</Notification>}
    </>
  );
});

export default Radiobutton;
