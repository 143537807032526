import { array } from '@dc-framework/js-utils';
import Tag from '../models/Tag';
const { sort } = array;

export const getInputTagFormat = tags => {
  if (tags && tags.length) {
    const formatedTags = tags.map(t => new Tag(t));
    return sort(formatedTags, { keys: [{ key: 'label' }] });
  }
  return [];
};
