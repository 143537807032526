import gql from 'graphql-tag';
import { doMutation, doQuery } from '../api';

export const QUERY_GET_CARDS = gql`
  query getCards($entityId: ID!) {
    getCards(entityId: $entityId) {
      id
      slug
      title
      description
      order
      date_published
      date_created
      favourite
      featured
      private
      tags {
        id
        slug
        name
        meta
        tagEntity {
          id
        }
      }
      cardEntities {
        id
        entityId
        breadcrumb
        views
      }
      thumb {
        id
        linkId
        url
        key
        filename
        type
        status
        label
      }
    }
  }
`;

export const refetchCards = async entityId => {
  await doQuery({
    query: QUERY_GET_CARDS,
    variables: { entityId: entityId }
  });
};

export const QUERY_GET_CARDS_BY_TOPIC_ID = gql`
  query getCardsBySlug($entitySlug: String, $subEntitySlug: String) {
    getCardsBySlug(entitySlug: $entitySlug, subEntitySlug: $subEntitySlug) {
      id
      slug
      title
      description
      order
      date_published
      favourite
      featured
      private
      tags {
        id
        slug
        name
        meta
        tagEntity {
          id
        }
      }
      thumb {
        id
        linkId
        url
        key
        filename
        type
        status
        label
      }
      cardEntity {
        id
        order
      }
      cardEntities {
        id
        entityId
        breadcrumb
        views
      }
    }
  }
`;

export const refetchCard = async cardSlug => {
  await doQuery({
    query: QUERY_GET_CARD_BY_SLUG,
    variables: { cardSlug }
  });
};

export const QUERY_GET_CARD_BY_SLUG = gql`
  query getCardBySlug($cardSlug: String) {
    getCardBySlug(cardSlug: $cardSlug) {
      id
      title
      slug
      description
      aliases
      thumb {
        id
        linkId
        url
        key
        filename
        type
        status
        label
      }
      order
      date_published
      favourite
      featured
      private
      tags {
        id
        slug
        name
        meta
        tagEntity {
          id
        }
      }
      categories {
        id
        parentId
        name
        slug
        order
        status
      }
      cardEntities {
        id
        entityId
        breadcrumb
        views
      }
      cardItems {
        cardId
        id
        type
        text
        files {
          type
          name
          url
          file {
            id
            linkId
            url
            filename
            type
            status
            ext
            label
          }
        }
        order
      }
    }
  }
`;

export const MUTATION_ADD_CARD = gql`
  mutation createCard(
    $title: String
    $description: String
    $aliases: String
    $thumbFileId: ID
    $featured: Boolean
    $private: Boolean
    $date_published: AWSDateTime
    $entityId: ID
  ) {
    createCard(
      input: {
        title: $title
        description: $description
        aliases: $aliases
        thumbFileId: $thumbFileId
        featured: $featured
        private: $private
        date_published: $date_published
        entityId: $entityId
      }
    ) {
      id
      slug
    }
  }
`;

export const addCardEntity = async variables => {
  return new Promise(async (resolve, reject) => {
    const mutation = MUTATION_ADD_CARD_TO_ENTITY;
    return await doMutation({
      mutation,
      variables,
      update: (cache, { data, data: { createCardEntity } }) => {
        if (createCardEntity && createCardEntity.id) {
          resolve(createCardEntity);
        }
      }
    });
  });
};

export const MUTATION_ADD_CARD_TO_ENTITY = gql`
  mutation createCardEntity(
    $cardId: ID!
    $entitySlug: String
    $topLevelEntityId: ID
  ) {
    createCardEntity(
      input: {
        cardId: $cardId
        entitySlug: $entitySlug
        topLevelEntityId: $topLevelEntityId
      }
    ) {
      id
      entityId
      breadcrumb
    }
  }
`;

export const MUTATION_UPDATE_CARD = gql`
  mutation editCard(
    $id: ID!
    $title: String
    $description: String
    $aliases: String
    $thumbFileId: ID
    $featured: Boolean
    $private: Boolean
    $date_published: AWSDateTime
  ) {
    editCard(
      input: {
        id: $id
        title: $title
        description: $description
        aliases: $aliases
        thumbFileId: $thumbFileId
        featured: $featured
        private: $private
        date_published: $date_published
      }
    ) {
      id
      title
      slug
      description
    }
  }
`;

export const MUTATION_REMOVE_CARD_ENTITY = gql`
  mutation archiveCardEntity($cardId: ID!, $entitySlug: String, $entityId: ID) {
    archiveCardEntity(
      cardId: $cardId
      entitySlug: $entitySlug
      entityId: $entityId
    )
  }
`;

export const removeCardEntity = async variables => {
  return new Promise(async (resolve, reject) => {
    const mutation = MUTATION_REMOVE_CARD_ENTITY;
    return await doMutation({
      mutation,
      variables,
      update: (cache, { data, data: { archiveCardEntity } }) => {
        if (archiveCardEntity === true) {
          resolve(archiveCardEntity);
        }
      }
    });
  });
};

export const MUTATION_REMOVE_CARD = gql`
  mutation archiveCard($cardId: ID!) {
    archiveCard(cardId: $cardId)
  }
`;

export const MUTATION_ADD_CARD_ITEM = gql`
  mutation createCardItem(
    $cardId: ID!
    $type: CardItemType
    $text: String
    $order: Int
    $files: [CardItemFileInput]
  ) {
    createCardItem(
      input: {
        cardId: $cardId
        type: $type
        text: $text
        order: $order
        files: $files
      }
    ) {
      id
      cardId
      type
      text
      order
    }
  }
`;

export const addCardItem = async variables => {
  return new Promise(async (resolve, reject) => {
    const mutation = MUTATION_ADD_CARD_ITEM;
    return await doMutation({
      mutation,
      variables,
      update: (cache, { data, data: { createCardItem } }) => {
        if (createCardItem && createCardItem.id) {
          resolve(createCardItem.id);
        }
      }
    });
  });
};

export const MUTATION_UPDATE_CARD_ITEM = gql`
  mutation editCardItem(
    $id: ID!
    $type: CardItemType
    $text: String
    $order: Int
    $files: [CardItemFileInput]
  ) {
    editCardItem(
      input: { id: $id, type: $type, text: $text, order: $order, files: $files }
    ) {
      id
      cardId
      type
      text
      order
    }
  }
`;

export const updateCardItem = async variables => {
  return new Promise(async (resolve, reject) => {
    const mutation = MUTATION_UPDATE_CARD_ITEM;
    return await doMutation({
      mutation,
      variables,
      update: (cache, { data, data: { editCardItem } }) => {
        if (editCardItem && editCardItem.id) {
          resolve(editCardItem.id);
        }
      }
    });
  });
};

export const MUTATION_REMOVE_CARD_ITEM = gql`
  mutation archiveCardItem($cardItemId: ID!) {
    archiveCardItem(cardItemId: $cardItemId)
  }
`;

export const MUTATION_UPDATE_CARDS_ORDER = gql`
  mutation sortCardEntities($sortItemInput: [SortItemInput]) {
    sortCardEntities(input: $sortItemInput)
  }
`;

export const updateCardsOrder = async (variables, refetchQueries) => {
  return new Promise(async (resolve, reject) => {
    const mutation = MUTATION_UPDATE_CARDS_ORDER;
    return await doMutation({
      mutation,
      variables,
      update: (cache, { data, data: { sortCardItems } }) => {
        if (sortCardItems) {
          resolve(sortCardItems);
        }
      },
      refetchQueries
    });
  });
};

export const handleCardEntities = async ({
  currentEntities,
  previousEntities,
  cardId,
  entityId
}) => {
  const cardsToLink = currentEntities.filter(
    st => !previousEntities.find(pt => pt.entityId === st.entityId)
  );

  const cardsToRemove = previousEntities.filter(
    st => !currentEntities.find(pt => pt.entityId === st.entityId)
  );
  await Promise.all(
    cardsToLink.map(async (e, i) => {
      return await addCardEntity({
        entitySlug: e.slug,
        cardId,
        topLevelEntityId: entityId
      });
    })
  );

  await Promise.all(
    cardsToRemove.map(async (e, i) => {
      return await removeCardEntity({
        entityId: e.entityId,
        cardId
      });
    })
  );
};

export const MUTATION_EXPORT_CARDS = gql`
  mutation getCardsExport {
    getCardsExport
  }
`;

export const QUERY_EXPORT_CARDS = gql`
  query getCardsExport {
    getCardsExport
  }
`;
/*export const getExportUrl = async () => {
  await doQuery({
    query: MUTATION_EXPORT_CARDS
  });
};*/

export const getExportUrl = async variables => {
  return new Promise(async (resolve, reject) => {
    const mutation = MUTATION_EXPORT_CARDS;
    return await doMutation({
      mutation,
      variables,
      update: (cache, { data, data: { getCardsExport } }) => {
        resolve(getCardsExport);
      }
    });
  });
};
