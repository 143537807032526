import React from 'react';
import { Mutation } from 'react-apollo';
import { injectIntl } from 'react-intl';
import { CardItemType } from '../../../constants';
import { ContentBlockYouTube } from './content-blocks/ContentBlockYouTube';
import { ContentBlockMultiSkillz } from './content-blocks/ContentBlockMultiSkillz';
import { ContentBlockText } from './content-blocks/ContentBlockText';
import { ContentBlockImage } from './content-blocks/ContentBlockImage';
import { ContentBlockFile } from './content-blocks/ContentBlockFile';
import { ButtonsGroup } from '../../../lib/components/button';
import { defaultFormButtons } from '../../../lib/helpers/copy';
import { MUTATION_REMOVE_CARD_ITEM } from '../../../services/graphql/card-query';
import { Icon } from '../../../lib/components/icon';
import { CardActions } from '../../../lib/components/card';
import ConfirmButton from '../../../lib/containers/modal/ConfirmButton';
import messages from './messages';

const getComponent = contentBlock => {
  switch (contentBlock.type) {
    case CardItemType.YOUTUBE:
      return ContentBlockYouTube;
    case CardItemType.MULTISKILLZ:
      return ContentBlockMultiSkillz;
    case CardItemType.TEXT:
      return ContentBlockText;
    case CardItemType.IMAGE:
      return ContentBlockImage;
    case CardItemType.FILE:
      return ContentBlockFile;
    default:
      console.log('Type not found');
  }
};
export const ContentBlockItem = injectIntl(
  ({
    intl,
    contentBlock,
    onDeleteItem,
    onConfirmModal,
    disabled,
    ...props
  }) => {
    const Component = getComponent(contentBlock);
    return (
      <div className="c-content-block__body">
        <CardActions>
          <ButtonsGroup>
            <Mutation mutation={MUTATION_REMOVE_CARD_ITEM}>
              {(mutation, data) => {
                return (
                  <ConfirmButton
                    disabled={disabled}
                    small
                    actionAlert
                    icon
                    type="button"
                    confirmTitle={intl.formatMessage(
                      messages.modalConfirmRemoveCardItemTitle
                    )}
                    confirmMessage={intl.formatMessage(
                      messages.modalConfirmRemoveCardItemMessage
                    )}
                    confirmOkHandler={() => {
                      onDeleteItem(contentBlock);
                      !contentBlock.__isNew__ &&
                        mutation({
                          variables: { cardItemId: contentBlock.id }
                        });
                    }}
                  >
                    <Icon id="remove" title={defaultFormButtons.DELETE} />
                  </ConfirmButton>
                );
              }}
            </Mutation>
          </ButtonsGroup>
        </CardActions>
        <Component contentBlock={contentBlock} disabled={disabled} {...props} />
      </div>
    );
  }
);
