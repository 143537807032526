import { defineMessages } from 'react-intl';

export default defineMessages({
  titleLabelGroups: {
    id: 'title.label.groups',
    defaultMessage: 'Groups'
  },
  titleLabelAddGroup: {
    id: 'title.label.add.group',
    defaultMessage: 'Add group'
  },
  titleLabelEditGroup: {
    id: 'title.label.edit.group',
    defaultMessage: 'Edit group'
  },
  buttonGoToTopics: {
    id: 'button.goto.topics',
    defaultMessage: 'Topics'
  },
  modalConfirmRemoveGroupTitle: {
    id: 'modal.confirm.remove.group.title',
    defaultMessage: 'Remove group'
  },
  modalConfirmRemoveGroupMessage: {
    id: 'modal.confirm.remove.group.message',
    defaultMessage: 'Are you sure you want to delete this group?'
  },
  notificationMessageGroupSaved: {
    id: 'notification.message.group.saved',
    defaultMessage: 'Group saved'
  },
  notificationMessageGroupRemoved: {
    id: 'notification.message.group.removed',
    defaultMessage: 'Group removed'
  },
  groupSelectExistingImage: {
    id: 'group.select.existing.image',
    defaultMessage: 'or choose existing image'
  }
});
