import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { createDraggableItem, Grabzone } from './dnd';

const DndWrapperContext = React.createContext();

const wrapperDraggableItem = ({ ...props }) =>
  createDraggableItem({
    ...props
  });

export default class DndWrapper extends Component {
  static Item = ({ children, ...props }) => (
    <DndWrapperContext.Consumer>
      {({ onDragUpdate, onDragStart, onDragEnd, DraggableItem }) => {
        return (
          <DraggableItem
            {...props}
            onDragUpdate={onDragUpdate}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
          >
            {children}
          </DraggableItem>
        );
      }}
    </DndWrapperContext.Consumer>
  );
  static Grabzone = ({ children }) => (
    <DndWrapperContext.Consumer>
      {() => {
        return <Grabzone>{children}</Grabzone>;
      }}
    </DndWrapperContext.Consumer>
  );

  onDragStart = () => {
    const { onDragStart } = this.props;
    onDragStart && onDragStart();
  };

  onDragUpdate = (dragIndex, hoverIndex) => {
    const { items, onDragUpdate } = this.props;
    const dragCard = items[dragIndex];
    const updatedItems = update(items, {
      $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]]
    });
    onDragUpdate && onDragUpdate(updatedItems);
  };

  onDragEnd = () => {
    const { onDragEnd } = this.props;
    onDragEnd && onDragEnd();
  };

  render() {
    const {
      children,
      itemParentStyle,
      placeholderOpacity,
      component = ({ children }) => <>{children}</>,
      dropComponent,
      type
    } = this.props;
    if (!this.draggableItem)
      this.draggableItem = wrapperDraggableItem({
        Component: component,
        DropComponent: dropComponent,
        parentStyle: itemParentStyle,
        type,
        placeholderOpacity
      });
    return (
      <DndWrapperContext.Provider
        value={{
          onDragUpdate: this.onDragUpdate,
          DraggableItem: this.draggableItem,
          onDragStart: this.onDragStart,
          onDragEnd: this.onDragEnd
        }}
      >
        {children}
      </DndWrapperContext.Provider>
    );
  }
}

DndWrapper.propTypes = {
  children: PropTypes.any,
  component: PropTypes.any,
  dropComponent: PropTypes.any,
  itemParentStyle: PropTypes.object,
  items: PropTypes.array.isRequired,
  onDragUpdate: PropTypes.func,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  placeholderOpacity: PropTypes.number,
  type: PropTypes.string.isRequired
};
