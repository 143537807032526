import React from 'react';
import { Rehydrated } from 'aws-appsync-react';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import { Provider, observer } from 'mobx-react';
import { IntlProvider } from 'react-intl';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import { DragDropContextProvider } from 'react-dnd';
import { syncHistoryWithStore } from 'mobx-react-router';
import HTML5Backend from 'react-dnd-html5-backend';
import { RootStore, StoresContext } from '../../../stores';
import IntlWrapper from './IntlWrapper';
import AppModalWrapper from './AppModalWrapper';
import AppNotificationWrapper from './AppNotificationWrapper';
import AppLoaderWrapper from './AppLoaderWrapper';
import { AppSelectFileWrapper } from '../../../containers/app-containers/AppSelectFileWrapper';

const ApolloAppContainer = ({
  client,
  rootStore = new RootStore(),
  translationMessages,
  children
}) => {
  const { locale } = rootStore.uiState;
  const browserHistory = createBrowserHistory();
  const history = syncHistoryWithStore(browserHistory, rootStore.routing);
  return (
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        <Rehydrated>
          <Provider rootStore={rootStore}>
            <StoresContext.Provider value={rootStore}>
              <IntlProvider
                locale={locale}
                messages={translationMessages[locale]}
              >
                <IntlWrapper>
                  <Router history={history}>
                    <DragDropContextProvider backend={HTML5Backend}>
                      {children}
                      <AppSelectFileWrapper />
                      <AppModalWrapper />
                      <AppNotificationWrapper />
                      <AppLoaderWrapper />
                    </DragDropContextProvider>
                  </Router>
                </IntlWrapper>
              </IntlProvider>
            </StoresContext.Provider>
          </Provider>
        </Rehydrated>
      </ApolloHooksProvider>
    </ApolloProvider>
  );
};

export default observer(ApolloAppContainer);
