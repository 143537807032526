import { form } from '@dc-framework/js-utils';
import messages from './messages';

const {
  VALIDATE_TYPES: {
    ERROR_REQUIRED,
    ERROR_NOT_EQUAL,
    ERROR_MIN_LENGTH,
    ERROR_MAX_LENGTH,
    ERROR_NO_ALPHABETICAL_CHAR,
    ERROR_NO_NUMERICAL_CHAR,
    ERROR_NO_SPECIAL_CHAR,
    ERROR_EMAIL,
    ERROR_REQUIRED_CHECKBOX,
    ERROR_REQUIRED_CHECKBOX_GROUP,
    ERROR_REQUIRED_RADIOBUTTON,
    ERROR_MIN_ARRAY_LENGTH,
    ERROR_UNKNOWN
  }
} = form;

export let defaultFormMessages;
export let defaultFormButtons;
export let defaultFormLabels;
export let defaultTitleLabels;
export let defaultCardLabels;

const _getCopy = (intl, message, defaultMessage) => {
  let copy = defaultMessage;
  try {
    copy = intl.formatMessage(messages[message]);
  } catch (er) {}
  return copy;
};

const _getCopyObject = (intl, defaultCopy) => {
  Object.keys(defaultCopy).forEach(key => {
    defaultCopy[key] = _getCopy(intl, key, defaultCopy[key]);
  });
  return defaultCopy;
};

const _setDefaultFormMessages = intl => {
  let defaultCopy = {
    errorMessageRequired: '$label is required.',
    errorMessageNoAlphabeticalChar:
      '$label must contain at least 1 alphabetical character.',
    errorMessageNoNumericChar:
      '$label must contain at least 1 numerical character.',
    errorMessageNoSpecialChar:
      '$label must contain at least 1 special character.',
    errorMessageNotEqual: '$label is not equal.',
    errorMessageMinLength: '$label is too short.',
    errorMessageMaxLength: '$label is too long.',
    errorMessageEmail: '$label is not valid.',
    errorMessageRequiredCheckbox: '$label must be checked.',
    errorMessageRequiredCheckboxGroup: 'At least 1 item must be checked.',
    errorMessageRadioButton: '$label is required.',
    errorMessageMinArrayLength: '$label is required.',
    errorMessageUnknown: 'Something went wrong.',
    errorMessageFileUploadFailed: '$filename failed to upload.',
    errorMessageFileUpdateFailed: '$filename failed to update.',
    errorMessageFileDeleteFailed: '$filename failed to delete.',
    warningMessageNoOptions: 'No options',
    warningMessageCreateValue: 'Create $value',
    defaultMessagePlaceholder: 'Enter $label...'
  };

  if (intl) {
    defaultCopy = _getCopyObject(intl, defaultCopy);
  }

  defaultFormMessages = Object.freeze({
    [ERROR_REQUIRED]: defaultCopy.errorMessageRequired,
    [ERROR_NO_ALPHABETICAL_CHAR]: defaultCopy.errorMessageNoAlphabeticalChar,
    [ERROR_NO_NUMERICAL_CHAR]: defaultCopy.errorMessageNoNumericChar,
    [ERROR_NO_SPECIAL_CHAR]: defaultCopy.errorMessageNoSpecialChar,
    [ERROR_NOT_EQUAL]: defaultCopy.errorMessageNotEqual,
    [ERROR_MIN_LENGTH]: defaultCopy.errorMessageMinLength,
    [ERROR_MAX_LENGTH]: defaultCopy.errorMessageMaxLength,
    [ERROR_EMAIL]: defaultCopy.errorMessageEmail,
    [ERROR_REQUIRED_CHECKBOX]: defaultCopy.errorMessageRequiredCheckbox,
    [ERROR_REQUIRED_CHECKBOX_GROUP]:
      defaultCopy.errorMessageRequiredCheckboxGroup,
    [ERROR_REQUIRED_RADIOBUTTON]: defaultCopy.errorMessageRadioButton,
    [ERROR_MIN_ARRAY_LENGTH]: defaultCopy.errorMessageMinArrayLength,
    [ERROR_UNKNOWN]: defaultCopy.errorMessageUnknown,
    ERROR_FILE_UPLOAD_FAILED: defaultCopy.errorMessageFileUploadFailed,
    ERROR_FILE_DELETE_FAILED: defaultCopy.errorMessageFileDeleteFailed,
    ERROR_FILE_UPDATE_FAILED: defaultCopy.errorMessageFileUpdateFailed,
    WARNING_NO_OPTIONS: defaultCopy.warningMessageNoOptions,
    WARNING_CREATE_VALUE: defaultCopy.warningMessageCreateValue,
    DEFAULT_PLACEHOLDER: defaultCopy.defaultMessagePlaceholder
  });
};

const _setDefaultFormButtons = intl => {
  let defaultCopy = {
    buttonLabelSave: 'Save',
    buttonLabelCancel: 'Cancel',
    buttonLabelOK: 'OK',
    buttonLabelLogin: 'Login',
    buttonLabelLogout: 'Logout',
    buttonLabelBack: 'Back',
    buttonLabelForgotPassword: 'Forgot password?',
    buttonLabelResetPassword: 'Reset password',
    buttonLabelCreate: 'Create',
    buttonLabelDelete: 'Delete',
    buttonLabelRemove: 'Remove',
    buttonLabelEdit: 'Edit',
    buttonLabelUpdate: 'Update',
    buttonLabelReset: 'Reset',
    buttonLabelDetail: 'Detail',
    buttonLabelView: 'View',
    buttonLabelSelectFiles: 'Select file(s)',
    buttonLabelUpload: 'Upload'
  };

  if (intl) {
    defaultCopy = _getCopyObject(intl, defaultCopy);
  }

  defaultFormButtons = Object.freeze({
    SAVE: defaultCopy.buttonLabelSave,
    CANCEL: defaultCopy.buttonLabelCancel,
    OK: defaultCopy.buttonLabelOK,
    LOGIN: defaultCopy.buttonLabelLogin,
    LOGOUT: defaultCopy.buttonLabelLogout,
    BACK: defaultCopy.buttonLabelBack,
    FORGOT_PASSWORD: defaultCopy.buttonLabelForgotPassword,
    RESET_PASSWORD: defaultCopy.buttonLabelResetPassword,
    CREATE: defaultCopy.buttonLabelCreate,
    DELETE: defaultCopy.buttonLabelDelete,
    REMOVE: defaultCopy.buttonLabelRemove,
    UPDATE: defaultCopy.buttonLabelUpdate,
    EDIT: defaultCopy.buttonLabelEdit,
    RESET: defaultCopy.buttonLabelReset,
    DETAIL: defaultCopy.buttonLabelDetail,
    VIEW: defaultCopy.buttonLabelView,
    SELECT_FILES: defaultCopy.buttonLabelSelectFiles,
    UPLOAD: defaultCopy.buttonLabelUpload
  });
};

const _setDefaultFormLabels = intl => {
  let defaultCopy = {
    formLabelPassword: 'Password',
    formLabelEmail: 'E-mail address',
    formLabelConfirmPassword: 'Confirm password'
  };

  if (intl) {
    defaultCopy = _getCopyObject(intl, defaultCopy);
  }

  defaultFormLabels = Object.freeze({
    PASSWORD: defaultCopy.formLabelPassword,
    EMAIL: defaultCopy.formLabelEmail,
    CONFIRM_PASSWORD: defaultCopy.formLabelConfirmPassword
  });
};

const _setDefaultTitleLabels = intl => {
  let defaultCopy = {
    titleLabelLoginTitle: 'Welcome,',
    titleLabelLoginSubtitle: 'Login to your account..',
    titleLabelForgotLoginTitle: 'Forgot your password?!',
    titleLabelForgotLoginSubtitle: 'Receive an e-mail for password reset',
    titleLabelForgotLoginSuccessTitle: 'Forgot your password?!',
    titleLabelForgotLoginSuccessSubtitle:
      'Receive an e-mail for password reset',
    titleLabelSetPasswordTitle: 'Hi $name',
    titleLabelSetPasswordSubtitle:
      'Create a new password to log in to your account'
  };

  if (intl) {
    defaultCopy = _getCopyObject(intl, defaultCopy);
  }

  defaultTitleLabels = Object.freeze({
    LOGIN_TITLE: defaultCopy.titleLabelLoginTitle,
    LOGIN_SUBTITLE: defaultCopy.titleLabelLoginSubtitle,
    FORGOT_LOGIN_TITLE: defaultCopy.titleLabelForgotLoginTitle,
    FORGOT_LOGIN_SUBTITLE: defaultCopy.titleLabelForgotLoginSubtitle,
    FORGOT_LOGIN_SUCCESS_TITLE: defaultCopy.titleLabelForgotLoginSuccessTitle,
    FORGOT_LOGIN_SUCCESS_SUBTITLE:
      defaultCopy.titleLabelForgotLoginSuccessSubtitle,
    SET_PASSWORD_TITLE: defaultCopy.titleLabelSetPasswordTitle,
    SET_PASSWORD_SUBTITLE: defaultCopy.titleLabelSetPasswordSubtitle
  });
};

const _setDefaultCardLabels = intl => {
  let defaultCopy = {
    cardLabelForgotLoginSuccess: 'E-mail sent to: $email',
    cardLabelLoading: 'Loading...'
  };

  if (intl) {
    defaultCopy = _getCopyObject(intl, defaultCopy);
  }

  defaultCardLabels = Object.freeze({
    FORGOT_LOGIN_SUCCESS_SEND_EMAIL: defaultCopy.cardLabelForgotLoginSuccess,
    LOADING: defaultCopy.cardLabelLoading
  });
};

export const setMessages = intl => {
  _setDefaultFormMessages(intl);
  _setDefaultFormButtons(intl);
  _setDefaultFormLabels(intl);
  _setDefaultTitleLabels(intl);
  _setDefaultCardLabels(intl);
};

setMessages();
