import React, { useContext, useEffect } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { StoresContext } from '../../../../stores';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-apollo-hooks';
import {
  defaultCardLabels,
  defaultFormButtons
} from '../../../../lib/helpers/copy/default-copy';
import { Wrapper } from '../../../../lib/components/page-layout';
import TopicForm from './TopicForm';
import { Button } from '../../../../lib/components/button/Button';
import { ButtonsGroup } from '../../../../lib/components/button/ButtonsGroup';
import { QUERY_GET_TOPIC_BY_SLUG } from '../../../../services/graphql/topic-query';
import messages from './messages';
import { ROUTE_TOPIC_EDIT, ROUTE_TOPICS } from '../../../../routes/RouteList';
import { getPath } from '../path-generator';
import { Icon } from '../../../../lib/components/icon';
import { useGroup } from './useGroup';
import Loader from '../../../../components/loader/Loader';

const Topic = ({ groupSlug, topicSlug, edit }) => {
  const { routing, uiState } = useContext(StoresContext);
  const { groupTitle } = useGroup(groupSlug);
  useEffect(() => uiState.setPageTitle(groupTitle));

  const queryProps = {
    query: QUERY_GET_TOPIC_BY_SLUG,
    variables: { slug: topicSlug }
  };
  const { data, error, loading } = useQuery(queryProps.query, {
    variables: queryProps.variables
  });

  if (loading)
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  if (error) return <Wrapper>{`Error! ${error.message}`}</Wrapper>;

  const onNoEdit = () => routing.push(getPath(ROUTE_TOPICS, groupSlug));
  const onEdit = () =>
    routing.push(getPath(ROUTE_TOPIC_EDIT, groupSlug, topicSlug));

  return (
    <>
      <div className="o-layout">
        <div className="o-layout__item u-1-of-5-at-medium">
          <h2 className="u-margin-bottom-medium">
            <FormattedMessage {...messages.titleLabelEditTopic} />
          </h2>
          <ButtonsGroup extraClassNames="u-margin-bottom-large">
            <Button
              ghost
              iconLeft
              onClick={() => routing.push(getPath(ROUTE_TOPICS, groupSlug))}
            >
              <Icon id="left" />
              {defaultFormButtons.BACK}
            </Button>
          </ButtonsGroup>
        </div>
        <div className="o-layout__item u-4-of-5-at-medium">
          <TopicForm
            edit={edit}
            groupSlug={groupSlug}
            loading={loading}
            topic={data.getEntityBySlug}
            onMutationComplete={onNoEdit}
            onCancel={onNoEdit}
            onEdit={onEdit}
          />
        </div>
      </div>
    </>
  );
};
export default hooksObserver(Topic);
