import React from 'react';
import classNames from 'classnames';

class Tag extends React.Component {
  render() {
    const { children, extraClassNames } = this.props;
    return (
      <span className={classNames('c-tag', extraClassNames)}>{children}</span>
    );
  }
}

export default Tag;
