import React, { useContext } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { StoresContext } from '../../stores';
import SelectFileModal from '../partials/modal/SelectFileModal';

export const AppSelectFileWrapper = hooksObserver(() => {
  const {
    uiState: { fileModal }
  } = useContext(StoresContext);
  return (
    <>
      {fileModal && (
        <SelectFileModal
          {...fileModal.otherProps}
          fileType={fileModal.type}
          onSelect={fileModal.onSelect}
          onClose={fileModal.onClose}
        />
      )}
    </>
  );
});
