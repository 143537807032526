import Item from './Item';
import Card from './Card';
import Tag from './Tag';

export default class Topic extends Item {
  cards;
  constructor({ cards, tags, ...props }) {
    super({ ...props });
    this.cards = cards && cards.length ? cards.map(t => new Card(t)) : [];
    this.tags = tags && tags.length ? tags.map(t => new Tag(t)) : [];
  }
}
