import React from 'react';
import classNames from 'classnames';
import './Textarea.scss';
import { Notification } from '../notification';

const Textarea = React.forwardRef((props, ref) => {
  const {
    id,
    name,
    rows = 4,
    maxLength = 150,
    onChange,
    disabled,
    readOnly,
    hasError,
    errorMessage,
    label,
    ...inputProps
  } = props;
  return (
    <>
      <div
        className={classNames('c-input', {
          'c-input--disabled': disabled,
          'c-input--readonly': readOnly,
          'c-input--error': hasError
        })}
      >
        <label htmlFor={id} className={classNames('c-input__label')}>
          {label}
        </label>
        <textarea
          {...inputProps}
          ref={ref}
          id={id}
          name={name}
          rows={rows}
          className={classNames(
            'c-input__field c-input__textarea-resize-vertical'
          )}
          onChange={onChange ? onChange : e => e.stopPropagation()}
          onClick={onChange ? onChange : e => e.stopPropagation()}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
        />
        {errorMessage && (
          <Notification errorMessage>{errorMessage}</Notification>
        )}
      </div>
    </>
  );
});

export default Textarea;
