import { defineMessages } from 'react-intl';

export default defineMessages({
  titleLabelTopics: {
    id: 'title.label.topic',
    defaultMessage: 'Topics'
  },
  titleLabelAddTopic: {
    id: 'title.label.add.topic',
    defaultMessage: 'Add topic'
  },
  titleLabelEditTopic: {
    id: 'title.label.edit.topic',
    defaultMessage: 'Edit topic'
  },
  buttonGotoCards: {
    id: 'button.goto.cards',
    defaultMessage: 'Cards'
  },
  modalConfirmRemoveTopicTitle: {
    id: 'modal.confirm.remove.topic.title',
    defaultMessage: 'Remove topic'
  },
  modalConfirmRemoveTopicMessage: {
    id: 'modal.confirm.remove.topic.message',
    defaultMessage: 'Are you sure you want to delete this topic?'
  },
  notificationMessageTopicSaved: {
    id: 'notification.message.topic.saved',
    defaultMessage: 'Topic saved'
  },
  notificationMessageTopicRemoved: {
    id: 'notification.message.topic.removed',
    defaultMessage: 'Topic removed'
  },
  topicSelectExistingImage: {
    id: 'topic.select.existing.image',
    defaultMessage: 'or choose existing image'
  }
});
