import { observable, decorate, action } from 'mobx';
import {
  login,
  logout,
  setPassword,
  userData,
  forgotPassword,
} from '../services/api';
import { User } from '../models';

class AuthStore {
  isLoggedIn = false;
  entityId = null;
  user = null;
  tmpCognitoUser = null;
  tmpEmail = null;

  constructor(rootStore) {
    this.uiState = rootStore.uiState;
  }

  login = async (mail, password) => {
    this.uiState.increasePendingRequest();
    const user = await login(mail, password);
    this.uiState.decreasePendingRequest();
    if (!user.error) {
      this.setUser(user);
      return this.user;
    } else if (user.newPasswordRequired) {
      this.setTmpCognitoUser(user.cognitoUser);
    }
    return user;
  };

  setLoginState = async () => {
    if (userData) {
      this.setUser(userData);
    }
  };

  logout = async () => {
    this.uiState.increasePendingRequest();
    await logout();
    this.uiState.decreasePendingRequest();
    this.setUser(null);
  };

  setPassword = async (password) => {
    this.uiState.increasePendingRequest();
    const user = await setPassword(this.tmpCognitoUser, password);
    this.uiState.decreasePendingRequest();
    if (!user.error) {
      this.setUser(user);
      return this.user;
    }
    return user;
  };

  forgotPassword = async (email) => {
    await forgotPassword(email);
    this.setTmpEmail(email);
  };

  setIsLoggedIn(value) {
    this.isLoggedIn = value;
  }

  setEntityId(value) {
    this.entityId = value;
  }

  setUser(rawData) {
    if (rawData) {
      this.user = new User(rawData);
      this.setIsLoggedIn(true);
      this.setEntityId(this.user.personEntities[0].entityId);
    } else {
      this.user = null;
      this.setIsLoggedIn(false);
      this.setEntityId(null);
    }
  }

  setTmpCognitoUser(rawData) {
    this.tmpCognitoUser = rawData;
  }
  setTmpEmail(email) {
    this.tmpEmail = email;
  }
}

export default AuthStore;

decorate(AuthStore, {
  isLoggedIn: observable,
  entityId: observable,
  user: observable,
  tmpCognitoUser: observable,
  setEntityId: action,
  setIsLoggedIn: action,
  setUser: action,
  setTmpCognitoUser: action,
  setTmpEmail: action,
});
