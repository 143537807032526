import React from 'react';
import './Nav.scss';
import classNames from 'classnames';

class Nav extends React.Component {
  render() {
    const { menuTitle, menuLabel, children } = this.props;

    return (
      <nav className={classNames('c-nav')} aria-labelledby={menuLabel}>
        <h1 className={classNames('u-hidden-visually')} id={menuLabel}>
          {menuTitle}
        </h1>
        <ul className={classNames('c-nav__list')}>{children}</ul>
      </nav>
    );
  }
}

export default Nav;
