import React, { useContext, useEffect, useState } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { useQuery } from 'react-apollo-hooks';
import { FormattedMessage, injectIntl } from 'react-intl';
import { StoresContext } from '../../../../../stores';
import { defaultFormButtons } from '../../../../../lib/helpers/copy/default-copy';
import { Wrapper } from '../../../../../lib/components/page-layout';
import {
  QUERY_GET_CARD_BY_SLUG,
  refetchCard
} from '../../../../../services/graphql/card-query';
import CardForm from './CardForm';
import { Button } from '../../../../../lib/components/button/Button';
import { ButtonsGroup } from '../../../../../lib/components/button/ButtonsGroup';
import { getPath } from '../../path-generator';
import messages from './messages';
import {
  ROUTE_SLUG_CARD_EDIT,
  ROUTE_SLUG_CARDS
} from '../../../../../routes/RouteList';
import { Icon } from '../../../../../lib/components/icon';
import { default as ModelCard } from '../../../../../models/Card';
import { useTopicName } from './useTopicName';
import Loader from '../../../../../components/loader/Loader';

const Card = ({ intl, groupSlug, topicSlug, cardSlug, edit }) => {
  const { routing, uiState } = useContext(StoresContext);

  const { name } = useTopicName(topicSlug);
  useEffect(() => uiState.setPageTitle(name));

  const { data, error, loading } = useQuery(QUERY_GET_CARD_BY_SLUG, {
    variables: { cardSlug }
  });

  if (loading)
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  if (error) return <Wrapper>{`Error! ${error.message}`}</Wrapper>;

  const onNoEdit = () =>
    routing.push(getPath(ROUTE_SLUG_CARDS, groupSlug, topicSlug));
  const onEdit = () =>
    routing.push(getPath(ROUTE_SLUG_CARD_EDIT, groupSlug, topicSlug, cardSlug));

  return (
    <>
      <div className="o-layout">
        <div className="o-layout__item u-1-of-5-at-medium">
          <h2 className="u-margin-bottom-medium">
            <FormattedMessage {...messages.titleLabelEditCard} />
          </h2>
          <ButtonsGroup extraClassNames="u-margin-bottom-large">
            <Button
              ghost
              iconLeft
              onClick={() =>
                routing.push(getPath(ROUTE_SLUG_CARDS, groupSlug, topicSlug))
              }
            >
              <Icon id="left" />
              {defaultFormButtons.BACK}
            </Button>
          </ButtonsGroup>
        </div>
        <div className="o-layout__item u-4-of-5-at-medium">
          <CardForm
            edit={edit}
            topicSlug={topicSlug}
            groupSlug={groupSlug}
            loading={loading}
            card={new ModelCard(data.getCardBySlug)}
            onMutationComplete={async data => {
              await refetchCard(data.slug);
              routing.push(
                getPath(ROUTE_SLUG_CARD_EDIT, groupSlug, topicSlug, data.slug)
              );
              uiState.showNotification({
                message: intl.formatMessage(
                  messages.notificationMessageCardSaved
                )
              });
            }}
            onCancel={onNoEdit}
            onEdit={onEdit}
          />
        </div>
      </div>
    </>
  );
};
export default injectIntl(hooksObserver(Card));
