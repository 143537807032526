import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input } from '../../../components/input';
import { Textarea } from '../../../components/input-textarea';
import {
  Checkbox,
  CheckboxGroup
} from '../../../components/input-checkbox-radiobutton';
import RadioButtonGroup from '../../../components/input-checkbox-radiobutton/RadioButtonGroup';
import { Select, Option } from '../../../components/input-select';
import { InputTag } from '../../../components/input-tag';
import { Notification } from '../../../components/notification';
import { defaultFormMessages } from '../../../helpers/copy';
import FileUploader from '../../file-upload/FileUploader';

export const FORM_ITEM_TYPES = Object.freeze({
  TEXTFIELD: 'textfield',
  TEXTAREA: 'textarea',
  CHECKBOX: 'checkbox',
  CHECKBOX_GROUP: 'checkbox_group',
  SELECT: 'select',
  RADIOBUTTON_GROUP: 'radiobutton_group',
  TAG_INPUT: 'tag_input',
  FILE_UPLOADER: 'file_uploader',
  CUSTOM: 'custom'
});

export const INPUT_TYPES = Object.freeze({
  TEXT: 'text',
  NUMBER: 'number',
  DATE: 'date',
  EMAIL: 'email',
  PASSWORD: 'password',
  URL: 'url'
});

export default class FormItem extends Component {
  static propTypes = {
    edit: PropTypes.bool,
    loading: PropTypes.bool,
    errorMessage: PropTypes.string,
    visible: PropTypes.bool,
    disabled: PropTypes.bool,
    formItem: PropTypes.shape({
      key: PropTypes.string.isRequired,
      required: PropTypes.bool,
      controlledValue: PropTypes.bool,
      title: PropTypes.string,
      label: PropTypes.string,
      accept: PropTypes.string,
      fileUploadIcon: PropTypes.string,
      rows: PropTypes.any,
      maxLength: PropTypes.number,
      placeholder: PropTypes.string,
      autoPlaceholder: PropTypes.bool,
      autoPlaceholderLowerCase: PropTypes.bool,
      type: PropTypes.oneOf(Object.values(FORM_ITEM_TYPES)),
      inputType: PropTypes.oneOf(Object.values(INPUT_TYPES)),
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        })
      ),
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.array
      ]),
      validator: PropTypes.oneOfType([PropTypes.func, PropTypes.array])
    }).isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onInit: PropTypes.func,
    onOut: PropTypes.func,
    onUpdate: PropTypes.func,
    placeholder: PropTypes.string,
    autoPlaceholder: PropTypes.bool,
    autoPlaceholderLowerCase: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.array
    ]),
    valueError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  };

  // FIX for react controlled/undcontrolled switch warning
  static getDefaultValue = (value, formType) => {
    if (value === undefined || value === null) {
      const type = formType || FORM_ITEM_TYPES.TEXTFIELD;
      if (
        type === FORM_ITEM_TYPES.TEXTFIELD ||
        type === FORM_ITEM_TYPES.TEXTAREA ||
        type === FORM_ITEM_TYPES.SELECT ||
        type === FORM_ITEM_TYPES.RADIOBUTTON_GROUP
      ) {
        return '';
      } else if (type === FORM_ITEM_TYPES.CHECKBOX) {
        return false;
      } else if (
        type === FORM_ITEM_TYPES.CHECKBOX_GROUP ||
        type === FORM_ITEM_TYPES.TAG_INPUT ||
        type === FORM_ITEM_TYPES.FILE_UPLOADER
      ) {
        return [];
      }
    }
    return value;
  };

  componentDidMount() {
    const { formItem, onInit } = this.props;
    onInit && onInit({ formItem });
  }

  componentDidUpdate() {
    const { onUpdate, formItem } = this.props;
    onUpdate && onUpdate({ formItem });
  }

  componentWillUnmount() {
    const { formItem, onOut } = this.props;
    onOut && onOut({ formItem });
  }

  onBlur = e => {
    const { formItem, onBlur, value } = this.props;
    onBlur && onBlur({ e, key: formItem.key, value, formItem });
  };

  onChange = e => {
    const { formItem, onChange } = this.props;
    const type = formItem.type || FORM_ITEM_TYPES.TEXTFIELD;
    const inputType = formItem.inputType || INPUT_TYPES.TEXT;
    let value;
    if (
      type === FORM_ITEM_TYPES.CHECKBOX ||
      type === FORM_ITEM_TYPES.CHECKBOX_GROUP
    ) {
      value = e.target.checked;
    } else if (type === FORM_ITEM_TYPES.SELECT) {
      value = e.target.value;
    } else if (
      type === FORM_ITEM_TYPES.TAG_INPUT ||
      type === FORM_ITEM_TYPES.FILE_UPLOADER ||
      type === FORM_ITEM_TYPES.CUSTOM
    ) {
      value = e;
    } else {
      if (inputType === INPUT_TYPES.NUMBER) {
        value = e.target.value !== '' ? Number(e.target.value) : '';
      } else {
        value = e.target.value;
      }
    }
    onChange && onChange({ e, key: formItem.key, value, formItem });
  };

  render() {
    const {
      loading,
      formItem,
      edit,
      value,
      valueError,
      errorMessage,
      children,
      onInit,
      onOut,
      onUpdate,
      onBusy,
      visible = true,
      disabled,
      autoPlaceholder,
      autoPlaceholderLowerCase,
      maxLength,
      ...props
    } = this.props;
    const key = formItem.key;
    const type = formItem.type || FORM_ITEM_TYPES.TEXTFIELD;

    const inputType = formItem.inputType
      ? formItem.inputType
      : FORM_ITEM_TYPES.TEXTFIELD === type
      ? INPUT_TYPES.TEXT
      : undefined;

    const isLowerCasePlaceholder =
      autoPlaceholderLowerCase || formItem.autoPlaceholderLowerCase;
    const formLabel = formItem.label ? formItem.label : '';
    const compProps = {
      ...props,
      id: key,
      checked: value ? true : false,
      type: inputType,
      readOnly: !edit,
      value: FormItem.getDefaultValue(value, type),
      label: formLabel,
      hasError: valueError,
      errorMessage: errorMessage,
      onBlur: this.onBlur,
      onChange: this.onChange,
      options: formItem.options,
      title: formItem.title,
      accept: formItem.accept,
      rows: formItem.rows,
      multiple: formItem.multiple,
      maxLength: formItem.maxLength || maxLength,
      placeholder: edit
        ? formItem.placeholder
          ? formItem.placeholder
          : autoPlaceholder || formItem.autoPlaceholder
          ? defaultFormMessages.DEFAULT_PLACEHOLDER.replace(
              '$label',
              isLowerCasePlaceholder ? formLabel.toLowerCase() : formLabel
            )
          : ''
        : '',
      disabled: disabled || loading ? true : false
    };

    if (type === FORM_ITEM_TYPES.FILE_UPLOADER) {
      compProps.fileUploadIcon = formItem.fileUploadIcon;
      compProps.canDelete = formItem.canDelete;
      compProps.canEdit = formItem.canEdit;
      compProps.isGallery = formItem.isGallery;
      compProps.labelFileManager = formItem.labelFileManager;
      compProps.onBrowseFileManager = formItem.onBrowseFileManager;
      compProps.uploader = formItem.uploader;
      compProps.deleter = formItem.deleter;
      compProps.updater = formItem.updater;
    }

    if (visible === false || formItem.visible === false) {
      return null;
    }

    if (children) {
      return React.Children.map(children, child =>
        React.cloneElement(child, {
          ...compProps
        })
      );
    }

    switch (type) {
      case FORM_ITEM_TYPES.TEXTFIELD:
        return <Input {...compProps} />;
      case FORM_ITEM_TYPES.TEXTAREA:
        return <Textarea {...compProps} />;
      case FORM_ITEM_TYPES.CHECKBOX:
        return <Checkbox {...compProps}>{formLabel}</Checkbox>;
      case FORM_ITEM_TYPES.CHECKBOX_GROUP:
        return <CheckboxGroup {...compProps} />;
      case FORM_ITEM_TYPES.RADIOBUTTON_GROUP:
        return <RadioButtonGroup {...compProps} />;
      case FORM_ITEM_TYPES.SELECT:
        return (
          <Select {...compProps}>
            <Option
              value={''}
              label={edit ? formItem.placeholder : ''}
              hidden={formItem.placeholder ? false : true}
              disabled={true}
            />
            {formItem.options.map(o => (
              <Option key={o.value} value={o.value} label={o.label} />
            ))}
          </Select>
        );
      case FORM_ITEM_TYPES.TAG_INPUT:
        return (
          <InputTag
            {...compProps}
            labelNoOptions={
              formItem.labelNoOptions
                ? formItem.labelNoOptions
                : defaultFormMessages.WARNING_NO_OPTIONS
            }
            labelCreateValue={
              formItem.labelCreateValue
                ? formItem.labelCreateValue
                : defaultFormMessages.WARNING_CREATE_VALUE
            }
          />
        );
      case FORM_ITEM_TYPES.FILE_UPLOADER:
        return (
          <FileUploader
            {...compProps}
            onBusy={busy => onBusy && onBusy({ formItem, busy })}
          />
        );
      default:
        return (
          <Notification errorMessage show>
            No valid form type.
          </Notification>
        );
    }
  }
}
