import axios from 'axios';
import { doMutation } from './api';
import { MUTATION_ADD_FILE, QUERY_GET_FILES } from './graphql/file-query';
import File, { FILE_TYPES } from '../models/File';

export const uploader = async ({
  files,
  onProgress = () => {},
  onComplete = () => {},
  onFileComplete = () => {},
}) => {
  let successFiles = [];
  let failedFiles = [];
  const progressEvent = {
    totalFiles: files.length,
    loadedFiles: 0,
    currentProgress: 0,
    totalProgress: 0,
  };
  onProgress(progressEvent);

  for (const file of files) {
    // Add mutation file & upload the file
    const uploadedFile = await handleSingleFileUpload({
      file,
      onProgress: (e) => {
        progressEvent.currentProgress = e.loaded / e.total;
        progressEvent.totalProgress =
          (successFiles.length +
            failedFiles.length +
            progressEvent.currentProgress) /
          files.length;
        onProgress(progressEvent);
      },
    });

    // Fill loaded file arrays
    uploadedFile.error
      ? failedFiles.push(uploadedFile)
      : successFiles.push(uploadedFile);

    onFileComplete(uploadedFile);

    // Update progress
    progressEvent.currentProgress = 0;
    progressEvent.loadedFiles = successFiles.length + failedFiles.length;
    progressEvent.totalProgress =
      (successFiles.length + failedFiles.length) / files.length;
    onProgress(progressEvent);
  }
  onComplete && onComplete({ successFiles, failedFiles });
};

export const addFileMutation = async (variables) => {
  return new Promise(async (resolve, reject) => {
    await doMutation({
      mutation: MUTATION_ADD_FILE,
      variables,
      update: (cache, { data: { addFile } }) => {
        if (addFile && addFile.id) {
          resolve(addFile);
        }
      },
      refetchQueries: [
        {
          query: QUERY_GET_FILES,
        },
        {
          query: QUERY_GET_FILES,
          variables: { type: FILE_TYPES.IMAGE },
        },
        {
          query: QUERY_GET_FILES,
          variables: { type: FILE_TYPES.FILE },
        },
      ],
    });
  });
};

export const handleSingleFileUpload = async ({
  file,
  onProgress = () => {},
}) => {
  const addFile = await addFileMutation({
    filename: file.name,
    type: File.getType(file.type),
  });

  const uploadConfig = {
    onUploadProgress: onProgress,
    headers: { 'content-type': file.type },
  };
  try {
    const response = await axios.put(
      addFile.signedUploadUrl,
      file,
      uploadConfig
    );
    if (response.status === 200) {
      return new File({ ...addFile });
    } else {
      return { error: true, name: file.name, action: 'upload' };
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
    return { error: true, name: file.name, action: 'upload' };
  }
};
