import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { form } from '@dc-framework/js-utils';
import {
  defaultFormMessages,
  defaultFormLabels,
  defaultFormButtons,
  defaultTitleLabels
} from '../../../helpers/copy/default-copy';
import Form from '../../form/Form';
import {
  Card,
  CardHeader,
  CardFooter,
  CardBody
} from '../../../components/card';
import { INPUT_TYPES } from '../../form';
import { Notification } from '../../../components/notification/index';
import { ButtonsGroup } from '../../../components/button';

const {
  isPasswordValid,
  VALIDATE_TYPES: { ERROR_NOT_EQUAL }
} = form;

export default class SetPasswordView extends Component {
  static propTypes = {
    error: PropTypes.string,
    labelName: PropTypes.string,
    labelPassword: PropTypes.string,
    labelPasswordRepeat: PropTypes.string,
    labelSubmitButton: PropTypes.string,
    labelSubtitle: PropTypes.string,
    labelTitle: PropTypes.string,
    loading: PropTypes.bool,
    onSubmit: PropTypes.func,
    passwordValidator: PropTypes.func,
    noCard: PropTypes.bool,
    formMessages: PropTypes.object
  };

  state = {
    valid: false,
    isPasswordEqual: false
  };

  handleSubmit = async ({ password1, password2 }) => {
    if (password1 === password2) {
      const { onSubmit } = this.props;
      if (onSubmit) await onSubmit(password1);
    } else {
      this.setState({
        isPasswordEqual: false
      });
    }
  };

  onChange = ({ data, valid }) => {
    this.setState({
      valid,
      isPasswordEqual: data.password1 === data.password2
    });
  };

  render() {
    const {
      passwordValidator = isPasswordValid,
      labelTitle = defaultTitleLabels.SET_PASSWORD_TITLE,
      labelSubtitle = defaultTitleLabels.SET_PASSWORD_SUBTITLE,
      labelPassword = defaultFormLabels.PASSWORD,
      labelPasswordRepeat = defaultFormLabels.CONFIRM_PASSWORD,
      labelSubmitButton = defaultFormButtons.CREATE,
      labelName = '',
      error,
      loading = false,
      noCard,
      formMessages
    } = this.props;
    const { isPasswordEqual, valid } = this.state;
    const useFormMessages = { ...defaultFormMessages, ...formMessages };
    const notEqual = valid && !isPasswordEqual;
    const Wrapper = noCard ? Fragment : Card;
    return (
      <Wrapper>
        <CardHeader>
          {labelTitle && (
            <h2 className="u-h3">{labelTitle.replace('$name', labelName)}</h2>
          )}
          {labelSubtitle && <p>{labelSubtitle}</p>}
        </CardHeader>
        <Form
          onSubmit={this.handleSubmit}
          loading={loading}
          onChange={this.onChange}
        >
          <CardBody>
            <Form.ItemWrapper
              formItems={[
                {
                  key: 'password1',
                  label: labelPassword,
                  inputType: INPUT_TYPES.PASSWORD,
                  validator: [passwordValidator]
                },
                {
                  key: 'password2',
                  label: labelPasswordRepeat,
                  inputType: INPUT_TYPES.PASSWORD,
                  validator: [passwordValidator]
                }
              ]}
            />
            {notEqual && (
              <Notification errorMessage show>
                {useFormMessages[ERROR_NOT_EQUAL].replace(
                  '$label',
                  defaultFormLabels.PASSWORD
                )}
              </Notification>
            )}
            {error && !loading && (
              <Notification errorMessage show>
                {error}
              </Notification>
            )}
          </CardBody>
          <CardFooter>
            <ButtonsGroup>
              <Form.SubmitButton
                primary
                label={labelSubmitButton}
                disabled={notEqual}
              />
            </ButtonsGroup>
          </CardFooter>
        </Form>
      </Wrapper>
    );
  }
}
