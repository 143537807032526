import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  ROUTE_FORGOT,
  ROUTE_LOGIN,
  ROUTE_SET_PASSWORD,
  ROUTE_FORGOT_SUCCESS,
} from '../../../routes/RouteList';
import LoginContainer from '../../../lib/containers/auth/LoginContainer';
import { Card } from '../../../lib/components';

class Login extends Component {
  state = {
    error: null,
  };

  componentDidUpdate(prevProps) {
    const { type } = this.props;
    if (type !== prevProps.type) {
      this.setState({
        error: null,
      });
    }
  }
  onSubmitLogin = async (email, password) => {
    const {
      rootStore: {
        authStore,
        routing: { push },
      },
    } = this.props;
    const response = await authStore.login(email.toLowerCase(), password);
    if (response.error) {
      if (response.newPasswordRequired) {
        push(ROUTE_SET_PASSWORD);
      } else {
        this.setState({ error: response.error.message });
      }
    }
  };

  onSubmitSetPassword = async (...props) => {
    const {
      rootStore: { authStore },
    } = this.props;
    const response = await authStore.setPassword(...props);
    if (response.error) {
      this.setState({ error: response.error.message });
    }
  };

  onSubmitForgotPassword = async (...props) => {
    const {
      rootStore: {
        authStore,
        routing: { push },
      },
    } = this.props;
    await authStore.forgotPassword(...props);
    push(ROUTE_FORGOT_SUCCESS);
  };

  render() {
    const {
      type,
      rootStore: {
        routing: { push },
        authStore: { tmpEmail },
        uiState,
      },
    } = this.props;
    const { error } = this.state;
    return (
      <Card login>
        <LoginContainer
          noCard
          error={error}
          loading={uiState.hasPendingRequests}
          loginViewState={type}
          onSubmitLogin={this.onSubmitLogin}
          onSubmitSetPassword={this.onSubmitSetPassword}
          onSubmitForgotPassword={this.onSubmitForgotPassword}
          forgotPasswordEmail={tmpEmail}
          onForgotPasswordClicked={() => push(ROUTE_FORGOT)}
          onCancelForgotPasswordClicked={() => push(ROUTE_LOGIN)}
          onCancelForgotPasswordSuccessClicked={() => push(ROUTE_LOGIN)}
        />
      </Card>
    );
  }
}

export default inject('rootStore')(observer(Login));
