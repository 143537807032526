import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../components/button';
import {
  defaultCardLabels,
  defaultTitleLabels
} from '../../../helpers/copy/default-copy';
import {
  Card,
  CardHeader,
  CardFooter,
  CardBody
} from '../../../components/card';
// import Icon from '../../../components/icon/Icon';

export default class ForgotLoginSuccessView extends Component {
  static propTypes = {
    email: PropTypes.string,
    labelSendEmail: PropTypes.string,
    labelSubtitle: PropTypes.string,
    labelTitle: PropTypes.string,
    onCancel: PropTypes.func,
    noCard: PropTypes.bool
  };

  handleCancel = e => {
    e.preventDefault();
    const { onCancel } = this.props;
    if (onCancel) onCancel();
  };

  render() {
    const {
      noCard,
      labelTitle = defaultTitleLabels.FORGOT_LOGIN_SUCCESS_TITLE,
      labelSubtitle = defaultTitleLabels.FORGOT_LOGIN_SUCCESS_SUBTITLE,
      labelSendEmail = defaultCardLabels.FORGOT_LOGIN_SUCCESS_SEND_EMAIL,
      email = '',
      onCancel
    } = this.props;

    const Wrapper = noCard ? Fragment : Card;
    return (
      <Wrapper>
        <CardHeader>
          {labelTitle && <h2 className="u-h3">{labelTitle}</h2>}
          {labelSubtitle && <p>{labelSubtitle}</p>}
        </CardHeader>
        <CardBody>
          <p>{labelSendEmail.replace('$email', email)}</p>
        </CardBody>
        <CardFooter>
          {onCancel && (
            <Button type="button" primary onClick={e => this.handleCancel(e)}>
              {'Back' /*<Icon id="back-nav" mediumIcon />*/}
            </Button>
          )}
        </CardFooter>
      </Wrapper>
    );
  }
}
