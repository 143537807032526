import gql from 'graphql-tag';

export const QUERY_GET_FILES = gql`
  query getFiles($linkId: ID, $type: String) {
    getFiles(linkId: $linkId, type: $type) {
      id
      linkId
      url
      filename
      type
      status
      ext
      label
    }
  }
`;

export const QUERY_GET_FILE = gql`
  query getFile($fileId: ID!) {
    getFile(fileId: $fileId) {
      id
      linkId
      url
      filename
      type
      status
      ext
      label
    }
  }
`;

export const MUTATION_UPDATE_FILE = gql`
  mutation editFileLabel($fileId: ID!, $label: String!) {
    editFileLabel(fileId: $fileId, label: $label) {
      id
      linkId
      url
      filename
      type
      status
      signedUploadUrl
      ext
      label
    }
  }
`;

export const MUTATION_ADD_FILE = gql`
  mutation addFile($filename: String!, $type: String) {
    addFile(input: { filename: $filename, public: true, type: $type }) {
      id
      linkId
      url
      filename
      type
      status
      signedUploadUrl
      ext
      label
    }
  }
`;

export const MUTATION_ARCHIVE_FILE = gql`
  mutation archiveFile($fileId: ID!) {
    archiveFile(fileId: $fileId)
  }
`;
