import { guid } from '@dc-framework/js-utils';
import { CardItemFileType } from '../constants';

export default class CardItemFile {
  type;
  name;
  url;
  ext;
  fileId;
  meta;
  constructor({ id, type, name, url, ext, file, fileId, meta }) {
    this.id = id || guid();
    this.type = type;
    this.name = name;
    this.url = url;
    this.ext = ext;
    this.file = file;
    this.fileId = fileId;
    this.meta = meta;
    if (this.type === CardItemFileType.YOUTUBE) {
      this.file = undefined;
    }
  }
}
