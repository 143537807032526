import React, { useContext, useEffect } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { Switch, Route } from 'react-router-dom';
import { StoresContext } from '../../../stores';
import {
  ROUTE_GROUP_ADD,
  ROUTE_GROUP_EDIT,
  ROUTE_GROUPS,
  ROUTE_GROUP,
  ROUTE_TOPICS
} from '../../../routes/RouteList';
import Groups from './Groups';
import AddGroup from './AddGroup';
import Group from './Group';
import TopicsPage from './topics';

const GroupsPage = () => {
  const { uiState } = useContext(StoresContext);
  useEffect(() => {
    uiState.setPageTitle('');
  });
  return (
    <Switch>
      <Route path={ROUTE_TOPICS} component={() => <TopicsPage />} />
      <Route path={ROUTE_GROUP_ADD} component={() => <AddGroup />} />
      <Route
        path={ROUTE_GROUP_EDIT}
        component={props => (
          <Group edit={true} groupSlug={props.match.params.groupSlug} />
        )}
      />
      <Route
        path={ROUTE_GROUP}
        component={props => (
          <Group edit={false} groupSlug={props.match.params.groupSlug} />
        )}
      />
      <Route path={ROUTE_GROUPS} component={() => <Groups />} />
    </Switch>
  );
};
export default hooksObserver(GroupsPage);
