import React, { Component } from 'react';
import {
  Header as PageHeader,
  HeaderTitle,
  HeaderActions
} from '../../../lib/components/page-layout';
import { inject, observer } from 'mobx-react';
import { Button, Nav, NavItem } from '../../../lib/components';
import { defaultFormButtons } from '../../../lib/helpers/copy/default-copy';
import Logo from '../../../lib/components/logo/Logo';
import Link from '../../../lib/components/link/Link';
import {
  ROUTE_USERS,
  ROUTE_GROUPS,
  ROUTE_FILE_MANAGER,
  ROUTE_TAGS,
  ROUTE_CARDS,
  ROUTE_CATEGORIES
} from '../../../routes/RouteList';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

class Header extends Component {
  render() {
    const {
      rootStore: { authStore, routing, uiState }
    } = this.props;

    // Quick fix: read routing.location for mobx rerendering ;-)
    return (
      routing.location && (
        <PageHeader>
          <Logo imgFilename="logo.svg" linkTo="/" altText="logo of the page" />
          <HeaderTitle>{uiState.pageTitle}</HeaderTitle>
          {authStore.isLoggedIn && (
            <HeaderActions>
              <Nav>
                <NavItem to={ROUTE_CARDS}>
                  <FormattedMessage {...messages.headerNavItemCards} />
                </NavItem>
                <NavItem to={ROUTE_GROUPS}>
                  <FormattedMessage {...messages.headerNavItemGroups} />
                </NavItem>
                <NavItem to={ROUTE_USERS}>
                  <FormattedMessage {...messages.headerNavItemUsers} />
                </NavItem>
                <NavItem to={ROUTE_FILE_MANAGER}>
                  <FormattedMessage {...messages.headerNavItemFiles} />
                </NavItem>
                <NavItem to={ROUTE_TAGS}>
                  <FormattedMessage {...messages.headerNavItemTags} />
                </NavItem>
                <NavItem to={ROUTE_CATEGORIES}>
                  <FormattedMessage {...messages.headerNavItemCategories} />
                </NavItem>
                <Button ghost primary small onClick={() => authStore.logout()}>
                  {defaultFormButtons.LOGOUT}
                </Button>
              </Nav>
            </HeaderActions>
          )}
        </PageHeader>
      )
    );
  }
}

export default inject('rootStore')(observer(Header));
