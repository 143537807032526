import React, { useContext, useState, useEffect } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { FormattedMessage, injectIntl } from 'react-intl';
import { StoresContext } from '../../../stores';
import { array } from '@dc-framework/js-utils';
import { Link } from '../../../lib/components/link';
import {
  QUERY_GET_GROUPS_BY_ENTITY_ID,
  MUTATION_REMOVE_GROUP,
  updateGroupsOrder
} from '../../../services/graphql/group-query';
import { useQuery } from 'react-apollo-hooks';
import { Row } from '../../../lib/components/page-layout';
import {
  defaultCardLabels,
  defaultFormButtons
} from '../../../lib/helpers/copy/default-copy';
import { ButtonsGroup } from '../../../lib/components/button/ButtonsGroup';
import { Button } from '../../../lib/components/button/Button';
import {
  ROUTE_GROUP_ADD,
  ROUTE_TOPICS,
  ROUTE_GROUP_EDIT
} from '../../../routes/RouteList';
import { List, ListItem } from '../../../lib/components/list';
import {
  Card,
  CardHeader,
  CardActions,
  CardThumbnail,
  CardFooter,
  CardBody,
  CardContent
} from '../../../lib/components/card';

import ConfirmButton from '../../../lib/containers/modal/ConfirmButton';
import { getPath } from './path-generator';
import { DndWrapper } from '../../../lib/containers/dnd';
import Icon from '../../../lib/components/icon/Icon';
import Group from '../../../models/Group';
import messages from './messages';
import mainMessages from '../../../messages';
import { Tag } from '../../../lib/components/input-tag';
import Image from '../../../lib/components/image/Image';
import Loader from '../../../components/loader/Loader';

const { sort, SORT_DATA_TYPES } = array;

const getMetaDescription = meta => {
  let description = '';
  if (meta) {
    try {
      description = JSON.parse(meta).description;
    } catch (er) {}
  }
  return description;
};

const Groups = ({ intl }) => {
  const { authStore, routing, uiState } = useContext(StoresContext);

  const entityId = authStore.user.rootEntityId;
  const [groups, setGroups] = useState([]);

  const queryProps = {
    query: QUERY_GET_GROUPS_BY_ENTITY_ID,
    variables: { entityId }
  };

  const { data, error, loading } = useQuery(queryProps.query, {
    variables: queryProps.variables
  });

  useEffect(() => {
    let rawGroups = data && data.getEntity && data.getEntity.subEntities;
    if (rawGroups && rawGroups.length) {
      rawGroups = sort(rawGroups, {
        keys: [{ key: 'order', dataType: SORT_DATA_TYPES.NUMBER }]
      });
    } else {
      rawGroups = [];
    }
    setGroups(rawGroups.map(g => new Group(g)));
  }, [data]);

  if (loading) return <Loader />;
  if (error) return <>{`Error! ${error.message}`}</>;
  return (
    <>
      <div className="o-flex o-flex--align-center o-flex--justify-space-between">
        <h2 className="u-margin-none">
          <FormattedMessage {...messages.titleLabelGroups} />
        </h2>
        <ButtonsGroup>
          <Button primary onClick={() => routing.push(ROUTE_GROUP_ADD)}>
            <FormattedMessage {...messages.titleLabelAddGroup} />
          </Button>
        </ButtonsGroup>
      </div>

      <Row>
        {groups && (
          <List extraClassNames={'o-layout'} draggable>
            <DndWrapper
              type="group"
              items={groups}
              onDragUpdate={items => {
                items.forEach((item, i) => {
                  item.order = i;
                });
                setGroups(items);
              }}
              onDragEnd={async () => {
                await updateGroupsOrder(
                  {
                    sortItemInput: groups.map(i => ({
                      id: i.id,
                      order: i.order
                    }))
                  },
                  [queryProps]
                );
              }}
              dropComponent={() => (
                <Card horizontal empty>
                  <CardBody>
                    <FormattedMessage {...mainMessages.listDragDropHereLabel} />
                  </CardBody>
                </Card>
              )}
              placeholderOpacity={1}
            >
              {groups.map((g, i) => (
                <ListItem
                  key={g.id}
                  extraClassNames={
                    'o-layout__item u-1-of-2-at-small u-1-of-1-at-medium o-flex'
                  }
                >
                  <DndWrapper.Item id={g.id} index={i}>
                    <Card horizontal draggable>
                      <CardActions>
                        <ButtonsGroup>
                          <Link
                            buttonSmallActionPrimaryIcon
                            to={getPath(ROUTE_GROUP_EDIT, g.slug)}
                          >
                            <Icon id="edit" title={defaultFormButtons.EDIT} />
                          </Link>
                          <ConfirmButton
                            small
                            actionAlert
                            icon
                            mutation={MUTATION_REMOVE_GROUP}
                            refetchQueries={[queryProps]}
                            update={(cache, { data }) => {
                              if (data && data.archiveEntity) {
                                uiState.showNotification({
                                  message: intl.formatMessage(
                                    messages.notificationMessageGroupRemoved
                                  )
                                });
                              }
                            }}
                            variables={{ entityId: g.id }}
                            confirmTitle={intl.formatMessage(
                              messages.modalConfirmRemoveGroupTitle
                            )}
                            confirmMessage={intl.formatMessage(
                              messages.modalConfirmRemoveGroupMessage
                            )}
                          >
                            <Icon
                              id="remove"
                              title={defaultFormButtons.DELETE}
                            />
                          </ConfirmButton>
                        </ButtonsGroup>
                      </CardActions>

                      <CardHeader>
                        <Link
                          extraClassNames="c-link--images"
                          to={getPath(ROUTE_TOPICS, g.slug)}
                        >
                          {g.thumbnail ? (
                            <CardThumbnail
                              src={g.thumbnail}
                              alt="This is the main image"
                            />
                          ) : (
                            <Image placeholder />
                          )}
                        </Link>
                      </CardHeader>
                      <CardBody>
                        <CardContent>
                          {g.tags.length > 0 && (
                            <div className="c-card__tags u-margin-vertical-tiny">
                              {g.tags.map(tag => (
                                <Tag key={tag.id}>{tag.name}</Tag>
                              ))}
                            </div>
                          )}
                          <h3 className="c-card__title u-margin-top-small">
                            <Link to={getPath(ROUTE_TOPICS, g.slug)}>
                              {g.name}
                            </Link>
                          </h3>
                          {g.meta && (
                            <p className="c-card__description">
                              {getMetaDescription(g.meta)}
                            </p>
                          )}
                        </CardContent>
                        <CardFooter>
                          {g.topics.length > 0 && (
                            <ButtonsGroup>
                              <Link
                                breadcrumb
                                to={getPath(ROUTE_TOPICS, g.slug)}
                              >
                                <FormattedMessage
                                  {...messages.buttonGoToTopics}
                                />
                              </Link>
                            </ButtonsGroup>
                          )}
                        </CardFooter>
                      </CardBody>
                    </Card>
                    <DndWrapper.Grabzone>
                      <Icon id="drag" small secondary />
                    </DndWrapper.Grabzone>
                  </DndWrapper.Item>
                </ListItem>
              ))}
            </DndWrapper>
          </List>
        )}
      </Row>
    </>
  );
};
export default injectIntl(hooksObserver(Groups));
