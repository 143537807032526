import React, { useContext, useEffect, useState } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { useQuery } from 'react-apollo-hooks';
import { array, date } from '@dc-framework/js-utils';
import { FormattedMessage, injectIntl } from 'react-intl';
import { StoresContext } from '../../../stores';
import { defaultFormButtons } from '../../../lib/helpers/copy/default-copy';
import { Button } from '../../../lib/components/button/Button';
import {
  MUTATION_REMOVE_CARD,
  QUERY_GET_CARDS,
  MUTATION_EXPORT_CARDS,
  getExportUrl
} from '../../../services/graphql/card-query';
import {
  ROUTE_CARD_ADD,
  ROUTE_CARD_EDIT,
  ROUTE_TOPICS,
  ROUTE_SLUG_CARDS
} from '../../../routes/RouteList';
import { List, ListItem } from '../../../lib/components/list';
import { Link } from '../../../lib/components/link';
import { ButtonsGroup } from '../../../lib/components/button/ButtonsGroup';
import {
  Card,
  CardHeader,
  CardThumbnail,
  CardBody,
  CardContent,
  CardActions
} from '../../../lib/components/card';
import CardModel from '../../../models/Card';
import { getPath, getPathGroup } from './path-generator';
import messages from './messages';
import appMessages from '../../../messages';
import { Row, Wrapper } from '../../../lib/components/page-layout';
import Icon from '../../../lib/components/icon/Icon';
import ConfirmButton from '../../../lib/containers/modal/ConfirmButton';
import { Tag } from '../../../lib/components/input-tag';
import Image from '../../../lib/components/image/Image';
import { Input } from '../../../lib/components/input';
import { Notification } from '../../../lib/components/notification';
import Breadcrumb from '../../../lib/components/breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../lib/components/breadcrumb/BreadcrumbItem';
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionContent
} from '../../../lib/components/accordion';
import Loader from '../../../components/loader/Loader';
import Table from '../../../components/table/Table';
import TableHeader from '../../../components/table/TableHeader';
import TableRow from '../../../components/table/TableRow';
import TableHeaderCell from '../../../components/table/TableHeaderCell';
import TableBody from '../../../components/table/TableBody';
import TableCell from '../../../components/table/TableCell';
import { Mutation } from 'react-apollo';
import { EXPORT_URL } from '../../../constants';

const { getLocalDateFormat } = date;
const { sort, SORT_DATA_TYPES } = array;

const isFutureDate = date => {
  return new Date(date).getTime() > new Date().getTime();
};

const getSortedRows = (creationDateCards, sortDir) => {
  const rows = [];
  creationDateCards.forEach((card, i) => {
    if (card.cardEntities.length > 0) {
      card.cardEntities.forEach((ce, t) => {
        rows.push({
          id: t === 0 ? card.id : undefined,
          number: i,
          name: card.name,
          group: ce.breadcrumb[0],
          topic: ce.breadcrumb[1],
          tags: card.tags,
          date_created: card.date_created,
          date_published: card.date_published
            ? new Date(card.date_published).getTime()
            : 0,
          public: !card.private,
          views: ce.views !== null ? ce.views : 0,
          slug: card.slug
        });
      });
    } else {
      rows.push({
        id: card.id,
        number: i,
        name: card.name,
        group: '',
        topic: '',
        tags: card.tags,
        date_created: card.date_created,
        date_published: card.date_published
          ? new Date(card.date_published).getTime()
          : 0,
        public: !card.private,
        views: 0,
        slug: card.slug
      });
    }
  });

  if (
    sortDir.dir === 'ascending' &&
    (sortDir.column === 0 || sortDir.column === 5)
  ) {
    return rows;
  } else {
    const desc = sortDir.dir === 'descending' ? true : false;
    switch (sortDir.column) {
      case 0:
        return sort(rows, {
          keys: [
            { key: 'date_created', dataType: SORT_DATA_TYPES.NUMBER, desc }
          ]
        });
      case 1:
        return sort(rows, {
          keys: [{ key: 'name', desc }]
        });
      case 2:
        return sort(rows, {
          keys: [{ key: 'group', desc }]
        });
      case 3:
        return sort(rows, {
          keys: [{ key: 'topic', desc }]
        });
      case 5:
        return sort(rows, {
          keys: [
            { key: 'date_created', dataType: SORT_DATA_TYPES.NUMBER, desc }
          ]
        });
      case 6:
        return sort(rows, {
          keys: [
            {
              key: 'date_published',
              dataType: SORT_DATA_TYPES.NUMBER,
              desc
            }
          ]
        });
      case 7:
        return sort(rows, {
          keys: [
            {
              key: 'public',
              dataType: SORT_DATA_TYPES.BOOL,
              desc
            }
          ]
        });
      case 8:
        return sort(rows, {
          keys: [{ key: 'views', dataType: SORT_DATA_TYPES.NUMBER, desc }]
        });
      default:
        return rows;
    }
  }
};

const tableHeaders = [
  'Nr',
  'Card Name',
  'Groups',
  'Topics',
  'Tags',
  'Date Created',
  'Publish Date',
  'Public',
  'Views'
];

const Arrow = props => {
  let ascending = props.sortDir === 'ascending';
  return (
    <svg viewBox="0 0 100 200" width="100" height="200">
      {!(!ascending && props.isCurrent) && (
        <polyline points="20 50, 50 20, 80 50" />
      )}
      <line x1="50" y1="20" x2="50" y2="180" />
      {!(ascending && props.isCurrent) && (
        <polyline points="20 150, 50 180, 80 150" />
      )}
    </svg>
  );
};

const Cards = ({ intl }) => {
  const { routing, uiState, authStore } = useContext(StoresContext);
  useEffect(() => uiState.setPageTitle(''));

  const [cardResults, setCardResults] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [sortDir, setSortDir] = useState({ dir: 'ascending', column: 0 });
  const [cards, setCards] = useState([]);
  const queryProps = {
    query: QUERY_GET_CARDS
  };
  const { data, loading, error } = useQuery(queryProps.query, {
    variables: { entityId: authStore.entityId }
  });

  useEffect(() => {
    let rawCards = data && data.getCards;
    if (rawCards && rawCards.length) {
      rawCards = sort(rawCards, {
        keys: [{ key: 'title', dataType: SORT_DATA_TYPES.STRING }]
      });
    } else {
      rawCards = [];
    }
    setCards(rawCards.map(c => new CardModel(c)));
  }, [data]);

  useEffect(() => {
    let filteredCards = cards.filter(
      card =>
        (card.name &&
          card.name.toLowerCase().includes(searchString.toLowerCase())) ||
        (card.description &&
          card.description.toLowerCase().includes(searchString.toLowerCase()))
    );
    setCardResults(filteredCards);
  }, [cards, searchString]);

  if (loading) return <Loader />;
  if (error) return <>{`Error! ${error.message}`}</>;

  const creationDateCards = sort(cardResults, {
    keys: [{ key: 'date_created', dataType: SORT_DATA_TYPES.NUMBER }]
  });

  const cardRows = getSortedRows(creationDateCards, sortDir);
  return (
    <>
      <div className="o-flex o-flex--align-center o-flex--justify-space-between">
        <h2 className="u-margin-none">
          <FormattedMessage {...messages.titleLabelAllCards} />
        </h2>
        <ButtonsGroup>
          {uiState.isCardTable ? (
            <Button
              link
              iconLeft
              icon
              onClick={() => uiState.setCardTableView(false)}
            >
              <Icon svgLocalPath={'/assets/images/svg-symbols.svg#list-view'} />
              List View
            </Button>
          ) : (
            <Button
              link
              iconLeft
              onClick={() => uiState.setCardTableView(true)}
            >
              Table View
              <Icon
                svgLocalPath={'/assets/images/svg-symbols.svg#table-view'}
              />
            </Button>
          )}
          <Button
            disabled={data.loading}
            secondary
            onClick={async () => {
              uiState.increasePendingRequest();
              const filename = await getExportUrl();
              uiState.decreasePendingRequest();
              if (filename) {
                window.location.href = EXPORT_URL + filename;
              }
            }}
          >
            <FormattedMessage {...messages.titleLabelExportCards} />
          </Button>

          <Button primary onClick={() => routing.push(ROUTE_CARD_ADD)}>
            <FormattedMessage {...messages.titleLabelAddCard} />
          </Button>
        </ButtonsGroup>
      </div>
      {/* <Row> */}
      <Wrapper
        textWrapper
        extraClassNames="u-margin-top-medium u-margin-bottom-large"
      >
        <form role="search" onSubmit={e => e.preventDefault()}>
          <Input
            id="input-id-6"
            type="search"
            arial-label="search"
            placeholder="search the cards..."
            inputIconId="search"
            inputIconLeft
            extraClassNames="u-margin-bottom-none"
            value={searchString}
            onChange={e => setSearchString(e.target.value)}
          />
          {searchString.length > 0 && cardResults.length !== 0 && (
            <Notification success show>
              {intl.formatMessage(messages.messageCardsResultFoundFor, {
                count: cardResults.length,
                search: searchString
              })}
            </Notification>
          )}

          {cards.length !== 0 &&
            (!cardResults || cardResults.length === 0) &&
            searchString.length > 0 && (
              <Notification notFound show>
                <FormattedMessage
                  {...messages.messageNoCardsResultFoundFor}
                  values={{ search: searchString }}
                />
              </Notification>
            )}
        </form>
      </Wrapper>
      {cardResults && cardResults.length !== 0 && !uiState.isCardTable ? (
        <List extraClassNames={'o-layout o-layout--small'}>
          {cardResults.map((c, i) => (
            <ListItem
              id={c.id}
              key={c.id}
              extraClassNames={
                'o-layout__item u-1-of-2-at-small u-1-of-1-at-medium u-1-of-2-at-huge  o-flex'
              }
            >
              <Card
                horizontal
                published={c.date_published && !isFutureDate(c.date_published)}
                unpublished={
                  !c.date_published || isFutureDate(c.date_published)
                }
              >
                <CardActions>
                  <div className="c-card__metadata">
                    {c.date_published && (
                      <span className="c-card__date">
                        {isFutureDate(c.date_published)
                          ? intl.formatMessage(appMessages.cardLabelPublishOn, {
                              date: getLocalDateFormat(c.date_published)
                            })
                          : intl.formatMessage(
                              appMessages.cardLabelPublishedOn,
                              { date: getLocalDateFormat(c.date_published) }
                            )}
                      </span>
                    )}
                    {c.private ? (
                      <span className="c-card__badge">
                        {intl.formatMessage(appMessages.cardLabelPrivate)}
                      </span>
                    ) : (
                      <span className="c-card__badge c-card__badge-public">
                        {intl.formatMessage(appMessages.cardLabelPublic)}
                      </span>
                    )}
                  </div>
                  <ButtonsGroup>
                    <Link
                      buttonSmallActionPrimaryIcon
                      to={getPath(ROUTE_CARD_EDIT, c.slug)}
                    >
                      <Icon id="edit" title="Edit" />
                    </Link>
                    <ConfirmButton
                      small
                      actionAlert
                      icon
                      mutation={MUTATION_REMOVE_CARD}
                      refetchQueries={[queryProps]}
                      variables={{
                        cardId: c.id
                      }}
                      confirmTitle={intl.formatMessage(
                        messages.modalConfirmRemoveCardTitle
                      )}
                      confirmMessage={intl.formatMessage(
                        messages.modalConfirmRemoveCardMessage
                      )}
                      update={(cache, { data }) => {
                        if (data && data.archiveCard) {
                          uiState.showNotification({
                            message: intl.formatMessage(
                              messages.notificationMessageCardRemoved
                            )
                          });
                        }
                      }}
                    >
                      <Icon id="remove" title={defaultFormButtons.DELETE} />
                    </ConfirmButton>
                  </ButtonsGroup>
                </CardActions>
                <CardHeader>
                  <Link
                    extraClassNames="c-link--images"
                    to={getPath(ROUTE_CARD_EDIT, c.slug)}
                  >
                    {c.thumbnail ? (
                      <CardThumbnail
                        src={c.thumbnail}
                        alt="This is the main image"
                      />
                    ) : (
                      <Image placeholder />
                    )}
                  </Link>
                </CardHeader>
                <CardBody>
                  <CardContent>
                    {c.tags.length > 0 && (
                      <div className="c-card__tags u-margin-vertical-tiny">
                        {c.tags.map(tag => (
                          <Tag key={tag.id}>{tag.name}</Tag>
                        ))}
                      </div>
                    )}
                    <h3 className="c-card__title u-margin-top-small">
                      <Link to={getPath(ROUTE_CARD_EDIT, c.slug)}>
                        {c.title}
                      </Link>
                    </h3>
                    <p className="c-card__description">{c.description}</p>
                    {c.cardEntities.length > 0 && (
                      <Accordion>
                        <AccordionItem>
                          <div className="c-breadcrumbs__wrapper">
                            <AccordionHeader>
                              <div className="c-breadcrumbs__wrapper-title">
                                Included in
                              </div>
                            </AccordionHeader>
                            <AccordionContent>
                              {c.cardEntities &&
                                c.cardEntities.map(
                                  ce =>
                                    ce.breadcrumb &&
                                    ce.breadcrumb.length === 3 && (
                                      <Breadcrumb key={ce.id}>
                                        <BreadcrumbItem
                                          title={`Group: ${ce.breadcrumb[0]}`}
                                          to={getPathGroup(
                                            ROUTE_TOPICS,
                                            ce.breadcrumb[0]
                                          )}
                                        >
                                          {ce.breadcrumb[0]}
                                        </BreadcrumbItem>
                                        <BreadcrumbItem
                                          title={`Topic: ${ce.breadcrumb[1]}`}
                                          to={getPathGroup(
                                            ROUTE_SLUG_CARDS,
                                            ce.breadcrumb[0],
                                            ce.breadcrumb[1]
                                          )}
                                        >
                                          {ce.breadcrumb[1]}
                                        </BreadcrumbItem>
                                      </Breadcrumb>
                                    )
                                )}
                            </AccordionContent>
                          </div>
                        </AccordionItem>
                      </Accordion>
                    )}
                  </CardContent>
                </CardBody>
                <Notification views show>
                  <Icon id="eye-thin" primary />
                  <div className="c-notification__label">
                    <span className="c-notification__main-label">
                      {c.cardEntities.length > 0
                        ? c.cardEntities.reduce((accumulator, currentValue) => {
                            return accumulator + currentValue.views;
                          }, 0)
                        : 0}
                    </span>
                    <span>views</span>
                  </div>
                </Notification>
              </Card>
            </ListItem>
          ))}
        </List>
      ) : (
        <Table stripped caption="this is a table">
          <TableHeader>
            <TableRow>
              {tableHeaders.map((header, i) => (
                <TableHeaderCell
                  key={i}
                  isCurrent={sortDir.column === i}
                  sortDir={sortDir.dir}
                  unsortable={i === 4}
                  extraClassNames={
                    sortDir.column === i && 'u-bgcolor-secondary-xlight'
                  }
                >
                  {i !== 4 ? (
                    <Button
                      small
                      onClick={() => {
                        if (i !== 4) {
                          const newDir = { column: i, dir: 'ascending' };
                          if (sortDir.column === i) {
                            newDir.dir =
                              sortDir.dir === 'ascending'
                                ? 'descending'
                                : 'ascending';
                          }
                          setSortDir(newDir);
                        }
                      }}
                    >
                      {header}

                      <Arrow
                        sortDir={sortDir.dir}
                        isCurrent={sortDir.column === i}
                      />
                    </Button>
                  ) : (
                    header
                  )}
                </TableHeaderCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {cardRows.map((card, j) => (
              <TableRow key={j}>
                <TableCell>{`#${card.number}`}</TableCell>
                <TableCell id={card.id}>
                  <Link to={getPath(ROUTE_CARD_EDIT, card.slug)}>
                    {card.name}
                  </Link>
                </TableCell>
                <TableCell>{card.group}</TableCell>
                <TableCell>{card.topic}</TableCell>
                <TableCell>
                  {card.tags.length > 0
                    ? card.tags.map(t => <Tag key={t.id}>{t.name}</Tag>)
                    : ''}
                </TableCell>
                <TableCell>{getLocalDateFormat(card.date_created)}</TableCell>
                <TableCell>
                  {card.date_published
                    ? getLocalDateFormat(card.date_published)
                    : ''}
                </TableCell>
                <TableCell>
                  {card.public && (
                    <Icon
                      title="Public"
                      primary
                      svgLocalPath={'/assets/images/svg-symbols.svg#checkmark'}
                    />
                  )}
                </TableCell>
                <TableCell>{card.views.toString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {/* </Row> */}
    </>
  );
};

export default injectIntl(hooksObserver(Cards));
