import React, { useContext } from 'react';
import { StoresContext } from '../../../stores';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { useQuery } from 'react-apollo-hooks';
import {
  defaultCardLabels,
  defaultFormButtons
} from '../../../lib/helpers/copy/default-copy';
import {
  ROUTE_TAG_EDIT,
  ROUTE_TAG,
  ROUTE_TAGS
} from '../../../routes/RouteList';
import { PageContent } from '../../../lib/components/page-layout';
import { Button } from '../../../lib/components/button/Button';
import { getPath } from './path-generator';
import { Icon } from '../../../lib/components/icon';
import { ButtonsGroup } from '../../../lib/components/button/ButtonsGroup';
import { QUERY_GET_TAG_BY_SLUG } from '../../../services/graphql/tag-query';
import TagForm from './TagForm';
import Loader from '../../../components/loader/Loader';

const Tag = ({ tagSlug, edit }) => {
  const { routing } = useContext(StoresContext);

  const { data, error, loading } = useQuery(QUERY_GET_TAG_BY_SLUG, {
    variables: { slug: tagSlug }
  });

  if (loading)
    return (
      <PageContent>
        <Loader />
      </PageContent>
    );
  if (error) return <PageContent>{`Error! ${error.message}`}</PageContent>;

  const onNoEdit = () => routing.push(getPath(ROUTE_TAG));
  const onEdit = () => routing.push(getPath(ROUTE_TAG_EDIT, tagSlug));

  return (
    <>
      <div className="o-layout">
        <div className="o-layout__item u-1-of-5-at-medium">
          <h2 className="u-margin-bottom-medium">
            <FormattedMessage {...messages.titleLabelEditTag} />
          </h2>
          <ButtonsGroup extraClassNames="u-margin-bottom-large">
            <Button ghost iconLeft onClick={() => routing.push(ROUTE_TAGS)}>
              <Icon id="left" />
              {defaultFormButtons.BACK}
            </Button>
          </ButtonsGroup>
        </div>
        <div className="o-layout__item u-4-of-5-at-medium">
          <TagForm
            edit={edit}
            loading={loading}
            tag={data.getTagBySlug}
            onMutationComplete={onNoEdit}
            onCancel={onNoEdit}
            onEdit={onEdit}
          />
        </div>
      </div>
    </>
  );
};
export default Tag;
