import React, { useContext, useState } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { StoresContext } from '../../../stores';
import { FormattedMessage, injectIntl } from 'react-intl';

import { ROUTE_CARD_EDIT, ROUTE_CARDS } from '../../../routes/RouteList';
import CardForm from '../groups/topics/cards/CardForm';
import { getPath } from './path-generator';
import messages from './messages';
import { refetchCards } from '../../../services/graphql';

const AddCard = ({ intl }) => {
  const { routing, uiState, authStore } = useContext(StoresContext);

  const [loading, setLoading] = useState(false);

  const goBack = () => routing.push(getPath(ROUTE_CARDS));

  return (
    <>
      <div className="o-layout">
        <div className="o-layout__item u-1-of-5-at-medium">
          <h2 className="u-margin-bottom-medium">
            <FormattedMessage {...messages.titleLabelAddCard} />
          </h2>
        </div>
        <div className="o-layout__item u-4-of-5-at-medium">
          <CardForm
            loading={loading}
            onMutationComplete={async card => {
              setLoading(true);
              await refetchCards(authStore.entityId);
              setLoading(false);
              routing.push(getPath(ROUTE_CARD_EDIT, card.slug));
              uiState.showNotification({
                message: intl.formatMessage(
                  messages.notificationMessageCardSaved
                )
              });
            }}
            onCancel={goBack}
          />
        </div>
      </div>
    </>
  );
};
export default injectIntl(hooksObserver(AddCard));
