import gql from 'graphql-tag';
import { doMutation } from '../api';

export const QUERY_GET_TAGS = gql`
  query getTags {
    getTags {
      id
      name
      slug
      meta
    }
  }
`;

export const QUERY_GET_TAG_BY_SLUG = gql`
  query getTagBySlug($slug: String!) {
    getTagBySlug(slug: $slug) {
      id
      name
      slug
      meta
    }
  }
`;

export const MUTATION_ADD_TAG = gql`
  mutation createTag($name: String!, $meta: AWSJSON) {
    createTag(input: { name: $name, meta: $meta }) {
      id
      slug
      name
    }
  }
`;

export const addTag = async variables => {
  return new Promise(async (resolve, reject) => {
    const mutation = MUTATION_ADD_TAG;
    return await doMutation({
      mutation,
      variables,
      update: (cache, { data, data: { createTag } }) => {
        if (createTag && createTag.id) {
          resolve(createTag);
        }
      }
    });
  });
};

export const MUTATION_UPDATE_TAG = gql`
  mutation editTag($id: ID!, $name: String, $meta: AWSJSON) {
    editTag(input: { id: $id, name: $name, meta: $meta }) {
      id
      name
      slug
    }
  }
`;

export const updateTag = async variables => {
  return new Promise(async (resolve, reject) => {
    const mutation = MUTATION_UPDATE_TAG;
    return await doMutation({
      mutation,
      variables,
      update: (cache, { data, data: { editTag } }) => {
        if (editTag && editTag.id) {
          resolve(editTag.id);
        }
      }
    });
  });
};

export const handleTags = async ({
  currentTags,
  previousTags,
  entityId,
  cardId,
  refetchQueries
}) => {
  const hasRefetchQueries = refetchQueries && refetchQueries.length;
  let newSavedTags = [];
  if (currentTags.length) {
    const newTags = currentTags.filter(t => t.__isNew__);
    newSavedTags = await addTags(newTags);
  }

  const currentSavedTags = [
    ...currentTags.filter(t => !t.__isNew__),
    ...newSavedTags
  ];

  const tagsToLink = currentSavedTags.filter(
    st => !previousTags.find(pt => pt.id === st.id)
  );

  const tagsToRemove = previousTags.filter(
    st => !currentSavedTags.find(pt => pt.id === st.id)
  );

  await Promise.all(
    tagsToLink.map(async (t, i) => {
      const hasToRefetch =
        i === tagsToLink.length - 1 &&
        tagsToRemove.length === 0 &&
        hasRefetchQueries;
      return await addTagEntity({
        variables: {
          tagId: t.id,
          entityId,
          cardId
        },
        refetchQueries: hasToRefetch ? refetchQueries : undefined
      });
    })
  );

  await Promise.all(
    tagsToRemove.map(async (t, i) => {
      const hasToRefetch = i === tagsToRemove.length - 1 && hasRefetchQueries;
      return await removeTagEntity({
        variables: {
          tagEntityId: t.tagEntity.id
        },
        refetchQueries: hasToRefetch ? refetchQueries : undefined
      });
    })
  );
};

export const addTags = async newTags => {
  return await Promise.all(
    newTags.map(async t => {
      return await addTag({
        id: t.value,
        name: t.label
      });
    })
  );
};

export const MUTATION_REMOVE_TAG = gql`
  mutation archiveTag($tagId: ID!) {
    archiveTag(tagId: $tagId)
  }
`;

export const MUTATION_ADD_TAG_ENTITY = gql`
  mutation createTagEntity($tagId: ID!, $entityId: ID, $cardId: ID) {
    createTagEntity(
      input: { tagId: $tagId, entityId: $entityId, cardId: $cardId }
    ) {
      id
    }
  }
`;

export const addTagEntity = async ({ refetchQueries, variables }) => {
  return new Promise(async (resolve, reject) => {
    const mutation = MUTATION_ADD_TAG_ENTITY;
    return await doMutation({
      mutation,
      variables,
      update: (cache, { data, data: { createTagEntity } }) => {
        if (createTagEntity && createTagEntity.id) {
          resolve(createTagEntity.id);
        }
      },
      refetchQueries
    });
  });
};

export const MUTATION_REMOVE_TAG_ENTITY = gql`
  mutation archiveTagEntity($tagEntityId: ID!) {
    archiveTagEntity(tagEntityId: $tagEntityId)
  }
`;

export const removeTagEntity = async ({ refetchQueries, variables }) => {
  return new Promise(async (resolve, reject) => {
    const mutation = MUTATION_REMOVE_TAG_ENTITY;
    return await doMutation({
      mutation,
      variables,
      update: (cache, { data, data: { archiveTagEntity } }) => {
        if (archiveTagEntity === true) {
          resolve(archiveTagEntity);
        }
      },
      refetchQueries
    });
  });
};
