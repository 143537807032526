import { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo-hooks';
import { QUERY_GET_CATEGORIES } from '../graphql/categories-query';
import { cahCategories } from '../../coachathome_data';
import { arrayToTree } from 'performant-array-to-tree';

export const useCategoryTree = (entityId) => {
  const [categories, setCategories] = useState([]);
  const queryProps = {
    query: QUERY_GET_CATEGORIES,
    variables: { entityId },
  };
  const { data, error, loading, refetch } = useQuery(queryProps.query, {
    variables: queryProps.variables,
  });

  useEffect(() => {
    if (data?.getCategories) {
      const tree = arrayToTree(data.getCategories, {
        rootParentIds: { '00001': true },
        dataField: null,
      });
      setCategories(tree);
    }
  }, [data, refetch]);

  return {
    queryProps,
    error,
    loading,
    categories,
    refetch,
  };
};
