import React, { useContext, useState } from 'react';
import { ContentBlock } from '../../../lib/components/content-block/ContentBlock';
import Input from '../../../lib/components/input/Input';
import { Button, ButtonsGroup, Select } from '../../../lib/components';
import { defaultFormButtons } from '../../../lib/helpers';
import Option from '../../../lib/components/input-select/Option';
import { useCategoryTree } from '../../../services/hooks/useCategoryTree';
import { StoresContext } from '../../../stores';
import { Formik } from 'formik';
import * as Yup from 'yup';

import {
  addCategory,
  MUTATION_ADD_CATEGORY,
  QUERY_GET_CATEGORIES,
} from '../../../services/graphql/categories-query';
import { injectIntl } from 'react-intl';
import messages from '../users/messages';

function CategoryForm({ onCancel, intl }) {
  const { authStore, routing, uiState } = useContext(StoresContext);
  const entityId = authStore.user.rootEntityId;
  const { categories, loading, error } = useCategoryTree(
    authStore.user.rootEntityId
  );
  const [selectCat, setSelectCat] = useState(null);

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, 'Category name should be 3 or more characters long')
      .required('Category name is required'),
  });

  return (
    <div className="o-layout">
      <ContentBlock
        extraClassNames={
          'o-layout__item u-2-of-3-at-medium u-margin-horizontal-auto u-padding-horizontal-medium'
        }
      >
        <Formik
          initialValues={{
            parentId1: '',
            parentId2: '',
            name: '',
            order: 0,
            status: 'online',
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            await addCategory({
              refetchQueries: [
                { query: QUERY_GET_CATEGORIES, variables: { entityId } },
              ],
              variables: {
                ...values,
                parentId:
                  values.parentId2 === '' ? values.parentId1 : values.parentId2,
              },
            });
            uiState.showNotification({
              message: intl.formatMessage(
                messages.notificationMessageCategorySaved
              ),
            });
            setSelectCat(null);
            setSubmitting(false);
            resetForm();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Input
                id="name"
                name="name"
                type="text"
                // placeholder="Write a name"
                label="Category Name"
                onChange={handleChange}
                value={values.name}
                extraClassNames={'c-input--inline'}
                hasError={touched.name && errors.name}
                errorMessage={errors.name}
              />
              <Select
                id={4}
                name={'parentId1'}
                label={'Parent'}
                value={values.parentId1}
                extraClassNames={'c-input--inline'}
                // hasError
                // errorMessage={'if there is an error, this is the msg'}
                readOnly={false}
                disabled={false}
                onChange={(e) => {
                  const selected = categories.find(
                    (el) => el.id === e.target.value
                  );
                  setSelectCat(selected);
                  setFieldValue('parentId1', e.target.value);
                  setFieldValue('parentId2', '');
                }}
              >
                <Option value={''}>-- Choose one --</Option>

                {categories.map((gt) => (
                  <Option key={gt.id} value={gt.id}>
                    {gt.name}
                  </Option>
                ))}
              </Select>

              {/* If Subcategory */}
              {selectCat && selectCat.children && (
                <Select
                  id={4}
                  name={'parentId2'}
                  label={'Parent'}
                  value={values.parentId2}
                  extraClassNames={'c-input--inline'}
                  // hasError
                  // errorMessage={'if there is an error, this is the msg'}
                  readOnly={false}
                  disabled={false}
                  onChange={(e) => {
                    setFieldValue('parentId2', e.target.value);
                  }}
                >
                  <Option value={''}>-- Choose one --</Option>
                  {selectCat.children.map((gt) => (
                    <Option key={gt.id} value={gt.id}>
                      {gt.name}
                    </Option>
                  ))}
                </Select>
              )}
              <ButtonsGroup extraClassNames="o-flex--justify-end u-margin-vertical-medium">
                <Button secondary onClick={onCancel}>
                  {defaultFormButtons.CANCEL}
                </Button>
                <Button type="submit" primary>
                  {defaultFormButtons.SAVE}
                </Button>
              </ButtonsGroup>
            </form>
          )}
        </Formik>
      </ContentBlock>
    </div>
  );
}

export default injectIntl(CategoryForm);
