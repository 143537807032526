import React, { useContext } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useTags } from '../../../services/hooks/useTags';
import { StoresContext } from '../../../stores/contexts';
import { ROUTE_TAG_ADD, ROUTE_TAG_EDIT } from '../../../routes/RouteList';
import messages from './messages';
import { Button, Row, ButtonsGroup, Icon } from '../../../lib/components';
import { List, ListItem } from '../../../lib/components/list';
import { defaultFormButtons } from '../../../lib/helpers/copy';
import { MUTATION_REMOVE_TAG } from '../../../services/graphql/tag-query';
import { ConfirmButton } from '../../../lib/containers/modal';

export const Tags = injectIntl(({ intl }) => {
  const { routing, uiState } = useContext(StoresContext);
  const { dbTags, queryProps } = useTags();
  return (
    <>
      <div className="o-flex o-flex--align-center o-flex--justify-space-between">
        <h2 className="u-margin-none">
          <FormattedMessage {...messages.titleLabelTags} />
        </h2>
        <Button primary onClick={() => routing.push(ROUTE_TAG_ADD)}>
          <FormattedMessage {...messages.titleLabelAddTag} />
        </Button>
      </div>
      <Row>
        {dbTags && (
          <List stripped extraClassNames="o-layout c-list--stripped-2-col">
            {dbTags.map(u => (
              <ListItem
                key={u.id}
                extraClassNames={'o-layout__item u-1-of-2-at-medium '}
              >
                <span className="u-2-of-3">{u.label}</span>
                <ButtonsGroup right extraClassNames="o-layout__item u-1-of-3">
                  <Button
                    small
                    actionPrimary
                    icon
                    onClick={() =>
                      routing.push(ROUTE_TAG_EDIT.replace(':tagSlug', u.slug))
                    }
                  >
                    <Icon id="edit" title={defaultFormButtons.EDIT} />
                  </Button>
                  <ConfirmButton
                    small
                    actionAlert
                    icon
                    mutation={MUTATION_REMOVE_TAG}
                    refetchQueries={[queryProps]}
                    variables={{ tagId: u.id }}
                    confirmTitle={intl.formatMessage(
                      messages.modalConfirmRemoveTagTitle
                    )}
                    confirmMessage={intl.formatMessage(
                      messages.modalConfirmRemoveTagMessage
                    )}
                    update={(cache, { data }) => {
                      if (data && data.archiveTag) {
                        uiState.showNotification({
                          message: intl.formatMessage(
                            messages.notificationMessageTagRemoved
                          )
                        });
                      }
                    }}
                  >
                    <Icon id="remove" title={defaultFormButtons.DELETE} />
                  </ConfirmButton>
                </ButtonsGroup>
              </ListItem>
            ))}
          </List>
        )}
      </Row>
    </>
  );
});
