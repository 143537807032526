import React, { useState } from 'react';
import { inject } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { form, file } from '@dc-framework/js-utils';
import { HashLink } from 'react-router-hash-link';
import {
  MutationForm,
  Form,
  FORM_ITEM_TYPES,
  INPUT_TYPES,
} from '../../../../../lib/containers/form';
import { Button, ButtonsGroup } from '../../../../../lib/components/button';
import {
  defaultFormMessages,
  defaultFormButtons,
} from '../../../../../lib/helpers/copy/default-copy';

import {
  MUTATION_UPDATE_CARD,
  MUTATION_ADD_CARD,
  addCardItem,
  updateCardItem,
  handleCardEntities,
  QUERY_GET_CARDS,
} from '../../../../../services/graphql';
import { ArticleEditor } from '../../../../partials/article/ArticleEditor';
import { useTags } from '../../../../../services/hooks/useTags';
import { handleTags } from '../../../../../services/graphql/tag-query';
import { getInputTagFormat } from '../../../../../utils/tags';
import File, { FILE_TYPES } from '../../../../../models/File';
import { uploader } from '../../../../../services/uploader';
import appMessages from '../../../../../messages';
import messages from './messages';
import {
  CardItemType,
  CardItemFileType,
  MAX_DESCRIPTION_LENGTH,
  CLIENT_URL,
} from '../../../../../constants';
import CardTree from '../../../../partials/card-tree/CardTree';
import { Tabs, TabList, Tab, TabPanel } from '../../../../../lib/components';
import { ROUTE_CARDS } from '../../../../../routes/RouteList';
import CategoryTree from '../../../../partials/category-tree/CategoryTree';
import { handleCardCategories } from '../../../../../services/graphql/categories-query';

const { MIME_TYPES_PRESETS, getMimeTypesByPreset } = file;

const {
  VALIDATE_TYPES: { ERROR_UNKNOWN },
  isMaxLengthValid,
} = form;

let tagsToSave = null;

const CardForm = ({
  rootStore,
  intl,
  edit = true,
  onEdit,
  card,
  loading,
  refetchQueries,
  onCancel,
  topicSlug,
  onMutationComplete,
}) => {
  const formRef = React.createRef();
  const { uiState, authStore } = rootStore;
  const { entityId } = authStore;
  const { dbTags, refetch } = useTags();
  const tags = card ? getInputTagFormat(card.tags) : [];

  const [thumb, setThumb] = useState(
    (card && card.thumb && [new File(card.thumb)]) || []
  );

  const [cardLoading, setCardLoading] = useState(false);
  const MUTATION = card ? MUTATION_UPDATE_CARD : MUTATION_ADD_CARD;

  const refetcher = [...(refetchQueries || [])];

  if (card) {
    refetcher.push({
      query: QUERY_GET_CARDS,
      variables: { entityId: entityId },
    });
  }

  const getFilesFormat = (cardItem) => {
    return cardItem.type === CardItemType.FILE ||
      cardItem.type === CardItemType.IMAGE
      ? cardItem.files.map((cif) => {
          if (cif.type === CardItemFileType.LINK) {
            return { ...cif, id: undefined };
          } else {
            return {
              fileId: cif.file.id,
              type: cif.file.type,
            };
          }
        })
      : cardItem.files.map((f) => ({ ...f, id: undefined }));
  };

  const saveCardItems = async (contentBlocks) => {
    const editedOrNew = contentBlocks.filter(
      (cb) => cb.__isNew__ || cb.__isUpdated__
    );
    await Promise.all(
      editedOrNew.map(async (c) => {
        if (c.__isNew__) {
          return await addCardItem({
            ...c,
            files: getFilesFormat(c),
            cardId: card.id,
          });
        } else {
          return await updateCardItem({
            ...c,
            files: getFilesFormat(c),
            cardId: card.id,
          });
        }
      })
    );
  };

  const tagsHandler = async (cardId, refetchQueries) => {
    await handleTags({
      currentTags: tagsToSave,
      previousTags: tags,
      cardId,
      refetchQueries,
    });
    refetch();
  };

  const onNavigateToPreview = (card) => {
    const path = card.cardEntities[0].breadcrumb.join('/');
    window.open(`${CLIENT_URL}groups/${path}`);
  };

  return (
    <>
      <MutationForm
        edit={edit}
        innerRef={formRef}
        loading={loading || cardLoading}
        mutation={MUTATION}
        onCancel={onCancel}
        onEdit={onEdit}
        getMutationVariables={async (data) => {
          setCardLoading(true);

          // tags update
          tagsToSave = data.tags;
          data.tags = undefined;
          if (card) {
            await tagsHandler(card.id);
          }

          // publish date
          if (!data.date_published) {
            data.date_published = null;
          } else {
            data.date_published = data.date_published + 'T00:00:00.000Z';
          }

          // card items
          if (data.article) {
            await saveCardItems(data.article);
          }

          // card entities
          if (data.cardEntities) {
            await handleCardEntities({
              previousEntities: card.cardEntities,
              currentEntities: data.cardEntities,
              cardId: card.id,
              entityId,
            });
          }

          // card categories
          if (data.categories) {
            await handleCardCategories({
              previousCategories: card.categories.map((c) => c.id),
              currentCategories: data.categories,
              cardId: card.id,
              entityId,
            });
          }

          // thumb
          data.thumbFileId = data.thumb.length ? data.thumb[0].id : undefined;
          setCardLoading(false);
          return data;
        }}
        errorMessage={defaultFormMessages[ERROR_UNKNOWN]}
        mutationDataKey={card ? 'editCard' : 'createCard'}
        extraMutationVariables={{
          id: card ? card.id : undefined,
        }}
        refetchQueries={card ? () => refetcher : undefined}
        onMutationComplete={async (data) => {
          if (!card) {
            setCardLoading(true);
            await tagsHandler(data.id, refetcher);
            setCardLoading(false);
          }
          onMutationComplete && onMutationComplete(data);
        }}
        enableSubmitOnChange={card ? true : false}
      >
        <div className="c-content-block__header-article">
          <ButtonsGroup extraClassNames="o-flex--justify-end u-margin-vertical-medium">
            {card && card.cardEntities && card.cardEntities.length !== 0 && (
              <Button onClick={() => onNavigateToPreview(card)} type="button">
                Preview
              </Button>
            )}
            <Form.EditButton />
            <Form.SubmitButton primary />
            {!card || topicSlug ? (
              <Form.CancelButton secondary />
            ) : (
              <HashLink
                className="c-button c-button--secondary"
                to={`${ROUTE_CARDS}#${card.id}`}
              >
                {defaultFormButtons.CANCEL}
              </HashLink>
            )}
          </ButtonsGroup>
          <Form.ItemWrapper
            formItems={[
              {
                key: 'thumb',
                type: FORM_ITEM_TYPES.FILE_UPLOADER,
                label: intl.formatMessage(appMessages.formLabelThumbnail),
                value: thumb,
                uploader,
                multiple: false,
                accept: getMimeTypesByPreset(MIME_TYPES_PRESETS.IMAGE),
                canDelete: false,
                isGallery: true,
                fileUploadIcon: 'image',
                controlledValue: true,
                placeholder: intl.formatMessage(
                  messages.cardUploadThumbPlaceholder
                ),
                labelFileManager: intl.formatMessage(
                  messages.cardSelectExistingImage
                ),
                onBrowseFileManager: () => {
                  uiState.showSelectFileModal({
                    type: FILE_TYPES.IMAGE,
                    ignoreFiles: thumb,
                    onSelect: (files) => {
                      setThumb(files);
                      formRef &&
                        formRef.current &&
                        formRef.current.forceTriggerChanged();
                    },
                    multiple: false,
                  });
                },
              },
              {
                key: 'private',
                label: intl.formatMessage(appMessages.formLabelStatus),
                value: card ? card.private : true,
                type: FORM_ITEM_TYPES.CHECKBOX,
              },
              {
                key: 'title',
                label: intl.formatMessage(appMessages.formLabelName),
                value: card ? card.title : '',
                required: true,
              },
              {
                key: 'description',
                label: intl.formatMessage(appMessages.formLabelDescription, {
                  maxLength: MAX_DESCRIPTION_LENGTH,
                }),
                value: card ? card.description : '',
                type: FORM_ITEM_TYPES.TEXTAREA,
                maxLength: MAX_DESCRIPTION_LENGTH,
                validator: ({ value }) =>
                  isMaxLengthValid({
                    value,
                    maxLength: MAX_DESCRIPTION_LENGTH,
                  }),
              },
              {
                key: 'date_published',
                type: FORM_ITEM_TYPES.TEXTFIELD,
                label: intl.formatMessage(appMessages.formLabelPublishDate),
                inputType: INPUT_TYPES.DATE,
                value: card
                  ? card.date_published
                    ? card.date_published.replace('T00:00:00.000Z', '')
                    : ''
                  : '',
              },
              {
                key: 'tags',
                label: intl.formatMessage(appMessages.formLabelTags),
                value: tags,
                type: FORM_ITEM_TYPES.TAG_INPUT,
                options: dbTags,
              },
              {
                key: 'aliases',
                label: intl.formatMessage(appMessages.formLabelAliases, {
                  maxLength: MAX_DESCRIPTION_LENGTH,
                }),
                value: card ? card.aliases : '',
                type: FORM_ITEM_TYPES.TEXTAREA,
                maxLength: MAX_DESCRIPTION_LENGTH,
                validator: ({ value }) =>
                  isMaxLengthValid({
                    value,
                    maxLength: MAX_DESCRIPTION_LENGTH,
                  }),
              },
            ]}
          />
        </div>
        {card && (
          <Tabs>
            <TabList>
              <Tab>Card items</Tab>
              <Tab>Topics</Tab>
              <Tab>Category</Tab>
            </TabList>
            <TabPanel>
              <Form.Item
                formItem={{
                  key: 'article',
                  type: FORM_ITEM_TYPES.CUSTOM,
                  value: card.cardItems,
                  controlledValue: true,
                  keep: true,
                }}
              >
                <ArticleEditor />
              </Form.Item>
            </TabPanel>
            <TabPanel>
              <Form.Item
                formItem={{
                  key: 'cardEntities',
                  type: FORM_ITEM_TYPES.CUSTOM,
                  value: card.cardEntities,
                  controlledValue: true,
                  keep: true,
                }}
              >
                <CardTree ignoreTopicSlug={topicSlug} />
              </Form.Item>
            </TabPanel>
            <TabPanel>
              <Form.Item
                formItem={{
                  key: 'categories',
                  type: FORM_ITEM_TYPES.CUSTOM,
                  value: card.categories.map((c) => c.id),
                  controlledValue: true,
                  keep: true,
                }}
              >
                <CategoryTree />
              </Form.Item>
            </TabPanel>
          </Tabs>
        )}

        <ButtonsGroup extraClassNames="o-flex--justify-end u-margin-vertical-medium">
          <Form.EditButton />
          <Form.SubmitButton primary />
          {!card || topicSlug ? (
            <Form.CancelButton secondary />
          ) : (
            <HashLink
              className="c-button c-button--secondary"
              to={`${ROUTE_CARDS}#${card.id}`}
            >
              {defaultFormButtons.CANCEL}
            </HashLink>
          )}
        </ButtonsGroup>
      </MutationForm>
    </>
  );
};
export default injectIntl(inject('rootStore')(CardForm));
