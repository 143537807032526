import AWSAppSyncClient from 'aws-appsync';
import { Auth } from 'aws-amplify';
import { getGQLQueryResult } from './utils/query';
import { getGQLMutationResult } from './utils/mutate';

const defaultAppSyncConfig = {
  region: process.env.REACT_APP_AWS_REGION,
  graphqlEndpoint: process.env.REACT_APP_AWS_GRAPHQL_END_POINT,
  authenticationType: process.env.REACT_APP_AWS_AUTHENTICATION_TYPE,
  apiKey: 'null'
};

// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export let appSyncClient = null;

export const setAWSAppSyncClient = async appSyncConfig => {
  const config = { ...defaultAppSyncConfig, ...appSyncConfig };
  if (!config.graphqlEndpoint) return null;
  appSyncClient = new AWSAppSyncClient({
    url: config.graphqlEndpoint,
    region: config.region,
    disableOffline: true,
    // connectToDevTools: true,
    auth: {
      type: config.authenticationType,
      credentials: () => Auth.currentCredentials(),
      jwtToken: async () =>
        (await Auth.currentSession()).getAccessToken().getJwtToken()
    }
  });

  //await sleep(1000);
  return appSyncClient;
};

export const gqlQuery = ({ client = appSyncClient, ...data }) =>
  getGQLQueryResult(client, { ...data });

export const gqlMutation = ({ client = appSyncClient, ...data }) =>
  getGQLMutationResult(client, { ...data });
