import axios from 'axios';

export const restLogin = () => {};

export const restSetNewPassword = () => {};

export const restLogout = () => {};

export const restSignup = () => {};

export const restForgotPassword = async (email) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_REST_END_POINT}user/reset/${email}`,
      {
        headers: {
          'x-api-key': process.env.REACT_APP_REST_API_KEY,
        },
      }
    );
  } catch (error) {
    console.error(error);
  }
};
