import { defineMessages } from 'react-intl';

export default defineMessages({
  headerNavItemUsers: {
    id: 'header.nav.item.users',
    defaultMessage: 'Users'
  },
  headerNavItemCards: {
    id: 'header.nav.item.cards',
    defaultMessage: 'Cards'
  },
  headerNavItemGroups: {
    id: 'header.nav.item.groups',
    defaultMessage: 'Groups'
  },
  headerNavItemFiles: {
    id: 'header.nav.item.files',
    defaultMessage: 'Files'
  },
  headerNavItemTags: {
    id: 'header.nav.item.tags',
    defaultMessage: 'Tags'
  },
  headerNavItemCategories: {
    id: 'header.nav.item.categories',
    defaultMessage: 'Categories'
  }
});
