import React, { useContext, useState, useEffect } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { StoresContext } from '../../../../../stores';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Mutation } from 'react-apollo';

import {
  ROUTE_SLUG_CARD_EDIT,
  ROUTE_SLUG_CARDS
} from '../../../../../routes/RouteList';
import CardForm from './CardForm';
import { getPath } from '../../path-generator';
import messages from './messages';
import {
  QUERY_GET_CARDS_BY_TOPIC_ID,
  MUTATION_ADD_CARD_TO_ENTITY
} from '../../../../../services/graphql';
import { useTopicName } from './useTopicName';

let cardSlug = '';
const AddCard = ({ intl, groupSlug, topicSlug }) => {
  const { routing, uiState, authStore } = useContext(StoresContext);
  const [loading, setLoading] = useState(false);
  const { entityId } = authStore;

  const { name } = useTopicName(topicSlug);
  useEffect(() => uiState.setPageTitle(name));

  const goBack = () =>
    routing.push(getPath(ROUTE_SLUG_CARDS, groupSlug, topicSlug));

  return (
    <>
      <div className="o-layout">
        <div className="o-layout__item u-1-of-5-at-medium">
          <h2 className="u-margin-bottom-medium">
            <FormattedMessage {...messages.titleLabelAddCard} />
          </h2>
        </div>
        <div className="o-layout__item u-4-of-5-at-medium">
          <Mutation
            mutation={MUTATION_ADD_CARD_TO_ENTITY}
            refetchQueries={[
              {
                query: QUERY_GET_CARDS_BY_TOPIC_ID,
                variables: { entitySlug: groupSlug, subEntitySlug: topicSlug }
              }
            ]}
            update={async (cache, { data }) => {
              if (data && data.createCardEntity) {
                setLoading(false);
                routing.push(
                  getPath(ROUTE_SLUG_CARD_EDIT, groupSlug, topicSlug, cardSlug)
                );
                uiState.showNotification({
                  message: intl.formatMessage(
                    messages.notificationMessageCardSaved
                  )
                });
              }
            }}
          >
            {(mutation, data) => {
              return (
                <CardForm
                  loading={data.loading || loading}
                  topicSlug={topicSlug}
                  groupSlug={groupSlug}
                  onMutationComplete={card => {
                    cardSlug = card.slug;
                    setLoading(true);
                    mutation({
                      variables: {
                        cardId: card.id,
                        entitySlug: topicSlug,
                        topLevelEntityId: entityId
                      }
                    });
                  }}
                  onCancel={goBack}
                />
              );
            }}
          </Mutation>
        </div>
      </div>
    </>
  );
};
export default injectIntl(hooksObserver(AddCard));
