import React, { Component } from 'react';
import classNames from 'classnames';
import Dropzone from './Dropzone';

import { Notification } from '../notification';
import './FileUpload.scss';
import FileUploadList from './file-upload-items/FileUploadList';
import FileUploadGallery from './file-upload-items/FileUploadGallery';

export default class FileUpload extends Component {
  render() {
    const {
      id,
      label,
      children,
      disabled,
      selectedFiles,
      files,
      isSelectable,
      orderLabel = 0,
      orderListFiles = 1,
      orderDropzone = 2,
      onDeleteFile,
      onUpdateFile,
      onFileSelect,
      isGallery,
      errorMessage,
      hasError,
      accept,
      fileUploadIcon,
      extraClassNames,
      ...props
    } = this.props;

    return (
      <div
        className={classNames('c-file-upload', extraClassNames, {
          // 'c-input--error': hasError,
          'c-file-upload--disabled': disabled
        })}
      >
        {id && (
          <label
            htmlFor={id}
            className={'c-input__label u-margin-bottom-medium'}
            style={{ order: orderLabel }}
          >
            {label}
          </label>
        )}

        {/* Are there children */}
        {children && (
          <div
            className={'c-file-upload__children-wrapper'}
            style={{ order: orderListFiles }}
          >
            {children}
          </div>
        )}

        {/* No Children && is Gallery */}
        {!children && isGallery && (
          <FileUploadGallery
            isGallery
            files={files}
            id={id}
            accept={accept}
            fileUploadIcon={fileUploadIcon}
            order={orderListFiles}
            disabled={disabled}
            isSelectable={isSelectable}
            selectedFiles={selectedFiles}
            onFileSelect={onFileSelect}
            onDeleteFile={onDeleteFile}
            {...props}
          />
        )}

        {/* No Children && file type*/}
        {!children && !isGallery && (
          <FileUploadList
            onUpdateFile={onUpdateFile}
            files={files}
            order={orderListFiles}
            disabled={disabled}
            isSelectable={isSelectable}
            selectedFiles={selectedFiles}
            onFileSelect={onFileSelect}
            onDeleteFile={onDeleteFile}
            {...props}
          />
        )}

        {/* If Not Gallery and Not Selectable > Add the dropzone */}
        {!isGallery && !isSelectable && (
          <Dropzone
            id={id}
            order={orderDropzone}
            disabled={disabled}
            accept={accept}
            fileUploadIcon={fileUploadIcon}
            {...props}
          />
        )}

        {errorMessage && (
          <Notification errorMessage>{errorMessage}</Notification>
        )}
      </div>
    );
  }
}
