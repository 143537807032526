import React, { useEffect, useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { StoresContext } from '../../../stores';
import {
  ROUTE_CATEGORY_ADD,
  ROUTE_CATEGORY_EDIT,
  ROUTE_CATEGORIES,
} from '../../../routes/RouteList';
// import AddCategory from './AddCategory';
import { Categories } from './Categories';
// import Category from './Category';

const CategoriesPage = () => {
  const { uiState } = useContext(StoresContext);
  useEffect(() => {
    uiState.setPageTitle('');
  });
  return (
    <Switch>
      <Route path={ROUTE_CATEGORIES} component={() => <Categories />} />
    </Switch>
  );
};
export default CategoriesPage;
