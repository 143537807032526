import { file } from '@dc-framework/js-utils';
const {
  IMAGE_EXTENSIONS,
  VIDEO_EXTENSIONS,
  AUDIO_EXTENSIONS,
  SPREADSHEET_EXTENSIONS,
  PDF_EXTENSIONS,
  ZIP_EXTENSIONS
} = file;

export const FILE_TYPES = Object.freeze({
  IMAGE: 'image',
  VIDEO: 'video',
  FILE: 'file'
});

export default class File {
  constructor({
    id,
    linkId,
    url,
    filename,
    type,
    status,
    signedUploadUrl,
    ext,
    name,
    label
  }) {
    // hack for CardItemFile parse to File
    this._name = name;

    this.id = id;
    this.linkId = linkId;
    this.url = url;
    this.filename = filename;
    this.type = type || FILE_TYPES.FILE;
    this.status = status;
    this.signedUploadUrl = signedUploadUrl;
    this.ext = ext;
    this.label = label;
  }

  static getType(mimeType) {
    if (mimeType) {
      if (mimeType.startsWith('image/')) {
        return FILE_TYPES.IMAGE;
      }
      if (mimeType.startsWith('video/')) {
        return FILE_TYPES.VIDEO;
      }
    }
    return FILE_TYPES.FILE;
  }

  get name() {
    return this.filename || this._name || '';
  }

  get iconType() {
    if (this.ext) {
      const e = this.ext.toLowerCase();
      if (IMAGE_EXTENSIONS.includes(e)) {
        return 'image';
      } else if (VIDEO_EXTENSIONS.includes(e) || AUDIO_EXTENSIONS.includes(e)) {
        return 'media';
      } else if (PDF_EXTENSIONS.includes(e)) {
        return 'pdf';
      } else if (ZIP_EXTENSIONS.includes(e)) {
        return 'zip';
      } else if (SPREADSHEET_EXTENSIONS.includes(e)) {
        return 'sheet';
      }
    }
    return 'doc';
  }
}
