import React from 'react';
import classNames from 'classnames';

class PanelError extends React.Component {
  render() {
    const { ...props } = this.props;
    return <div className={classNames('c-panel--error')}>{props.children}</div>;
  }
}

export default PanelError;
