import React from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { INPUT_TYPES, MutationForm, Form } from '../../../lib/containers/form';
import { form } from '@dc-framework/js-utils';
import {
  createLogin,
  MUTATION_ADD_PERSON,
  MUTATION_CREATE_LOGIN,
  MUTATION_REMOVE_PERSON,
  MUTATION_UPDATE_PERSON,
  QUERY_GET_PERSONS_BY_ENTITY_ID,
  resetLogin,
} from '../../../services/graphql';
import { Button, ButtonsGroup } from '../../../lib/components/button';
import {
  defaultFormButtons,
  defaultFormMessages,
} from '../../../lib/helpers/copy/default-copy';
import { Mutation } from 'react-apollo';
const {
  isEmailValid,
  VALIDATE_TYPES: { ERROR_UNKNOWN },
} = form;

const UserForm = ({
  entityId,
  user,
  loading,
  refetchQueries,
  onCancel,
  onMutationComplete,
}) => {
  const MUTATION = user ? MUTATION_UPDATE_PERSON : MUTATION_ADD_PERSON;

  return (
    <>
      <MutationForm
        loading={loading}
        mutation={MUTATION}
        onCancel={onCancel}
        errorMessage={defaultFormMessages[ERROR_UNKNOWN]}
        mutationDataKey={user ? 'editPerson' : 'createPerson'}
        getMutationVariables={(data) => ({
          ...data,
          email: data.email.toLowerCase(),
        })}
        extraMutationVariables={{
          id: user ? user.id : undefined,
        }}
        refetchQueries={
          refetchQueries
            ? refetchQueries
            : [
                {
                  query: QUERY_GET_PERSONS_BY_ENTITY_ID,
                  variables: { entityId },
                },
              ]
        }
        onMutationComplete={onMutationComplete}
      >
        <Form.ItemWrapper
          formItems={[
            {
              key: 'email',
              label: 'Email',
              inputType: INPUT_TYPES.EMAIL,
              value: user ? user.email : '',
              validator: isEmailValid,
              disabled: user ? true : false,
            },
            {
              key: 'firstname',
              label: 'Firstname',
              value: user ? user.firstname : '',
              required: true,
            },
            {
              key: 'lastname',
              label: 'Lastname',
              value: user ? user.lastname : '',
              required: true,
            },
          ]}
        />
        <ButtonsGroup>
          <Form.SubmitButton primary />
          <Form.CancelButton secondary />

          {user && (
            <Button
              type="button"
              onClick={() =>
                resetLogin({
                  personId: user.id,
                })
              }
            >
              {defaultFormButtons.RESET_PASSWORD}
            </Button>
          )}
        </ButtonsGroup>
      </MutationForm>
    </>
  );
};
export default hooksObserver(UserForm);
