import React, { Component } from 'react';
import classNames from 'classnames';

class TableCell extends Component {
  render() {
    const { children, mainCell, onClick, id, ...props } = this.props;
    return (
      <td
        onClick={onClick}
        className={classNames('c-table-cell', {
          'c-table-cell--main': mainCell
        })}
        {...props}
      >
        <span id={id}> </span>
        {children}
      </td>
    );
  }
}

export default TableCell;
