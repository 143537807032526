import React, { useContext } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { StoresContext } from '../../../stores';
import NotificationPill from '../../components/notification-pill/NotificationPill';

const AppNotificationWrapper = () => {
  const {
    uiState: { notificationSuccess, notificationError, notificationData }
  } = useContext(StoresContext);
  return (
    <NotificationPill
      success={notificationSuccess}
      error={notificationError}
      show={notificationData ? true : false}
    >
      {notificationData && notificationData.message}
    </NotificationPill>
  );
};
export default hooksObserver(AppNotificationWrapper);
