import React, {
  createContext,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import './Accordion.scss';
import { Icon } from '../icon';

const AccordionContext = createContext({});

const checkIsActive = (active, position) => {
  if (Array.isArray(active)) {
    var index = active.indexOf(index);
    return index !== -1;
  } else {
    return active === position;
  }
};

export const AccordionItem = ({ children, ...props }) => {
  return React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      ...props,
    });
  });
};

export const AccordionHeader = ({ children, position, disabled, ...props }) => {
  const { onChange, activeIndex } = useContext(AccordionContext);
  return (
    <div
      className="c-collapsible__title js-collapsible__item"
      data-type="accordion"
    >
      {disabled ? (
        <div className="c-collapsible__button">{children}</div>
      ) : (
        <button
          className="c-collapsible__button"
          aria-expanded={activeIndex === position}
          onClick={() => onChange(activeIndex === position ? -1 : position)}
        >
          {children}
          <Icon
            small
            extraClassNames={
              activeIndex === position
                ? 'c-collapsible__icon-expanded'
                : 'c-collapsible__icon-collapsed'
            }
            ariaHidden="true"
            focusable="false"
            id={activeIndex === position ? 'down' : 'right'}
          />
        </button>
      )}
    </div>
  );
};

export const AccordionContent = ({ children, position }) => {
  const { activeIndex } = useContext(AccordionContext);
  const [height, setHeight] = useState(0);
  const containerRef = useRef(null);

  useLayoutEffect(() => {
    if (containerRef.current) {
      setHeight(containerRef.current.scrollHeight);
    }
  });

  const isHidden = !checkIsActive(activeIndex, position);

  return (
    // !isHidden && (
    <div
      ref={containerRef}
      className="c-collapsible__content"
      // hidden={isHidden}
      style={{ maxHeight: !isHidden && height }}
    >
      {children}
    </div>
    // )
  );
};

const Accordion = ({ children, index, isOpen }) => {
  const [activeIndex, setActiveIndex] = useState(isOpen ? 0 : index);

  const changeAccordionIndex = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="c-collapsible js-collapsible">
      <AccordionContext.Provider
        value={{
          onChange: changeAccordionIndex,
          activeIndex,
        }}
      >
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, {
            position: index,
          });
        })}
      </AccordionContext.Provider>
    </div>
  );
};

export const Collapsible = ({ children }) => {
  const [activeItems, setActiveIndex] = useState([]);

  const changeAccordionIndex = (index) => {
    var index = activeItems.indexOf(index);
    if (index !== -1) activeItems.splice(index, 1).activeItems.push(index);
    setActiveIndex(activeItems);
  };

  return (
    <div className="c-collapsible js-collapsible">
      <AccordionContext.Provider
        value={{
          onChange: changeAccordionIndex,
          activeItems,
        }}
      >
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, {
            position: index,
          });
        })}
      </AccordionContext.Provider>
    </div>
  );
};

export default Accordion;
