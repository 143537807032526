export default class Item {
  id = '';
  uuid = '';
  description = '';
  aliases = '';
  thumb = '';
  slug = '';
  created_at = '';
  updated_at = '';
  deleted_at = '';
  published_at = '';
  order;
  meta;

  constructor({
    id,
    uuid,
    title,
    name,
    description,
    aliases,
    thumb,
    slug,
    created_at,
    updated_at,
    deleted_at,
    date_published,
    order,
    meta,
    __isNew__,
    __isUpdated__
  }) {
    // temp
    this.__isNew__ = __isNew__;
    this.__isUpdated__ = __isUpdated__;

    this.id = id;
    this.uuid = uuid;
    this.title = title || name;
    this.description = description;
    this.aliases = aliases;
    this.thumb = thumb;
    this.slug = slug;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
    this.date_published = date_published;
    this.order = order;
    this.meta = meta;

    this.thumb && (this.thumbnail = this.thumb.url);
  }

  get name() {
    return this.title;
  }
}
