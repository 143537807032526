import React, { useContext, useState } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { StoresContext } from '../../../stores';
import { form, file } from '@dc-framework/js-utils';
import {
  MutationForm,
  Form,
  FORM_ITEM_TYPES
} from '../../../lib/containers/form';
import { ButtonsGroup } from '../../../lib/components/button';
import { defaultFormMessages } from '../../../lib/helpers/copy/default-copy';
import {
  MUTATION_ADD_GROUP,
  MUTATION_UPDATE_GROUP,
  QUERY_GET_GROUPS_BY_ENTITY_ID
} from '../../../services/graphql';
import { useTags } from '../../../services/hooks/useTags';
import { injectIntl } from 'react-intl';
import { handleTags } from '../../../services/graphql/tag-query';
import { getInputTagFormat } from '../../../utils/tags';
import File, { FILE_TYPES } from '../../../models/File';
import { uploader } from '../../../services/uploader';
import messages from './messages';
import appMessages from '../../../messages';
import { MAX_DESCRIPTION_LENGTH } from '../../../constants';

const { MIME_TYPES_PRESETS, getMimeTypesByPreset } = file;
const {
  VALIDATE_TYPES: { ERROR_UNKNOWN },
  isMaxLengthValid
} = form;

const GroupForm = ({
  intl,
  edit = true,
  onEdit,
  rootEntityId,
  group,
  loading,
  refetchQueries,
  onCancel,
  onMutationComplete
}) => {
  const { uiState } = useContext(StoresContext);
  const MUTATION = group ? MUTATION_UPDATE_GROUP : MUTATION_ADD_GROUP;
  const [tagLoading, setTagLoading] = useState(false);
  const [thumb, setThumb] = useState(
    (group && group.thumb && [new File(group.thumb)]) || []
  );
  const { dbTags, refetch } = useTags();
  const tags = group ? getInputTagFormat(group.tags) : [];
  let tagsToSave = null;

  let description = '';
  if (group) {
    try {
      description = JSON.parse(group.meta).description;
    } catch (er) {}
  }

  const refetcher = [
    {
      query: QUERY_GET_GROUPS_BY_ENTITY_ID,
      variables: { entityId: rootEntityId }
    },
    ...(refetchQueries || [])
  ];

  const tagsHandler = async (entityId, refetchQueries) => {
    await handleTags({
      currentTags: tagsToSave,
      previousTags: tags,
      entityId,
      refetchQueries
    });
    refetch();
  };

  return (
    <>
      <MutationForm
        edit={edit}
        loading={loading || tagLoading}
        mutation={MUTATION}
        onCancel={onCancel}
        onEdit={onEdit}
        errorMessage={defaultFormMessages[ERROR_UNKNOWN]}
        getMutationVariables={async data => {
          // tags
          tagsToSave = data.tags;
          data.tags = undefined;
          if (group) {
            setTagLoading(true);
            await tagsHandler(group.id);
            setTagLoading(false);
          }

          // description via meta
          data.meta = JSON.stringify({ description: data.description });

          // thumb
          data.thumbFileId = data.thumb.length ? data.thumb[0].id : undefined;

          return data;
        }}
        extraMutationVariables={{
          parentId: rootEntityId,
          id: group ? group.id : undefined
        }}
        refetchQueries={group ? () => refetcher : undefined}
        mutationDataKey={group ? 'editEntity' : 'createEntity'}
        onMutationComplete={async data => {
          let hasToRefetch = false;
          if (!group) {
            if (tagsToSave && tagsToSave.length) {
              setTagLoading(true);
              await tagsHandler(data.id, refetcher);
              setTagLoading(false);
            } else {
              hasToRefetch = true;
            }
          }

          uiState.showNotification({
            message: intl.formatMessage(messages.notificationMessageGroupSaved)
          });
          onMutationComplete(data, hasToRefetch);
        }}
      >
        <Form.ItemWrapper
          formItems={[
            {
              key: 'thumb',
              type: FORM_ITEM_TYPES.FILE_UPLOADER,
              label: intl.formatMessage(appMessages.formLabelThumbnail),
              value: thumb,
              uploader,
              multiple: false,
              required: false,
              accept: getMimeTypesByPreset(MIME_TYPES_PRESETS.IMAGE),
              canDelete: false,
              isGallery: true,
              fileUploadIcon: 'image',
              controlledValue: true,
              labelFileManager: intl.formatMessage(
                messages.groupSelectExistingImage
              ),
              onBrowseFileManager: () => {
                uiState.showSelectFileModal({
                  ignoreFiles: thumb,
                  type: FILE_TYPES.IMAGE,
                  onSelect: files => setThumb(files),
                  multiple: false
                });
              }
            },
            {
              key: 'name',
              label: intl.formatMessage(appMessages.formLabelName),
              value: group ? group.name : '',
              required: true
            },
            {
              key: 'description',
              label: intl.formatMessage(appMessages.formLabelDescription, {
                maxLength: MAX_DESCRIPTION_LENGTH
              }),
              value: description,
              type: FORM_ITEM_TYPES.TEXTAREA,
              maxLength: MAX_DESCRIPTION_LENGTH,
              validator: ({ value }) =>
                isMaxLengthValid({ value, maxLength: MAX_DESCRIPTION_LENGTH })
            },
            {
              key: 'tags',
              label: intl.formatMessage(appMessages.formLabelTags),
              value: tags,
              type: FORM_ITEM_TYPES.TAG_INPUT,
              options: dbTags
            }
          ]}
        />
        <ButtonsGroup>
          <Form.EditButton />
          <Form.SubmitButton primary />
          <Form.CancelButton secondary />
        </ButtonsGroup>
      </MutationForm>
    </>
  );
};
export default injectIntl(hooksObserver(GroupForm));
