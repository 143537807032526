import React from 'react';
import classNames from 'classnames';

import { Button } from './Button';
import { Icon } from '../icon';

export const ButtonDragger = ({
  children,
  large,
  extraClassNames,
  ...inputProps
}) => (
  <Button
    large={large}
    extraClassNames={classNames('c-button--dragger', extraClassNames)}
    {...inputProps}
  >
    {children ? children : <Icon id="drag" small secondary />}
  </Button>
);
