import React, { Component } from 'react';
import classNames from 'classnames';

// const Arrow = props => {
//   let ascending = props.sortDir === 'ascending';
//   return (
//     <svg viewBox="0 0 100 200" width="100" height="200">
//       {!(!ascending && props.isCurrent) && (
//         <polyline points="20 50, 50 20, 80 50" />
//       )}
//       <line x1="50" y1="20" x2="50" y2="180" />
//       {!(ascending && props.isCurrent) && (
//         <polyline points="20 150, 50 180, 80 150" />
//       )}
//     </svg>
//   );
// };
class TableHeaderCell extends Component {
  render() {
    const {
      children,
      extraClassNames,
      sortDir,
      isCurrent,
      unsortable,
      ...props
    } = this.props;
    return (
      <th
        className={classNames(
          'c-table-cell c-table-cell--header',
          extraClassNames
        )}
        {...props}
      >
        <div className={classNames('c-table-cell--wrapper')}>
          {children}
          {/* {!unsortable && <Arrow sortDir={sortDir} isCurrent={isCurrent} />} */}
        </div>
      </th>
    );
  }
}

export default TableHeaderCell;
