import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { form } from '@dc-framework/js-utils';
import { Button, ButtonsGroup } from '../../../components/button';
import {
  defaultTitleLabels,
  defaultFormButtons,
  defaultFormLabels
} from '../../../helpers/copy/default-copy';
import {
  Card,
  CardFooter,
  CardBody,
  CardHeader,
  CardActions
} from '../../../components/card';
import { Form } from '../../form/index';
import { INPUT_TYPES } from '../../form/views/index';
import { Icon } from '../../../components';
//import Icon from '../../../components/icon/Icon';

const { isEmailValid } = form;

export default class ForgotLoginView extends Component {
  static propTypes = {
    emailValidator: PropTypes.func,
    labelCancelButton: PropTypes.bool,
    labelLogin: PropTypes.string,
    labelSubmitButton: PropTypes.string,
    labelSubtitle: PropTypes.string,
    labelTitle: PropTypes.string,
    loading: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    noCard: PropTypes.bool,
    email: PropTypes.string
  };

  handleSubmit = async ({ email }) => {
    const { onSubmit } = this.props;
    onSubmit && (await onSubmit(email));
  };

  render() {
    const {
      noCard,
      email,
      loading = false,
      emailValidator = isEmailValid,
      labelTitle = defaultTitleLabels.FORGOT_LOGIN_TITLE,
      labelSubtitle = defaultTitleLabels.FORGOT_LOGIN_SUBTITLE,
      labelCancelButton = defaultFormButtons.CANCEL,
      labelSubmitButton = defaultFormButtons.RESET_PASSWORD,
      labelLogin = defaultFormLabels.EMAIL,
      onCancel
    } = this.props;

    const Wrapper = noCard ? Fragment : Card;
    return (
      <Wrapper>
        <CardHeader>
          {labelTitle && <h2 className="u-h3">{labelTitle}</h2>}
          {labelSubtitle && <p>{labelSubtitle}</p>}
          <CardActions login>
            <Button
              ghost
              primary
              rounded
              iconLeft
              type="button"
              // extraClassNames="c-button--absolute-top-left c-button--large-spacing"
              disabled={loading}
              onClick={onCancel}
            >
              <Icon id="left" small />
              {'Back' /*<Icon id="back-nav" mediumIcon />*/}
            </Button>
          </CardActions>
        </CardHeader>
        <Form onSubmit={this.handleSubmit} onCancel={onCancel}>
          <CardBody>
            <Form.ItemWrapper
              formItems={[
                {
                  key: 'email',
                  label: labelLogin,
                  value: email,
                  inputType: INPUT_TYPES.EMAIL,
                  validator: emailValidator,
                  required: true
                }
              ]}
            />
          </CardBody>
          <CardFooter>
            <ButtonsGroup>
              <Form.CancelButton
                secondary
                label={labelCancelButton}
                tabIndex="-1"
              />
              <Form.SubmitButton primary label={labelSubmitButton} />
            </ButtonsGroup>
          </CardFooter>
        </Form>
      </Wrapper>
    );
  }
}
