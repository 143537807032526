import React, { useEffect, useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { StoresContext } from '../../../stores';
import {
  ROUTE_TAG_ADD,
  ROUTE_TAG_EDIT,
  ROUTE_TAGS
} from '../../../routes/RouteList';
import AddTag from './AddTag';
import { Tags } from './Tags';
import Tag from './Tag';

const TagsPage = () => {
  const { uiState } = useContext(StoresContext);
  useEffect(() => {
    uiState.setPageTitle('');
  });
  return (
    <Switch>
      <Route path={ROUTE_TAG_ADD} component={() => <AddTag />} />
      <Route
        path={ROUTE_TAG_EDIT}
        component={props => <Tag tagSlug={props.match.params.tagSlug} />}
      />
      <Route path={ROUTE_TAGS} component={() => <Tags />} />
    </Switch>
  );
};
export default TagsPage;
