import React from 'react';
import PropTypes from 'prop-types';
import {
  LoginView,
  ForgotLoginView,
  ForgotLoginSuccessView,
  SetPasswordView
} from './views';
import { Notification } from '../../components/notification';

export const LOGIN_VIEW_STATES = Object.freeze({
  LOGIN: 'login',
  FORGOT_LOGIN: 'forgotLogin',
  FORGOT_LOGIN_SUCCESS: 'forgotLoginSuccess',
  SET_PASSWORD: 'setPassword'
});

export default class LoginContainer extends React.Component {
  static propTypes = {
    forgotLoginLabels: PropTypes.object,
    forgotLoginSuccessLabels: PropTypes.object,
    forgotPasswordEmail: PropTypes.string,
    loading: PropTypes.bool,
    error: PropTypes.string,
    loginLabels: PropTypes.object,
    loginViewState: PropTypes.oneOf(Object.values(LOGIN_VIEW_STATES)),
    onCancelForgotPasswordClicked: PropTypes.func,
    onCancelForgotPasswordSuccessClicked: PropTypes.func,
    onForgotPasswordClicked: PropTypes.func,
    onSubmitForgotPassword: PropTypes.func,
    onSubmitLogin: PropTypes.func,
    onSubmitSetPassword: PropTypes.func,
    passwordValidator: PropTypes.func,
    setPasswordLabels: PropTypes.object,
    noCard: PropTypes.bool,
    LoginViewComponent: PropTypes.any,
    ForgotLoginViewComponent: PropTypes.any,
    ForgotLoginSuccessViewComponent: PropTypes.any,
    SetPasswordViewComponent: PropTypes.any
  };

  state = {
    loginViewState: this.props.loginViewState || LOGIN_VIEW_STATES.LOGIN
  };

  componentDidUpdate(prevProps) {
    const { loginViewState } = this.props;
    if (
      loginViewState !== prevProps.loginViewState &&
      loginViewState !== undefined
    ) {
      this.setState({ loginViewState });
    }
  }

  onForgotPassword = () => {
    this.setState({ loginViewState: LOGIN_VIEW_STATES.FORGOT_LOGIN });
    const { onForgotPasswordClicked } = this.props;
    if (onForgotPasswordClicked) onForgotPasswordClicked();
  };

  onForgotPasswordCancel = () => {
    this.setState({ loginViewState: LOGIN_VIEW_STATES.LOGIN });
    const { onCancelForgotPasswordClicked } = this.props;
    if (onCancelForgotPasswordClicked) onCancelForgotPasswordClicked();
  };

  onForgotPasswordSuccessCancel = () => {
    this.setState({ loginViewState: LOGIN_VIEW_STATES.LOGIN });
    const { onCancelForgotPasswordSuccessClicked } = this.props;
    if (onCancelForgotPasswordSuccessClicked)
      onCancelForgotPasswordSuccessClicked();
  };

  getComponent = loginViewState => {
    const {
      error,
      loading,
      onSubmitLogin = () => console.warn('No onSubmitLogin prop found.'),
      onSubmitForgotPassword = () =>
        console.warn('No onSubmitForgotPassword prop found.'),
      onSubmitSetPassword = () =>
        console.warn('No onSubmitSetPassword prop found.'),
      forgotPasswordEmail,
      passwordValidator,
      loginLabels,
      forgotLoginLabels,
      forgotLoginSuccessLabels,
      setPasswordLabels,
      onCancelForgotPasswordSuccessClicked,
      LoginViewComponent = LoginView,
      ForgotLoginViewComponent = ForgotLoginView,
      ForgotLoginSuccessViewComponent = ForgotLoginSuccessView,
      SetPasswordViewComponent = SetPasswordView,
      ...props
    } = this.props;
    switch (loginViewState) {
      case LOGIN_VIEW_STATES.LOGIN:
        return (
          <LoginViewComponent
            {...props}
            {...loginLabels}
            error={error}
            loading={loading}
            onSubmit={onSubmitLogin}
            onForgotPassword={this.onForgotPassword}
          />
        );
      case LOGIN_VIEW_STATES.FORGOT_LOGIN:
        return (
          <ForgotLoginViewComponent
            {...props}
            {...forgotLoginLabels}
            loading={loading}
            onSubmit={onSubmitForgotPassword}
            onCancel={this.onForgotPasswordCancel}
          />
        );
      case LOGIN_VIEW_STATES.FORGOT_LOGIN_SUCCESS:
        return (
          <ForgotLoginSuccessViewComponent
            {...props}
            {...forgotLoginSuccessLabels}
            email={forgotPasswordEmail}
            onCancel={onCancelForgotPasswordSuccessClicked}
          />
        );
      case LOGIN_VIEW_STATES.SET_PASSWORD:
        return (
          <SetPasswordViewComponent
            {...props}
            {...setPasswordLabels}
            error={error}
            loading={loading}
            passwordValidator={passwordValidator}
            onSubmit={onSubmitSetPassword}
          />
        );
      default:
        return (
          <Notification show errorMessage>
            Hm, something went wrong, no correct loginViewState.
          </Notification>
        );
    }
  };

  render() {
    const { loginViewState } = this.state;
    return this.getComponent(loginViewState);
  }
}
