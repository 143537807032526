import React from 'react';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { defaultFormButtons } from '../../helpers/copy/default-copy';
import { Button } from '../../components/button/Button';
import { MODAL_TYPES } from '../../../models/ModalData';

const ConfirmButton = ({
  children,
  rootStore: { uiState },
  confirmType,
  confirmTitle,
  confirmMessage,
  confirmAcceptLabel = defaultFormButtons.REMOVE,
  confirmOkHandler,
  confirmCancelLabel = defaultFormButtons.CANCEL,
  confirmDismissButton,
  confirmDismissHandler,
  mutation,
  variables,
  refetchQueries,
  update,
  onClick,
  ...props
}) => {
  return (
    <Button
      {...props}
      onClick={e => {
        e.preventDefault();
        if (onClick) onClick();
        uiState.showModal({
          title: confirmTitle || '',
          message: confirmMessage || '',
          type: confirmType || MODAL_TYPES.ALERT,
          okLabel: confirmAcceptLabel,
          okHandler: confirmOkHandler,
          dismissLabel: confirmCancelLabel,
          dismissButton: confirmDismissButton,
          dismissHandler: confirmDismissHandler,
          mutationData: mutation
            ? {
                mutation,
                variables,
                refetchQueries: () => refetchQueries,
                update
              }
            : null
        });
      }}
    >
      {children}
    </Button>
  );
};

ConfirmButton.propTypes = {
  children: PropTypes.any,
  confirmAcceptLabel: PropTypes.string,
  confirmCancelLabel: PropTypes.string,
  confirmMessage: PropTypes.string,
  confirmTitle: PropTypes.string,
  mutation: PropTypes.any,
  onClick: PropTypes.func,
  refetchQueries: PropTypes.array,
  update: PropTypes.func,
  variables: PropTypes.any
};

export default inject('rootStore')(ConfirmButton);
