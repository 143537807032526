export const getGQLMutationResult = async (
  client,
  {
    mutation,
    variables,
    optimisticResponse,
    update,
    refetchQueries = [],
    ...otherProps
  }
) => {
  const result = await client.mutate({
    mutation,
    variables,
    optimisticResponse,
    update,
    refetchQueries: () => refetchQueries,
    ...otherProps
  });
  return result;
};
