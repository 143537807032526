import React from 'react';
import classNames from 'classnames';
import { Link as ReactRouterLink } from 'react-router-dom';
import './Link.scss';

const Link = ({
  children,
  to,
  buttonSmallActionPrimaryIcon,
  breadcrumb,
  extraClassNames,
  ...props
}) => (
  <ReactRouterLink
    {...props}
    className={classNames('c-link', extraClassNames, {
      'c-button c-button--small c-button--action-primary c-button--icon': buttonSmallActionPrimaryIcon,
      'c-link__breadcrumb': breadcrumb
    })}
    to={to}
  >
    {children}
  </ReactRouterLink>
);
export default Link;
