import React, { useContext } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { StoresContext } from '../../../stores';
import BasicModal from '../modal/BasicModal';

const AppModalWrapper = () => {
  const {
    uiState: { modalData }
  } = useContext(StoresContext);
  return <>{modalData && <BasicModal modalData={modalData} />}</>;
};
export default hooksObserver(AppModalWrapper);
