import React from 'react';
import classNames from 'classnames';
import PanelError from '../../components/panel/PanelError';
import Icon from '../../components/icon/Icon';
import { Button } from '../../components/button';

const RetryPanel = ({ labelTitle, labelMessage, labelRetryButton }) => (
  <PanelError>
    <div className={classNames('c-panel--empty-state-wrapper')}>
      <div className="c-panel--mesage-icon">
        <Icon id="database-error" mediumIcon />
      </div>
      <div className="c-panel--message c-panel--message-wrapper">
        <p className="c-panel--message-text c-panel--message-text-warning">
          {labelTitle}
        </p>
        <p className="c-panel--message-text">{labelMessage}</p>
      </div>
      <Button onClick={() => window.location.reload()}>
        <Icon id="retry" smallIcon />
        <span>{labelRetryButton}</span>
      </Button>
    </div>
  </PanelError>
);

export default RetryPanel;
