import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Radiobutton from './Radiobutton';
import { Notification } from '../notification';

const callAll = (...fns) => (...args) => fns.forEach(fn => fn && fn(...args));

const RadioButtonGroupContext = React.createContext();

export default class RadioButtonGroup extends Component {
  static propTypes = {
    children: PropTypes.any,
    errorMessage: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    noTitle: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    options: PropTypes.array,
    readOnly: PropTypes.bool,
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    valueError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    disabled: PropTypes.bool
  };

  static Item = ({ children, optionValue, optionLabel, ...props }) => (
    <RadioButtonGroupContext.Consumer>
      {({ readOnly, onChange, onBlur, value, id }) => (
        <Radiobutton
          {...props}
          id={optionValue}
          key={optionValue}
          name={id}
          readOnly={readOnly}
          checked={value === optionValue}
          onChange={callAll(props.onChange, e => onChange(e, optionValue))}
          onBlur={callAll(props.onBlur, onBlur)}
        >
          {children ? children : optionLabel}
        </Radiobutton>
      )}
    </RadioButtonGroupContext.Consumer>
  );

  onChange = (e, optionValue) => {
    const { onChange } = this.props;
    onChange && onChange({ ...e, target: { value: optionValue } });
  };

  onBlur = e => {
    const { onBlur } = this.props;
    onBlur && onBlur(e);
  };

  render() {
    const {
      readOnly,
      options,
      children,
      id,
      value,
      valueError,
      errorMessage,
      title,
      label,
      noTitle,
      disabled
    } = this.props;
    return (
      <fieldset
        className={classNames('c-input--radiobutton-group', {
          'c-input--radiobutton-group-error': valueError || errorMessage
        })}
      >
        <RadioButtonGroupContext.Provider
          value={{
            readOnly,
            onChange: this.onChange,
            onBlur: this.onBlur,
            value,
            id
          }}
        >
          {!noTitle && <legend>{title || label}</legend>}
          {children
            ? children
            : options &&
              options.length &&
              options.map(o => (
                <RadioButtonGroup.Item
                  key={o.value}
                  optionValue={o.value}
                  optionLabel={o.label}
                  disabled={disabled}
                >
                  {o.label}
                </RadioButtonGroup.Item>
              ))}
        </RadioButtonGroupContext.Provider>
        {errorMessage && (
          <Notification errorMessage>{errorMessage}</Notification>
        )}
      </fieldset>
    );
  }
}
