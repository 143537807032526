import React from 'react';
import PropTypes from 'prop-types';
import BaseInput from './BaseInput';
import { Notification } from '../notification';
import { Icon } from '../icon';

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.ref = props.innerref || React.createRef();
  }

  render() {
    const {
      children,
      inputIconRight,
      inputIconLeft,
      inputIconId,
      errorMessage,
      hasError,
      ...inputProps
    } = this.props;
    return (
      <BaseInput
        {...inputProps}
        ref={this.innerref}
        hasError={hasError}
        inputIconLeft={inputIconLeft}
        inputIconRight={inputIconRight}
        inputIconId={inputIconId}
      >
        {(inputIconRight || inputIconLeft) && <Icon id={inputIconId} />}
        {children}
        {errorMessage && (
          <Notification errorMessage>{errorMessage}</Notification>
        )}
      </BaseInput>
    );
  }
}

Input.propTypes = {
  children: PropTypes.any,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hasError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  innerref: PropTypes.any,
  inputIconId: PropTypes.string,
  inputIconLeft: PropTypes.any,
  inputIconRight: PropTypes.any
};

export default React.forwardRef((props, ref) => (
  <Input innerref={ref} {...props} />
));
