import React from 'react';
import classNames from 'classnames';
import './Logo.scss';

class Logo extends React.Component {
  render() {
    const { imgFilename, altText, linkTo, ...props } = this.props;
    return (
      <>
        {linkTo ? (
          <a href={linkTo} className={classNames('c-logo')}>
            <img src={`/assets/images/${imgFilename}`} alt={altText} />
          </a>
        ) : (
          <div className={classNames('c-logo')}>
            <img src={`/assets/images/${imgFilename}`} alt={altText} />
          </div>
        )}
      </>
    );
  }
}
export default Logo;
