import React, { Component } from 'react';

import { List, ListItem } from '../../list';
import { LinkExternal } from '../../link';
import { Icon } from '../../icon';
import { Button, ButtonsGroup } from '../../button';
import Checkbox from '../../input-checkbox-radiobutton/Checkbox';
import { Input } from '../../input';

export default class FileUploadList extends Component {
  state = {
    editFile: null,
    editLabel: ''
  };
  render() {
    const { editFile, editLabel } = this.state;
    const {
      isSelectable,
      canEdit,
      order,
      files,
      disabled,
      onDeleteFile,
      onUpdateFile,
      onFileSelect,
      labelEditButton = 'Edit',
      labelDeleteButton = 'Delete',
      labelSaveButton = 'Save',
      labelCancelButton = 'Cancel',
      canDelete = true,
      selectedFiles
    } = this.props;

    const onSaveEdit = e => {
      onUpdateFile && onUpdateFile(editFile, { label: editLabel });
      this.setState({ editFile: null, editLabel: '' });
    };

    const onCancelEdit = e => {
      this.setState({ editFile: null, editLabel: '' });
    };

    return (
      <>
        {files.length > 0 && (
          <List
            fileupload
            extraClassNames={'c-list--file-upload-manager'}
            style={{ order: order }}
          >
            {files.map(file =>
              isSelectable ? (
                <ListItem key={file.id}>
                  <Checkbox
                    name={'files'}
                    id={`file-${file.id}`}
                    onChange={() => onFileSelect && onFileSelect(file)}
                    checked={
                      selectedFiles.find(f => f.id === file.id) ? true : false
                    }
                  >
                    <Icon
                      large
                      secondary
                      id={
                        file.iconType
                          ? `icon-${file.iconType}-file`
                          : `icon-file`
                      }
                    />
                    <span className="c-file-upload__filename">
                      {file.label || file.filename || file.name}
                    </span>
                  </Checkbox>
                </ListItem>
              ) : (
                <ListItem key={file.id}>
                  {editFile && editFile.id === file.id ? (
                    <Input
                      id={file.id}
                      value={editLabel}
                      autoFocus={true}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          if (editLabel) {
                            onSaveEdit();
                          }
                        }
                      }}
                      onKeyDown={e => {
                        if (e.keyCode === 27) {
                          e.preventDefault();
                          onCancelEdit();
                        }
                      }}
                      onChange={e => {
                        this.setState({ editLabel: e.target.value });
                      }}
                    />
                  ) : (
                    <LinkExternal
                      download
                      fileUpload
                      target="_blank"
                      to={file.url ? file.url : '/'}
                    >
                      <Icon
                        large
                        secondary
                        id={
                          file.iconType
                            ? `icon-${file.iconType}-file`
                            : `icon-file`
                        }
                      />
                      <span className="c-file-upload__filename">
                        {file.label || file.filename || file.name}
                      </span>
                    </LinkExternal>
                  )}
                  {editFile && editFile.id === file.id && (
                    <ButtonsGroup>
                      <Button
                        disabled={disabled || !editLabel}
                        primary
                        small
                        type="button"
                        onClick={onSaveEdit}
                      >
                        {labelSaveButton}
                      </Button>
                      <Button
                        secondary
                        small
                        type="button"
                        onClick={onCancelEdit}
                      >
                        {labelCancelButton}
                      </Button>
                    </ButtonsGroup>
                  )}
                  {(canDelete || canEdit) && (
                    <ButtonsGroup>
                      {canEdit && (!editFile || editFile.id !== file.id) && (
                        <Button
                          small
                          actionPrimary
                          icon
                          type="button"
                          disabled={disabled}
                          onClick={() =>
                            this.setState({
                              editFile: file,
                              editLabel:
                                file.label || file.filename || file.name
                            })
                          }
                        >
                          <Icon id="edit" title={labelEditButton} />
                        </Button>
                      )}
                      {canDelete && (!editFile || editFile.id !== file.id) && (
                        <Button
                          small
                          actionAlert
                          icon
                          type="button"
                          disabled={disabled}
                          onClick={() => onDeleteFile && onDeleteFile(file)}
                        >
                          <Icon id="remove" title={labelDeleteButton} />
                        </Button>
                      )}
                    </ButtonsGroup>
                  )}
                </ListItem>
              )
            )}
          </List>
        )}
      </>
    );
  }
}
