import React from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { Switch, Route } from 'react-router-dom';
import {
  ROUTE_SLUG_CARDS,
  ROUTE_SLUG_CARD_ADD,
  ROUTE_SLUG_CARD_EDIT,
  ROUTE_SLUG_CARD
} from '../../../../../routes/RouteList';
import Cards from './Cards';
import AddCard from './AddCard';
import Card from './Card';

const CardsSlugPage = () => {
  return (
    <Switch>
      <Route
        path={ROUTE_SLUG_CARD_ADD}
        component={props => (
          <AddCard
            groupSlug={props.match.params.groupSlug}
            topicSlug={props.match.params.topicSlug}
          />
        )}
      />
      <Route
        path={ROUTE_SLUG_CARD_EDIT}
        component={props => (
          <Card
            edit={true}
            groupSlug={props.match.params.groupSlug}
            topicSlug={props.match.params.topicSlug}
            cardSlug={props.match.params.cardSlug}
          />
        )}
      />
      <Route
        path={ROUTE_SLUG_CARD}
        component={props => (
          <Card
            edit={false}
            groupSlug={props.match.params.groupSlug}
            topicSlug={props.match.params.topicSlug}
            cardSlug={props.match.params.cardSlug}
          />
        )}
      />
      <Route
        path={ROUTE_SLUG_CARDS}
        component={props => (
          <Cards
            groupSlug={props.match.params.groupSlug}
            topicSlug={props.match.params.topicSlug}
          />
        )}
      />
    </Switch>
  );
};
export default hooksObserver(CardsSlugPage);
