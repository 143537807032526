// Auth
export const ROUTE_LOGIN = '/login';
export const ROUTE_SET_PASSWORD = '/set-password';
export const ROUTE_FORGOT = '/forgot';
export const ROUTE_FORGOT_SUCCESS = '/forgot-success';

// Authenticated
export const ROUTE_TAGS = '/tags';
export const ROUTE_TAG_ADD = '/tags/add';
export const ROUTE_TAG = '/tags/:tagSlug';
export const ROUTE_TAG_EDIT = '/tags/:tagSlug/edit';

export const ROUTE_GROUPS = '/groups';
export const ROUTE_GROUP_ADD = '/groups/add';
export const ROUTE_GROUP = '/groups/:groupSlug';
export const ROUTE_GROUP_EDIT = '/groups/:groupSlug/edit';

export const ROUTE_TOPICS = '/groups/:groupSlug/topics';
export const ROUTE_TOPIC_ADD = '/groups/:groupSlug/topics/add';
export const ROUTE_TOPIC = '/groups/:groupSlug/topics/:topicSlug';
export const ROUTE_TOPIC_EDIT = '/groups/:groupSlug/topics/:topicSlug/edit';

export const ROUTE_CARDS = '/cards';
export const ROUTE_CARD_ADD = '/cards/add';
export const ROUTE_CARD = '/cards/:cardSlug';
export const ROUTE_CARD_EDIT = '/cards/:cardSlug/edit';

export const ROUTE_SLUG_CARDS = '/groups/:groupSlug/topics/:topicSlug/cards';
export const ROUTE_SLUG_CARD_ADD =
  '/groups/:groupSlug/topics/:topicSlug/cards/add';
export const ROUTE_SLUG_CARD =
  '/groups/:groupSlug/topics/:topicSlug/cards/:cardSlug';
export const ROUTE_SLUG_CARD_EDIT =
  '/groups/:groupSlug/topics/:topicSlug/cards/:cardSlug/edit';

export const ROUTE_USERS = '/users';
export const ROUTE_USERS_ADD = '/users/add';
export const ROUTE_USERS_USER = '/users/:personId';
export const ROUTE_USERS_EDIT = '/users/:personId/edit';

export const ROUTE_CATEGORIES = '/categories';
export const ROUTE_CATEGORY_ADD = '/categories/add';
export const ROUTE_CATEGORY = '/categories/:categorySlug';
export const ROUTE_CATEGORY_EDIT = '/categories/:categorySlug/edit';

export const ROUTE_FILE_MANAGER = '/filemanager';

export const ROUTE_TEST = '/test';
export const ROUTE_HOOKS = '/hooks';
