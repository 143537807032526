import { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo-hooks';
import { QUERY_S_GET_TOPICS_BY_SLUG } from '../../../../../services/graphql/topic-query';

export const useTopicName = topicSlug => {
  const [name, setName] = useState('');
  const queryProps = {
    query: QUERY_S_GET_TOPICS_BY_SLUG,
    variables: { slug: topicSlug }
  };
  const { data, loading, error } = useQuery(queryProps.query, {
    variables: queryProps.variables
  });

  useEffect(() => {
    const name =
      (data && data.getEntityBySlug && data.getEntityBySlug.name) || '';
    setName(name);
  }, [data]);

  return {
    data,
    loading,
    error,
    name,
    queryProps
  };
};
