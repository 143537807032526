import React from 'react';
import classNames from 'classnames';

const LinkExternal = ({ children, to, fileUpload, ...props }) => (
  <a
    className={classNames('c-link', { 'c-link--file-upload': fileUpload })}
    href={to}
    {...props}
  >
    {children}
  </a>
);

export default LinkExternal;
