import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const ListItem = forwardRef(
  ({ id, onClick, children, extraClassNames, ...props }, ref) => (
    <li
      ref={ref}
      {...props}
      id={id}
      className={classNames('o-list-bare__item c-list__item', extraClassNames)}
      onClick={onClick}
    >
      {children}
    </li>
  )
);

ListItem.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
};
