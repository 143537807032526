import React from 'react';
import { Input } from '../../../../lib/components/input';
import classNames from 'classnames';
import messages from '../messages';
import { injectIntl } from 'react-intl';
import { CardItemFileType } from '../../../../constants';

export const ContentBlockMultiSkillz = injectIntl(
  ({ intl, contentBlock, width = 640, height = 360, onChange, disabled }) => {
    let url =
      contentBlock &&
      contentBlock.files &&
      contentBlock.files.length &&
      contentBlock.files[0].url;
    url = url || '';
    return (
      <>
        <Input
          disabled={disabled}
          id={contentBlock.id}
          label={intl.formatMessage(messages.articleEditorMultiSkillzLabel)}
          placeholder={intl.formatMessage(
            messages.articleEditorMultiSkillzPlaceholder
          )}
          value={url}
          onChange={e => {
            contentBlock.files = [
              {
                url: e.target.value || undefined,
                type: CardItemFileType.MULTISKILLZ
              }
            ];
            onChange && onChange(contentBlock);
          }}
        />
        {url && (
          <div className={classNames('o-ratio o-ratio--16-by-9')}>
            <iframe
              title={contentBlock.title}
              src={url || ''}
              width={width}
              height={height}
              frameBorder="0"
              allowFullScreen={true}
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
            />
          </div>
        )}
      </>
    );
  }
);
