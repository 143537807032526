import gql from 'graphql-tag';
import { doMutation, doQuery } from '../api';

export const QUERY_S_GET_TOPICS_BY_SLUG = gql`
  query getEntityBySlug($slug: String!) {
    getEntityBySlug(slug: $slug) {
      id
      name
    }
  }
`;

export const refetchTopics = async slug => {
  await doQuery({
    query: QUERY_GET_TOPICS_BY_SLUG,
    variables: { slug }
  });
};

export const QUERY_GET_TOPICS_BY_SLUG = gql`
  query getEntityBySlug($slug: String!) {
    getEntityBySlug(slug: $slug) {
      id
      name
      meta
      parentId
      order
      thumb {
        id
        linkId
        url
        key
        filename
        type
        status
        label
      }
      subEntities {
        id
        name
        meta
        slug
        order
        tags {
          id
          slug
          name
          meta
          tagEntity {
            id
          }
        }
        thumb {
          id
          linkId
          url
          key
          filename
          type
          status
          label
        }
      }
    }
  }
`;

export const QUERY_GET_TOPIC_BY_SLUG = gql`
  query getEntityBySlug($slug: String!) {
    getEntityBySlug(slug: $slug) {
      id
      name
      slug
      config
      meta
      parentId
      thumb {
        id
        linkId
        url
        key
        filename
        type
        status
        label
      }
      tags {
        id
        slug
        name
        meta
        tagEntity {
          id
        }
      }
    }
  }
`;

export const MUTATION_ADD_TOPIC = gql`
  mutation createEntity(
    $parentSlug: String
    $name: String!
    $meta: AWSJSON
    $config: AWSJSON
    $thumbFileId: ID
  ) {
    createEntity(
      input: {
        parentSlug: $parentSlug
        name: $name
        meta: $meta
        config: $config
        thumbFileId: $thumbFileId
      }
    ) {
      id
      slug
      name
      meta
      tags {
        id
        slug
        name
        meta
        tagEntity {
          id
        }
      }
    }
  }
`;

export const MUTATION_UPDATE_TOPIC = gql`
  mutation editEntity(
    $id: ID!
    $name: String
    $meta: AWSJSON
    $config: AWSJSON
    $thumbFileId: ID
  ) {
    editEntity(
      input: {
        id: $id
        name: $name
        meta: $meta
        config: $config
        thumbFileId: $thumbFileId
      }
    ) {
      id
      name
      slug
      meta
      tags {
        id
        slug
        name
        meta
        tagEntity {
          id
        }
      }
    }
  }
`;

export const MUTATION_REMOVE_TOPIC = gql`
  mutation archiveEntity($entityId: ID!) {
    archiveEntity(entityId: $entityId)
  }
`;

export const MUTATION_UPDATE_TOPICS_ORDER = gql`
  mutation sortEntities($sortItemInput: [SortItemInput]) {
    sortEntities(input: $sortItemInput)
  }
`;

export const updateTopicsOrder = async (variables, refetchQueries) => {
  return new Promise(async (resolve, reject) => {
    const mutation = MUTATION_UPDATE_TOPICS_ORDER;
    return await doMutation({
      mutation,
      variables,
      update: (cache, { data, data: { sortEntities } }) => {
        if (sortEntities) {
          resolve(sortEntities);
        }
      },
      refetchQueries
    });
  });
};
