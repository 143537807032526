import React, { useContext } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { Mutation } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';
import { StoresContext } from '../../../stores';
import UserForm from './UserForm';
import { ROUTE_USERS } from '../../../routes/RouteList';
import messages from './messages';
import {
  QUERY_GET_PERSONS_BY_ENTITY_ID,
  MUTATION_ADD_PERSON_ENTITY,
  createLogin,
} from '../../../services/graphql/user-query';
import { PERSON_ROLES } from '../../../constants';

const AddUser = ({ intl }) => {
  const { authStore, routing, uiState } = useContext(StoresContext);
  const entityId = authStore.user.rootEntityId;
  const goBack = () => routing.push(ROUTE_USERS);
  return (
    <>
      <div className="o-layout">
        <div className="o-layout__item u-1-of-5-at-medium">
          <h2 className="u-margin-bottom-medium">
            <FormattedMessage {...messages.titleLabelAddUser} />
          </h2>
        </div>
        <div className="o-layout__item u-4-of-5-at-medium">
          <Mutation
            mutation={MUTATION_ADD_PERSON_ENTITY}
            refetchQueries={[
              {
                query: QUERY_GET_PERSONS_BY_ENTITY_ID,
                variables: { entityId },
              },
            ]}
            update={async (cache, { data }) => {
              if (data && data.createPersonEntity) {
                await createLogin({
                  personId: data.createPersonEntity.personId,
                });
                uiState.showNotification({
                  message: intl.formatMessage(
                    messages.notificationMessageUserSaved
                  ),
                });
                goBack && goBack();
              }
            }}
          >
            {(mutation, data) => {
              return (
                <UserForm
                  loading={data.loading}
                  entityId={entityId}
                  onMutationComplete={(person) =>
                    mutation({
                      variables: {
                        personId: person.id,
                        entityId,
                        role: PERSON_ROLES.ROLE_ADMIN,
                      },
                    })
                  }
                  onCancel={goBack}
                />
              );
            }}
          </Mutation>
        </div>
      </div>
    </>
  );
};
export default injectIntl(hooksObserver(AddUser));
