import { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo-hooks';
import { QUERY_GET_GROUP_TREE } from '../graphql/group-query';

export const useGroupTree = entityId => {
  const [groups, setGroups] = useState([]);
  const queryProps = {
    query: QUERY_GET_GROUP_TREE,
    variables: { entityId }
  };
  const { data, error, loading, refetch } = useQuery(queryProps.query, {
    variables: queryProps.variables
  });

  useEffect(() => {
    let rawGroups = data && data.getEntityTree && data.getEntityTree;
    if (!rawGroups || !rawGroups.length) {
      rawGroups = [];
    }

    const groupTree = rawGroups.map(rg => ({
      id: rg.id,
      name: rg.name,
      slug: rg.slug,
      topics: rg.subEntities.map(rgs => ({
        id: rgs.id,
        name: rgs.name,
        slug: rgs.slug
      }))
    }));

    setGroups(groupTree);
  }, [data]);

  return {
    queryProps,
    error,
    loading,
    groupTree: groups,
    refetch
  };
};
