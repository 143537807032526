import { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo-hooks';
import { QUERY_GET_TOPICS_BY_SLUG } from '../../../../services/graphql';
import Topic from '../../../../models/Topic';

export const useGroup = groupSlug => {
  const [topics, setTopics] = useState([]);
  const queryProps = {
    query: QUERY_GET_TOPICS_BY_SLUG,
    variables: { slug: groupSlug }
  };
  const { data, error, loading } = useQuery(queryProps.query, {
    variables: queryProps.variables
  });

  useEffect(() => {
    let rawTopics =
      data && data.getEntityBySlug && data.getEntityBySlug.subEntities;
    if (!rawTopics || !rawTopics.length) {
      rawTopics = [];
    }
    rawTopics = rawTopics.map(t => new Topic(t));
    setTopics(rawTopics);
  }, [data]);

  const entityData = data && data.getEntityBySlug;
  const groupTitle = entityData && entityData.name;

  return {
    queryProps,
    error,
    loading,
    data,
    topics: topics,
    groupTitle
  };
};
