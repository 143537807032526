import React from 'react';
import './Icon.scss';
import classNames from 'classnames';
import svgSymbols from '@dc-framework/style/images/svg-symbols.svg';

class Icon extends React.Component {
  render() {
    const {
      id,
      extraClassNames,
      small,
      large,
      primary,
      secondary,
      tertiary,
      neutral,
      inverted,
      titleId,
      title,
      svgLocalPath
    } = this.props;

    const svgPath = `${svgSymbols}#${id}`;

    return (
      <svg
        role="img"
        aria-labelledby={titleId}
        className={classNames('c-icon', extraClassNames, {
          'c-icon--small': small,
          'c-icon--large': large,
          'c-icon--primary': primary,
          'c-icon--secondary': secondary,
          'c-icon--tertiary': tertiary,
          'c-icon--neutral': neutral,
          'c-icon--inverted': inverted
        })}
      >
        {title && <title id={titleId}>{title}</title>}
        <use xlinkHref={id ? svgPath : svgLocalPath} />
      </svg>
    );
  }
}

export default Icon;
