import React from 'react';

import classNames from 'classnames';

class CardContent extends React.Component {
  render() {
    const { children } = this.props;
    return <div className={classNames('c-card__content')}>{children}</div>;
  }
}

export default CardContent;
