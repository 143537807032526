import React, { useEffect, useContext } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { Route, Switch } from 'react-router-dom';
import { StoresContext } from '../../../stores';
import {
  ROUTE_USERS,
  ROUTE_USERS_ADD,
  ROUTE_USERS_EDIT
} from '../../../routes/RouteList';
import Users from './Users';
import AddUser from './AddUser';
import EditUser from './EditUser';

const UsersPage = () => {
  const { uiState } = useContext(StoresContext);
  useEffect(() => {
    uiState.setPageTitle('');
  });
  return (
    <Switch>
      <Route path={ROUTE_USERS_ADD} component={() => <AddUser />} />
      <Route
        path={ROUTE_USERS_EDIT}
        component={props => <EditUser personId={props.match.params.personId} />}
      />
      <Route path={ROUTE_USERS} component={() => <Users />} />
    </Switch>
  );
};
export default hooksObserver(UsersPage);
