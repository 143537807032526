import Item from './Item';
import Topic from './Topic';
import Tag from './Tag';

export default class Group extends Item {
  topics;
  constructor({ subEntities, tags, ...props }) {
    super({ ...props });
    this.tags = tags && tags.length ? tags.map(t => new Tag(t)) : [];
    this.topics =
      subEntities && subEntities.length
        ? subEntities.map(t => new Topic(t))
        : [];
  }
}
