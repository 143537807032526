import { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo-hooks';
import { QUERY_GET_TAGS } from '../graphql/tag-query';
import { getInputTagFormat } from '../../utils/tags';

export const useTags = () => {
  const [tags, setTags] = useState([]);
  const queryProps = {
    query: QUERY_GET_TAGS
  };
  const { data, error, loading, refetch } = useQuery(queryProps.query, {
    variables: queryProps.variables
  });

  useEffect(() => {
    let rawTags = data && data.getTags;
    if (!rawTags || !rawTags.length) {
      rawTags = [];
    }

    rawTags = getInputTagFormat(rawTags);
    setTags(rawTags);
  }, [data]);

  return {
    queryProps,
    error,
    loading,
    dbTags: tags,
    refetch
  };
};
