import React from 'react';
import classNames from 'classnames';

class Wrapper extends React.Component {
  render() {
    const { children, textWrapper, extraClassNames, ...props } = this.props;
    return (
      <div
        className={classNames('o-wrapper', extraClassNames, {
          'c-page__wrapper-text': textWrapper
        })}
        {...props}
      >
        {children}
      </div>
    );
  }
}

export default Wrapper;
