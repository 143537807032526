import { defineMessages } from 'react-intl';

export default defineMessages({
  titleLabelCategories: {
    id: 'title.label.categories',
    defaultMessage: 'Categories'
  },
  titleLabelAddCategory: {
    id: 'title.label.add.category',
    defaultMessage: 'Add category'
  },
  titleLabelEditCategory: {
    id: 'title.label.edit.category',
    defaultMessage: 'Edit category'
  },
  buttonGoToCategories: {
    id: 'button.goto.categories',
    defaultMessage: 'Categories'
  },
  modalConfirmRemoveCategoryTitle: {
    id: 'modal.confirm.remove.category.title',
    defaultMessage: 'Remove category'
  },
  modalConfirmRemoveCategoryMessage: {
    id: 'modal.confirm.remove.category.message',
    defaultMessage: 'Are you sure you want to delete this category?'
  },
  notificationMessageCategorySaved: {
    id: 'notification.message.category.saved',
    defaultMessage: 'Category saved'
  },
  notificationMessageCategoryRemoved: {
    id: 'notification.message.category.removed',
    defaultMessage: 'Category removed'
  }
});
