import React from 'react';

import classNames from 'classnames';

class PageTitleGroup extends React.Component {
  render() {
    const { children } = this.props;
    return <div className={classNames('c-page__title')}>{children}</div>;
  }
}

export default PageTitleGroup;
