import { injectIntl } from 'react-intl';
import React, { useContext, useEffect, useState } from 'react';
import { StoresContext } from '../../../stores';
import {
  QUERY_GET_CATEGORIES,
  updateCategory,
  updateCategoryOrder,
} from '../../../services/graphql/categories-query';
import messages from '../../pages/categories/messages';
import { List, ListItem } from '../../../lib/components/list';
import { Button, Icon, Input } from '../../../lib/components';
import ButtonsGroupForCategories from './ButtonsGroupForCategories';
import Category from './Category';
import { CSS } from '@dnd-kit/utilities';
import { closestCenter, DndContext, useDroppable } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
  rectSwappingStrategy,
} from '@dnd-kit/sortable';
import {
  sort,
  SORT_DATA_TYPES,
} from '@dc-framework/js-utils/src/lib/utils/array';
import { ButtonDragger } from '../../../lib/components/button/ButtonDragger';

const CategoryGroup = injectIntl(
  ({ categoryGroup: cg, intl, parentReorder }) => {
    const { uiState } = useContext(StoresContext);
    const [sortedCategories, setSortedCategories] = useState([]);
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({
        id: cg.id,
      });
    const style = {
      transform: CSS.Translate.toString(transform),
      transition,
    };
    const [categoryName, setCategoryName] = useState();
    const [edit, setEdit] = useState(false);
    const [reorder, setReorder] = useState(false);

    useEffect(() => {
      if (cg.children) {
        setSortedCategories(
          sort([...cg.children], {
            keys: [{ key: 'order', dataType: SORT_DATA_TYPES.NUMBER }],
          })
        );
      }
    }, [cg]);

    const onCategoryUpdate = async () => {
      const categoryId = await updateCategory({
        refetchQueries: [{ query: QUERY_GET_CATEGORIES }],
        variables: {
          id: cg.id,
          name: categoryName,
        },
      });
      uiState.showNotification({
        message: intl.formatMessage(messages.notificationMessageCategorySaved),
      });
    };

    const handleDragEnd = (event) => {
      const { active, over } = event;

      if (active.id !== over.id) {
        const newSortedCats = (items) => {
          const oldIndex = items.findIndex((item) => item.id === active.id);
          const newIndex = items.findIndex((item) => item.id === over.id);

          return arrayMove(items, oldIndex, newIndex).map((cat, i) => ({
            ...cat,
            order: i,
          }));
        };

        setSortedCategories(newSortedCats);
      }
    };

    const updatecategoryOrderHandler = async () => {
      await updateCategoryOrder(sortedCategories);
      setReorder(false);
      uiState.showNotification({
        message: intl.formatMessage(messages.notificationMessageCategorySaved),
      });
    };

    return (
      <ListItem
        key={cg.id}
        ref={setNodeRef}
        style={style}
        extraClassNames={'c-draggable__item'}
      >
        <div className="c-list__group-name">
          {parentReorder && <ButtonDragger {...listeners} {...attributes} />}
          {edit ? (
            <Input
              id={`input-${cg.id}`}
              type="text"
              value={categoryName}
              extraClassNames={
                'c-input--small c-input--category c-input--sub-category'
              }
              autoFocus
              onKeyDown={(e) => {
                if (e.keyCode === 27) {
                  e.preventDefault();
                  setEdit(false);
                }
                if (e.keyCode === 13) {
                  e.preventDefault();
                  onCategoryUpdate();
                  setEdit(false);
                }
              }}
              onChange={(e) => setCategoryName(e.target.value)}
            />
          ) : (
            <span>{cg.name}</span>
          )}
        </div>
        {!parentReorder && (
          <ButtonsGroupForCategories
            category={cg}
            setEdit={setEdit}
            edit={edit}
            reorder={reorder}
            setReorder={setReorder}
            onUpdate={() => onCategoryUpdate()}
            onEdit={() => setCategoryName(cg.name)}
            onReorder={() => updatecategoryOrderHandler()}
          />
        )}
        {!parentReorder && sortedCategories.length > 0 && (
          <DndContext
            onDragEnd={handleDragEnd}
            collisionDetection={closestCenter}
          >
            <SortableContext items={sortedCategories}>
              <List extraClassNames="c-list__categories">
                {sortedCategories.map((category, i) => (
                  <DraggableCategoryListItem
                    key={category.id}
                    parentReorder={reorder}
                    category={category}
                  />
                ))}
              </List>
            </SortableContext>
          </DndContext>
        )}
      </ListItem>
    );
  }
);
export default CategoryGroup;

const DraggableCategoryListItem = ({ category, parentReorder, index }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: category.id,
    });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };
  return (
    <ListItem
      ref={setNodeRef}
      style={style}
      extraClassNames={parentReorder && 'c-draggable__item'}
    >
      {parentReorder && <ButtonDragger {...listeners} {...attributes} />}
      <Category
        category={category}
        index={index}
        parentReorder={parentReorder}
      />
    </ListItem>
  );
};
