import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { form, array } from '@dc-framework/js-utils';
import { Button } from '../../components/button';
import {
  defaultFormButtons,
  defaultFormMessages
} from '../../helpers/copy/default-copy';
import { Card } from '../../components/card';
import FormItem from './views/FormItem';

const { VALIDATE_TYPES, validate } = form;
const { arrayToObject, removeItemFromArray } = array;

const callAll = (...fns) => (...args) => fns.forEach(fn => fn && fn(...args));

const FormContext = React.createContext();

export default class Form extends Component {
  static propTypes = {
    children: PropTypes.any,
    edit: PropTypes.bool,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onInit: PropTypes.func,
    onReset: PropTypes.func,
    onSubmit: PropTypes.func,
    onBlur: PropTypes.func,
    onEdit: PropTypes.func,
    formMessages: PropTypes.any,
    resetOnCancel: PropTypes.bool,
    enableSubmitOnValid: PropTypes.bool,
    enableSubmitOnChange: PropTypes.bool,
    autoPlaceholder: PropTypes.bool,
    autoPlaceholderLowerCase: PropTypes.bool,
    card: PropTypes.bool
  };

  // Container state
  state = {
    valid: null,
    busy: false,
    changed: false
  };

  formItems = [];
  busyFormItems = [];
  didMount = false;

  static SubmitButton = ({ children, ...props }) => (
    <FormContext.Consumer>
      {({
        onSubmit,
        valid,
        loading,
        enableSubmitOnValid,
        enableSubmitOnChange,
        edit,
        disabled,
        busy,
        changed
      }) => {
        const mainProps = {
          onClick: callAll(onSubmit, props.onClick),
          type: 'submit',
          disabled:
            (!changed && enableSubmitOnChange) ||
            (!valid && enableSubmitOnValid) ||
            loading ||
            props.disabled ||
            disabled ||
            busy
        };
        if (!edit) {
          return null;
        }
        return children ? (
          React.Children.map(children, child =>
            React.cloneElement(child, {
              ...props,
              ...mainProps
            })
          )
        ) : (
          <Button {...props} {...mainProps}>
            {props.label ? props.label : defaultFormButtons.SAVE}
          </Button>
        );
      }}
    </FormContext.Consumer>
  );

  static CancelButton = ({ children, ...props }) => (
    <FormContext.Consumer>
      {({ onCancel, loading, edit, disabled, busy }) => {
        if (!edit) {
          return null;
        }
        const contextProps = {
          onClick: callAll(onCancel, props.onClick),
          type: 'button',
          disabled: loading || props.disabled || disabled || busy
        };
        return children ? (
          React.Children.map(children, child =>
            React.cloneElement(child, {
              ...props,
              ...contextProps
            })
          )
        ) : (
          <Button {...props} {...contextProps}>
            {props.label ? props.label : defaultFormButtons.CANCEL}
          </Button>
        );
      }}
    </FormContext.Consumer>
  );

  static ResetButton = ({ children, ...props }) => (
    <FormContext.Consumer>
      {({ onReset, loading, edit, disabled, busy }) => {
        if (!edit) {
          return null;
        }
        const contextProps = {
          onClick: callAll(onReset, props.onClick),
          type: 'button',
          disabled: loading || props.disabled || disabled || busy
        };

        return children ? (
          React.Children.map(children, child =>
            React.cloneElement(child, {
              ...props,
              ...contextProps
            })
          )
        ) : (
          <Button {...props} {...contextProps}>
            {props.label ? props.label : defaultFormButtons.RESET}
          </Button>
        );
      }}
    </FormContext.Consumer>
  );

  static EditButton = ({ children, ...props }) => (
    <FormContext.Consumer>
      {({ onEdit, loading, edit, disabled }) => {
        if (edit) {
          return null;
        }
        const contextProps = {
          onClick: callAll(onEdit, props.onClick),
          type: 'button',
          disabled: loading || props.disabled || disabled
        };
        return children ? (
          React.Children.map(children, child =>
            React.cloneElement(child, {
              ...props,
              ...contextProps
            })
          )
        ) : (
          <Button ghost primary {...props} {...contextProps}>
            {props.label ? props.label : defaultFormButtons.EDIT}
          </Button>
        );
      }}
    </FormContext.Consumer>
  );

  static Item = ({ children, formItem, ...props }) => (
    <FormContext.Consumer>
      {({
        edit,
        loading,
        onChange,
        onBlur,
        addFormItem,
        busyFormItem,
        removeFormItem,
        updateFormItem,
        getErrorMessage,
        disabled,
        autoPlaceholder,
        autoPlaceholderLowerCase,
        ...contextProps
      }) => {
        const mainProps = {
          edit,
          loading,
          formItem,
          onInit: addFormItem,
          onUpdate: updateFormItem,
          onOut: removeFormItem,
          onBusy: busyFormItem,
          autoPlaceholder,
          autoPlaceholderLowerCase,
          disabled: disabled || props.disabled || formItem.disabled,
          onChange: callAll(onChange, props.onChange),
          onBlur: callAll(onBlur, props.onBlur),
          value: contextProps[formItem.key],
          valueError: contextProps[`${formItem.key}Error`],
          errorMessage: getErrorMessage(
            contextProps[`${formItem.key}Error`],
            formItem.label
          )
        };
        return (
          <FormItem {...props} {...mainProps}>
            {children}
          </FormItem>
        );
      }}
    </FormContext.Consumer>
  );

  static ItemWrapper = ({ children, formItems = [], ...props }) => (
    <>
      {children
        ? children
        : formItems.map(fi => (
            <Form.Item {...props} key={fi.key} formItem={fi} />
          ))}
    </>
  );

  addFormItem = ({ formItem }) => {
    const previousFormItem = this.formItems.find(fi => fi.key === formItem.key);
    this.formItems = this.formItems.filter(fi => fi.key !== formItem.key);
    this.formItems.push(formItem);
    if (this.didMount) {
      if (!formItem.keep || !previousFormItem) {
        this.setState(
          {
            [formItem.key]: formItem.value
          },
          () => {
            const { onChange } = this.props;
            const eventData = this.getEventData({ formItem });
            this.setState({ valid: eventData.valid });
            onChange && onChange(eventData);
          }
        );
      }
    }
  };

  updateFormItem = ({ formItem }) => {
    // Optional feature: Update always formItem when value field is updated (componentDidUpdate)
    // Known Use case: on custom type formitem
    const previousFormItem = this.formItems.find(fi => fi.key === formItem.key);
    if (
      formItem.controlledValue === true &&
      formItem.value !== previousFormItem.value
    ) {
      this.setState({
        [formItem.key]: formItem.value
      });
    }

    this.formItems = this.formItems.filter(fi => fi.key !== formItem.key);
    this.formItems.push(formItem);
  };

  busyFormItem = ({ formItem, busy }) => {
    if (busy) {
      if (!this.busyFormItems.find(fi => fi.key === formItem.key)) {
        this.busyFormItems.push(formItem);
      }
    } else {
      removeItemFromArray(this.busyFormItems, formItem);
    }
    this.setState({ busy: this.busyFormItems.length ? true : false });
  };

  removeFormItem = ({ formItem }) => {
    if (!formItem.keep) {
      removeItemFromArray(this.formItems, formItem);
    }
  };

  componentDidMount() {
    this.didMount = true;
    this.setState(
      {
        ...arrayToObject(this.formItems, 'key', 'value')
      },
      () => {
        const { onInit } = this.props;
        const eventData = this.getEventData();
        this.setState({ valid: eventData.valid });
        onInit && onInit(eventData);
      }
    );
  }

  getErrorMessage = (validType, label) => {
    const currentMessages = this.getCurrentMessages();
    if (currentMessages[validType]) {
      return currentMessages[validType].replace('$label', label || 'field');
    }
    return '';
  };

  getCurrentMessages = () => {
    const { formMessages } = this.props;
    return { ...defaultFormMessages, ...formMessages };
  };

  getCurrentErrorsReset = () => {
    const errors = {};
    Object.keys(this.state).forEach(key => {
      if (key.search('Error') !== -1) {
        errors[key] = false;
      }
    });
    return errors;
  };

  getEventData = extraProps => ({
    valid: this.isFormValid(),
    data: this.getCurrentData(),
    ...extraProps
  });

  getCurrentData = () => {
    const data = {};
    this.formItems.forEach(fi => {
      data[fi.key] = FormItem.getDefaultValue(this.state[fi.key], fi.type);
    });
    return data;
  };

  isFormValid = () => {
    const { ...state } = this.state;
    const formItems = this.formItems;
    const inValidFormItem = formItems.find(
      fi =>
        validate({
          value: FormItem.getDefaultValue(state[fi.key], fi.type),
          validator: fi.validator,
          required: fi.required
        }) !== VALIDATE_TYPES.VALID
    );
    if (inValidFormItem) {
      return false;
    }
    return true;
  };

  forceFormValidation = () => {
    const { ...state } = this.state;
    const formItems = this.formItems;
    const newState = {};
    formItems.forEach(fi => {
      const valideType = validate({
        value: FormItem.getDefaultValue(state[fi.key], fi.type),
        validator: fi.validator,
        required: fi.required
      });
      newState[`${fi.key}Error`] =
        valideType === VALIDATE_TYPES.VALID ? false : valideType;
    });
    this.setState(newState);
  };

  onValidateField = (key, value, validator, required, onComplete) => {
    this.setState(
      {
        [`${key}Error`]: this.getKeyErrorByFormItem({
          value,
          validator,
          required
        })
      },
      onComplete
    );
  };

  getKeyErrorByFormItem = ({ value, validator, required }) => {
    const validType = validate({ value, validator, required });
    return validType === VALIDATE_TYPES.VALID ? false : validType;
  };

  onChange = ({ key, value, formItem }) => {
    const { onChange, edit = true } = this.props;
    if (edit) {
      let newState = { [key]: value };
      let keyError;
      if (this.state[`${key}Error`]) {
        // Clear error after onChange when it is valid
        keyError = this.getKeyErrorByFormItem({
          value: FormItem.getDefaultValue(value, formItem.type),
          validator: formItem.validator,
          required: formItem.required
        });
        if (!keyError) {
          newState[`${key}Error`] = false;
        }
      }
      this.setState(
        {
          ...newState,
          changed: true
        },
        () => {
          const eventData = this.getEventData({ formItem });
          this.setState({ valid: eventData.valid });
          onChange && onChange(eventData);
        }
      );
    }
  };

  onBlur = ({ key, value, formItem }) => {
    const { edit = true } = this.props;
    if (edit) {
      this.onValidateField(
        key,
        FormItem.getDefaultValue(value, formItem.type),
        formItem.validator,
        formItem.required,
        () => {
          const { onBlur } = this.props;
          const eventData = this.getEventData({ formItem });
          onBlur && onBlur(eventData);
        }
      );
    }
  };

  onEdit = () => {
    const { onEdit } = this.props;
    onEdit && onEdit();
  };

  onReset = e => {
    e && e.preventDefault();
    this.setState(
      {
        ...arrayToObject(this.formItems, 'key', 'value'),
        ...this.getCurrentErrorsReset(),
        changed: false
      },
      () => {
        const { onChange, onReset } = this.props;
        const eventData = this.getEventData();
        this.setState({ valid: eventData.valid });
        onChange && onChange(eventData);
        onReset && onReset();
      }
    );
  };

  onCancel = e => {
    e.preventDefault();
    const { onCancel, resetOnCancel = true } = this.props;
    if (resetOnCancel) {
      this.onReset();
    }
    onCancel && onCancel();
  };

  onSubmit = e => {
    e.preventDefault();
    this.submitHandler();
  };

  onFallbackSubmit = e => {
    e.preventDefault();
    this.submitHandler();
  };

  submitHandler = () => {
    const { onSubmit } = this.props;
    const { busy } = this.state;
    if (!busy) {
      if (this.isFormValid()) {
        onSubmit && onSubmit(this.getCurrentData());
        this.setState({ changed: false });
      } else {
        this.forceFormValidation();
      }
    }
  };

  forceTriggerChanged = () => {
    this.setState({ changed: true });
  };

  render() {
    const {
      edit = true,
      loading = false,
      resetOnCancel = true,
      enableSubmitOnValid = true,
      enableSubmitOnChange = false,
      autoPlaceholder = false,
      autoPlaceholderLowerCase = false,
      card,
      formItems,
      ...props
    } = this.props;

    const Wrapper = card ? Card : Fragment;
    return (
      <Wrapper>
        <form onSubmit={this.onFallbackSubmit}>
          <FormContext.Provider
            value={{
              ...props,
              edit,
              loading,
              addFormItem: this.addFormItem,
              removeFormItem: this.removeFormItem,
              updateFormItem: this.updateFormItem,
              busyFormItem: this.busyFormItem,
              getErrorMessage: this.getErrorMessage,
              onInit: this.onInit,
              onChange: this.onChange,
              onBlur: this.onBlur,
              onReset: this.onReset,
              onSubmit: this.onSubmit,
              onCancel: this.onCancel,
              onEdit: this.onEdit,
              resetOnCancel,
              enableSubmitOnValid: enableSubmitOnChange
                ? false
                : enableSubmitOnValid,
              enableSubmitOnChange,
              autoPlaceholder,
              autoPlaceholderLowerCase,
              ...this.state
            }}
          >
            {formItems &&
              formItems.length &&
              formItems.map(fi => <Form.Item key={fi.key} formItem={fi} />)}
            {this.props.children}
          </FormContext.Provider>
        </form>
      </Wrapper>
    );
  }
}

/*
KNOWN ISSUES:
- FormElement onChange doesn't trigger when onReset is done (see 'resetOnCancel')
  => use the onChange and onReset trigger on the Form or 
*/
