import React from 'react';
import classNames from 'classnames';

class PageContent extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <main
        className={classNames('c-page__content o-wrapper', {
          // 'c-page__content-centered': centered
        })}
      >
        <div className="o-row">{children}</div>
      </main>
    );
  }
}

export default PageContent;
