import React from 'react';
import classNames from 'classnames';

class HeaderTitle extends React.Component {
  render() {
    const { ...props } = this.props;
    return (
      <h1 className={classNames('c-page__title', {})}>{props.children}</h1>
    );
  }
}
export default HeaderTitle;
