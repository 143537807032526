import { doMutation } from './api';
import { MUTATION_UPDATE_FILE } from './graphql/file-query';

export const updater = async ({ file, onComplete = () => {} }) => {
  try {
    await updateFileMutation({
      label: file.label,
      fileId: file.id
    });
    onComplete && onComplete({ failedFiles: [], successFiles: [file] });
  } catch (error) {
    onComplete &&
      onComplete({
        failedFiles: [{ ...file, action: 'update' }],
        successFiles: []
      });
  }
};

export const updateFileMutation = async variables => {
  return new Promise(async (resolve, reject) => {
    await doMutation({
      mutation: MUTATION_UPDATE_FILE,
      variables,
      update: (cache, { data: { archiveFile } }) => {
        if (archiveFile === true) {
          resolve(archiveFile);
        }
      }
    });
  });
};
