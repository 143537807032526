import React, { useEffect, useContext } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { FormattedMessage, injectIntl } from 'react-intl';
import { StoresContext } from '../../../stores';
import { useFiles } from '../../../services/hooks/useFiles';
import { Row } from '../../../lib/components';
import { file } from '@dc-framework/js-utils';
import { uploader } from '../../../services/uploader';
import { deleter } from '../../../services/deleter';
import FileUploader from '../../../lib/containers/file-upload/FileUploader';
import { MODAL_TYPES } from '../../../models/ModalData';
import { defaultFormButtons } from '../../../lib/helpers/copy';
import messages from './messages';
import { updater } from '../../../services/updater';

const { getMimeTypesByPreset, MIME_TYPES_PRESETS } = file;

const FileManagerPage = ({ intl }) => {
  const { uiState } = useContext(StoresContext);
  useEffect(() => {
    uiState.setPageTitle('');
  });
  const { files, refetch } = useFiles();
  return (
    <>
      <div className="o-flex o-flex--align-center o-flex--justify-space-between">
        <h2 className="u-margin-none">
          <FormattedMessage {...messages.titleLabelFilemanager} />
        </h2>
      </div>
      <div className="u-margin-top-medium">
        <FileUploader
          extraClassNames="c-file-upload-manager"
          canEdit
          updater={updater}
          orderDropzone={-1}
          placeholder={intl.formatMessage(messages.fileManagerUploadFilesLabel)}
          value={files}
          multiple={true}
          uploader={uploader}
          deleter={deleter}
          isGallery={false}
          accept={getMimeTypesByPreset(MIME_TYPES_PRESETS.ALL_FILES)}
          onChange={() => refetch()}
          confirmModal={okHandler => {
            uiState.showModal({
              title: intl.formatMessage(
                messages.modalConfirmFileManagerRemoveFileTitle
              ),
              message: intl.formatMessage(
                messages.modalConfirmFileManagerRemoveFileMessage
              ),
              type: MODAL_TYPES.ALERT,
              okLabel: defaultFormButtons.REMOVE,
              okHandler
            });
          }}
        />
      </div>
    </>
  );
};
export default injectIntl(hooksObserver(FileManagerPage));
