import { injectIntl } from 'react-intl';
import React, { useContext } from 'react';
import { StoresContext } from '../../../stores';
import {
  MUTATION_REMOVE_CATEGORY,
  QUERY_GET_CATEGORIES,
} from '../../../services/graphql/categories-query';
import { Button, ButtonsGroup, Icon } from '../../../lib/components';
import svgUrl from '../../../assets/images/svg-symbols.svg';
import { defaultFormButtons } from '../../../lib/helpers';
import ConfirmButton from '../../../lib/containers/modal/ConfirmButton';
import messages from '../../pages/categories/messages';

const ButtonsGroupForCategories = injectIntl(
  ({
    category,
    setEdit,
    edit,
    setReorder,
    reorder,
    onEdit,
    onReorder,
    onUpdate,
    onCancel,
    intl,
  }) => {
    const { routing, uiState } = useContext(StoresContext);
    const queryProps = {
      query: QUERY_GET_CATEGORIES,
    };
    return (
      <ButtonsGroup
        right
        style={edit ? { right: 16 } : {}}
        extraClassNames="u-margin-left-medium"
      >
        {!reorder && (
          <Button
            small
            actionPrimary
            icon
            extraClassNames="u-margin-vertical-none"
            onClick={() => {
              setEdit(!edit);
              if (edit) {
                onUpdate();
              } else {
                onEdit();
              }
            }}
            style={edit || reorder ? { opacity: 1 } : {}}
          >
            {edit ? (
              <Icon
                svgLocalPath={`${svgUrl}#checkmark`}
                title={defaultFormButtons.SAVE}
              />
            ) : (
              <Icon id="edit" title={defaultFormButtons.EDIT} />
            )}
          </Button>
        )}
        {!edit && setReorder && (
          <Button
            small
            actionPrimary
            icon
            extraClassNames="u-margin-vertical-none"
            onClick={() => {
              setReorder(!edit);
              if (reorder) {
                onReorder();
              }
            }}
            style={edit || reorder ? { opacity: 1 } : {}}
          >
            {reorder ? (
              <Icon
                svgLocalPath={`${svgUrl}#checkmark`}
                title={defaultFormButtons.SAVE}
              />
            ) : (
              <Icon
                svgLocalPath={`${svgUrl}#reorder`}
                title={defaultFormButtons.EDIT}
              />
            )}
          </Button>
        )}
        {(reorder || edit) && (
          <Button
            small
            actionPrimary
            icon
            extraClassNames="u-margin-vertical-none"
            onClick={() => {
              if (edit) {
                setEdit(false);
              }
              if (reorder) {
                setReorder(false);
              }
              if (onCancel) {
                onCancel();
              }
            }}
            style={edit || reorder ? { opacity: 1 } : {}}
          >
            <Icon
              svgLocalPath={`${svgUrl}#cancel`}
              title={defaultFormButtons.EDIT}
              small
            />
          </Button>
        )}
        <ConfirmButton
          small
          actionAlert
          icon
          extraClassNames="u-margin-vertical-none"
          mutation={MUTATION_REMOVE_CATEGORY}
          refetchQueries={[queryProps]}
          variables={{ categoryId: category.id }}
          confirmTitle={intl.formatMessage(
            messages.modalConfirmRemoveCategoryTitle
          )}
          confirmMessage={intl.formatMessage(
            messages.modalConfirmRemoveCategoryMessage
          )}
          update={(cache, { data }) => {
            if (data && data.archiveTag) {
              uiState.showNotification({
                message: intl.formatMessage(
                  messages.notificationMessageCategoryRemoved
                ),
              });
            }
          }}
          style={edit || reorder ? { opacity: 1 } : {}}
        >
          <Icon id="remove" title={defaultFormButtons.DELETE} />
        </ConfirmButton>
      </ButtonsGroup>
    );
  }
);
export default ButtonsGroupForCategories;
