import React, { useContext } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { useGroupTree } from '../../../services/hooks/useGroupTree';
import { StoresContext } from '../../../stores/contexts';
import { List } from '../../../lib/components/list/List';
import { ListItem } from '../../../lib/components/list/list-item/ListItem';
import { Checkbox } from '../../../lib/components/input-checkbox-radiobutton/index';
import { defaultCardLabels } from '../../../lib/helpers/copy/default-copy';
import Loader from '../../../components/loader/Loader';

const CardTree = ({ value, onChange, readOnly, ignoreTopicSlug }) => {
  const { authStore } = useContext(StoresContext);
  const { groupTree, loading, error } = useGroupTree(
    authStore.user.rootEntityId
  );
  const cardEntities = value || [];
  const isTopicChecked = topicId => {
    return cardEntities.find(c => c.entityId === topicId) ? true : false;
  };

  const onCheckUpdate = (id, slug) => {
    let newValue;
    if (cardEntities.find(c => c.entityId === id)) {
      newValue = cardEntities.filter(cd => cd.entityId !== id);
    } else {
      newValue = [...cardEntities, { slug, entityId: id }];
    }
    onChange && onChange(newValue);
  };

  if (loading) return <Loader />;
  if (error) return <>{`Error! ${error.message}`}</>;

  const filteredGroupTree = groupTree.filter(gt => gt.topics.length);
  return (
    <>
      <List extraClassNames="c-list--groups">
        {filteredGroupTree.map(gt => {
          return (
            <ListItem key={gt.id}>
              <div className="c-list__group-name">{gt.name}</div>

              <div className="c-list__topics">
                {gt.topics.map(t => (
                  <Checkbox
                    key={t.id}
                    name={'topic'}
                    id={t.id}
                    onChange={() => onCheckUpdate(t.id, t.slug)}
                    checked={isTopicChecked(t.id)}
                    disabled={readOnly || t.slug === ignoreTopicSlug}
                  >
                    {t.name}
                  </Checkbox>
                ))}
              </div>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default hooksObserver(CardTree);
