export const getGQLQueryResult = async (
  client,
  { query, variables, fetchPolicy = 'network-only', ...otherProps }
) => {
  const result = await client.query({
    query,
    variables,
    fetchPolicy,
    ...otherProps
  });
  return result;
};
