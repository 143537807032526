import React, { useContext, useEffect, useState } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { useQuery } from 'react-apollo-hooks';
import { array, date } from '@dc-framework/js-utils';
import { FormattedMessage, injectIntl } from 'react-intl';
import { StoresContext } from '../../../../../stores';
import {
  defaultFormButtons,
  defaultCardLabels
} from '../../../../../lib/helpers/copy/default-copy';
import { Button } from '../../../../../lib/components/button/Button';
import {
  QUERY_GET_CARDS_BY_TOPIC_ID,
  MUTATION_REMOVE_CARD_ENTITY,
  updateCardsOrder
} from '../../../../../services/graphql/card-query';
import {
  ROUTE_TOPICS,
  ROUTE_SLUG_CARD_ADD,
  ROUTE_SLUG_CARD_EDIT,
  ROUTE_SLUG_CARDS,
  ROUTE_SLUG_CARD
} from '../../../../../routes/RouteList';
import { List, ListItem } from '../../../../../lib/components/list';
import { Link } from '../../../../../lib/components/link';
import { ButtonsGroup } from '../../../../../lib/components/button/ButtonsGroup';
import {
  Card,
  CardHeader,
  CardThumbnail,
  CardBody,
  CardContent,
  CardActions
} from '../../../../../lib/components/card';
import { DndWrapper } from '../../../../../lib/containers/dnd';
import CardModel from '../../../../../models/Card';
import { getPath } from '../../path-generator';
import messages from './messages';
import { Row } from '../../../../../lib/components/page-layout';
import Icon from '../../../../../lib/components/icon/Icon';
import { useTopicName } from './useTopicName';
import mainMessages from '../../../../../messages';
import ConfirmButton from '../../../../../lib/containers/modal/ConfirmButton';
import { Tag } from '../../../../../lib/components/input-tag';
import Image from '../../../../../lib/components/image/Image';
import Breadcrumb from '../../../../../lib/components/breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../../../lib/components/breadcrumb/BreadcrumbItem';
import { Notification } from '../../../../../lib/components/notification';
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionContent
} from '../../../../../lib/components/accordion';
import Loader from '../../../../../components/loader/Loader';

const { sort, SORT_DATA_TYPES } = array;
const { getLocalDateFormat } = date;

const Cards = ({ intl, groupSlug, topicSlug }) => {
  const { routing, uiState } = useContext(StoresContext);
  const { name } = useTopicName(topicSlug);
  useEffect(() => uiState.setPageTitle(name));

  const [cards, setCards] = useState([]);
  const queryProps = {
    query: QUERY_GET_CARDS_BY_TOPIC_ID,
    variables: { entitySlug: groupSlug, subEntitySlug: topicSlug }
  };
  const { data, loading, error } = useQuery(queryProps.query, {
    variables: queryProps.variables
  });

  useEffect(() => {
    let rawCards = data && data.getCardsBySlug && data.getCardsBySlug;
    if (rawCards && rawCards.length) {
      rawCards = sort(rawCards, {
        keys: [{ key: 'order', dataType: SORT_DATA_TYPES.NUMBER }]
      });
    } else {
      rawCards = [];
    }
    setCards(rawCards.map(c => new CardModel(c)));
  }, [data]);

  if (loading) return <Loader />;
  if (error) return <>{`Error! ${error.message}`}</>;

  const isFutureDate = date => {
    return new Date(date).getTime() > new Date().getTime();
  };

  return (
    <>
      <div className="o-flex o-flex--wrap o-flex--align-center o-flex--justify-space-between">
        <h2 className="u-margin-vertical-medium u-h4">
          <FormattedMessage {...messages.titleLabelCards} /> in topic:{' '}
          <span className="c-page__title c-page__title--primary-dark">
            {topicSlug}
          </span>
        </h2>
        <ButtonsGroup>
          <Button
            ghost
            iconLeft
            onClick={() =>
              routing.push(getPath(ROUTE_TOPICS, groupSlug, topicSlug))
            }
          >
            <Icon id="left" title={defaultFormButtons.BACK} />
            {defaultFormButtons.BACK}
          </Button>
          <Button
            primary
            onClick={() =>
              routing.push(getPath(ROUTE_SLUG_CARD_ADD, groupSlug, topicSlug))
            }
          >
            <FormattedMessage {...messages.titleLabelAddCard} />
          </Button>
        </ButtonsGroup>
      </div>
      <Row>
        {cards.length !== 0 && (
          <List extraClassNames={'o-layout'} draggable>
            <DndWrapper
              type="card"
              items={cards}
              onDragUpdate={items => {
                items.forEach((item, i) => {
                  item.order = i;
                });
                setCards(items);
              }}
              onDragEnd={async () => {
                await updateCardsOrder(
                  {
                    sortItemInput: cards.map(i => ({
                      id: i.cardEntity.id,
                      order: i.order
                    }))
                  },
                  [queryProps]
                );
              }}
              dropComponent={() => (
                <Card horizontal empty>
                  <CardBody>
                    <FormattedMessage {...mainMessages.listDragDropHereLabel} />
                  </CardBody>
                </Card>
              )}
              placeholderOpacity={1}
            >
              {cards.map((c, i) => (
                <ListItem
                  key={c.id}
                  extraClassNames={
                    'o-layout__item u-1-of-2-at-small u-1-of-1-at-medium o-flex'
                  }
                >
                  <DndWrapper.Item id={c.id} index={i}>
                    <Card
                      horizontal
                      draggable
                      published={
                        c.date_published && !isFutureDate(c.date_published)
                      }
                      unpublished={
                        !c.date_published || isFutureDate(c.date_published)
                      }
                    >
                      <CardActions>
                        <div className="c-card__metadata">
                          {c.date_published && (
                            <span className="c-card__date">
                              {isFutureDate(c.date_published)
                                ? intl.formatMessage(
                                    mainMessages.cardLabelPublishOn,
                                    {
                                      date: getLocalDateFormat(c.date_published)
                                    }
                                  )
                                : intl.formatMessage(
                                    mainMessages.cardLabelPublishedOn,
                                    {
                                      date: getLocalDateFormat(c.date_published)
                                    }
                                  )}
                            </span>
                          )}
                          {c.private ? (
                            <span className="c-card__badge">
                              {intl.formatMessage(
                                mainMessages.cardLabelPrivate
                              )}
                            </span>
                          ) : (
                            <span className="c-card__badge c-card__badge-public">
                              {intl.formatMessage(mainMessages.cardLabelPublic)}
                            </span>
                          )}
                        </div>
                        <ButtonsGroup>
                          <Link
                            buttonSmallActionPrimaryIcon
                            to={getPath(
                              ROUTE_SLUG_CARD_EDIT,
                              groupSlug,
                              topicSlug,
                              c.slug
                            )}
                          >
                            <Icon id="edit" title="Edit" />
                          </Link>
                          <ConfirmButton
                            small
                            actionAlert
                            icon
                            mutation={MUTATION_REMOVE_CARD_ENTITY}
                            refetchQueries={[queryProps]}
                            variables={{
                              cardId: c.id,
                              entitySlug: topicSlug
                            }}
                            confirmTitle={intl.formatMessage(
                              messages.modalConfirmRemoveCardTitle
                            )}
                            confirmMessage={intl.formatMessage(
                              messages.modalConfirmRemoveCardMessage
                            )}
                            update={(cache, { data }) => {
                              if (data && data.archiveCardEntity) {
                                uiState.showNotification({
                                  message: intl.formatMessage(
                                    messages.notificationMessageCardRemoved
                                  )
                                });
                              }
                            }}
                          >
                            <Icon
                              id="remove"
                              title={defaultFormButtons.DELETE}
                            />
                          </ConfirmButton>
                        </ButtonsGroup>
                      </CardActions>
                      <CardHeader>
                        <Link
                          extraClassNames="c-link--images"
                          to={getPath(
                            ROUTE_SLUG_CARD_EDIT,
                            groupSlug,
                            topicSlug,
                            c.slug
                          )}
                        >
                          {c.thumbnail ? (
                            <CardThumbnail
                              src={c.thumbnail}
                              alt="This is the main image"
                            />
                          ) : (
                            <Image placeholder />
                          )}
                        </Link>
                      </CardHeader>
                      <CardBody>
                        <CardContent>
                          {c.tags.length > 0 && (
                            <div className="c-card__tags u-margin-vertical-tiny">
                              {c.tags.map(tag => (
                                <Tag key={tag.id}>{tag.name}</Tag>
                              ))}
                            </div>
                          )}
                          <h3 className="c-card__title u-margin-top-small">
                            <Link
                              to={getPath(
                                ROUTE_SLUG_CARD_EDIT,
                                groupSlug,
                                topicSlug,
                                c.slug
                              )}
                            >
                              {c.title}
                            </Link>
                          </h3>
                          <p className="c-card__description">{c.description}</p>
                          {c.cardEntities.length > 0 && (
                            <Accordion>
                              <AccordionItem>
                                <div className="c-breadcrumbs__wrapper">
                                  <AccordionHeader>
                                    <div className="c-breadcrumbs__wrapper-title">
                                      Included in:
                                    </div>
                                  </AccordionHeader>
                                  <AccordionContent>
                                    {c.cardEntities &&
                                      c.cardEntities.map(
                                        ce =>
                                          ce.breadcrumb &&
                                          ce.breadcrumb.length === 3 && (
                                            <Breadcrumb key={ce.id}>
                                              <BreadcrumbItem
                                                title={`Group: ${
                                                  ce.breadcrumb[0]
                                                }`}
                                                to={getPath(
                                                  ROUTE_TOPICS,
                                                  ce.breadcrumb[0]
                                                )}
                                              >
                                                {ce.breadcrumb[0]}
                                              </BreadcrumbItem>
                                              <BreadcrumbItem
                                                title={`Topic: ${
                                                  ce.breadcrumb[1]
                                                }`}
                                                to={getPath(
                                                  ROUTE_SLUG_CARDS,
                                                  ce.breadcrumb[0],
                                                  ce.breadcrumb[1]
                                                )}
                                              >
                                                {ce.breadcrumb[1]}
                                              </BreadcrumbItem>
                                            </Breadcrumb>
                                          )
                                      )}
                                  </AccordionContent>
                                </div>
                              </AccordionItem>
                            </Accordion>
                          )}
                        </CardContent>
                        {/* <CardFooter /> */}
                      </CardBody>
                      <Notification views show>
                        <Icon id="eye-thin" primary />
                        <div className="c-notification__label">
                          <span className="c-notification__main-label">
                            {c.cardEntities.length > 0
                              ? c.cardEntities.reduce(
                                  (accumulator, currentValue) => {
                                    return accumulator + currentValue.views;
                                  },
                                  0
                                )
                              : 0}
                          </span>
                          <span>views</span>
                        </div>
                      </Notification>
                    </Card>
                    <DndWrapper.Grabzone>
                      <Icon id="drag" small secondary />
                    </DndWrapper.Grabzone>
                  </DndWrapper.Item>
                </ListItem>
              ))}
            </DndWrapper>
          </List>
        )}
      </Row>
    </>
  );
};

export default injectIntl(hooksObserver(Cards));
