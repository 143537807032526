import React from 'react';
import classNames from 'classnames';
import { Link as ReactRouterLink } from 'react-router-dom';

const BreadcrumbItem = ({ children, to, ...props }) => (
  <ReactRouterLink
    {...props}
    className={classNames('c-breadcrumb__item', {})}
    to={to}
  >
    {children}
  </ReactRouterLink>
);
export default BreadcrumbItem;
