import { defineMessages } from 'react-intl';

export default defineMessages({
  listDragDropHereLabel: {
    id: 'list.drag.drop.here.label',
    defaultMessage: 'Drop here!'
  },
  formLabelThumbnail: {
    id: 'form.label.thumbnail',
    defaultMessage: 'Thumbnail'
  },
  formLabelName: {
    id: 'form.label.name',
    defaultMessage: 'Name'
  },
  formLabelStatus: {
    id: 'form.label.status',
    defaultMessage: 'Is private'
  },
  formLabelTags: {
    id: 'form.label.tags',
    defaultMessage: 'Tags'
  },
  formLabelAliases: {
    id: 'form.label.aliases',
    defaultMessage: 'Aliases (max {maxLength} chars)'
  },
  formLabelDescription: {
    id: 'form.label.description',
    defaultMessage: 'Description (max {maxLength} chars)'
  },
  formLabelPublishDate: {
    id: 'form.label.publish.date',
    defaultMessage: 'Publish date'
  },
  cardLabelPublishOn: {
    id: 'card.label.publish.on',
    defaultMessage: 'Will publish on {date}'
  },
  cardLabelPublishedOn: {
    id: 'card.label.published.on',
    defaultMessage: 'Published on {date}'
  },
  cardLabelPrivate: {
    id: 'card.label.private',
    defaultMessage: 'Private'
  },
  cardLabelPublic: {
    id: 'card.label.public',
    defaultMessage: 'Public'
  }
});
