import React, { Component } from 'react';
import { FileUpload } from '../../components/file-upload';
import { Notification } from '../../components/notification';
import { defaultFormMessages } from '../../helpers/copy';

export default class FileUploader extends Component {
  state = {
    uploading: false,
    uploadingPercentage: null,
    failedFiles: [],
    selectedFiles: []
  };
  onDrop = async files => {
    const { uploader, onBusy } = this.props;
    this.setState({ failedFiles: [] });
    uploader && onBusy && onBusy(true);
    uploader &&
      uploader({
        files,
        onProgress: this.onProgress,
        onComplete: this.onComplete,
        onFileComplete: this.onFileComplete
      });
  };
  onProgress = ({
    totalFiles,
    loadedFiles,
    currentProgress,
    totalProgress
  }) => {
    this.setState({ uploading: true, uploadingPercentage: totalProgress });
  };

  onFileComplete = file => {
    if (!file.error) {
      const { onChange, value, multiple } = this.props;
      const newValue = multiple ? [...value, file] : [file];
      onChange && onChange(newValue);
    } else {
      const { failedFiles } = this.state;
      this.setState({ failedFiles: [...failedFiles, file] });
    }
  };

  onComplete = ({ successFiles, failedFiles }) => {
    const { onBusy } = this.props;
    this.setState({ uploading: false, uploadingPercentage: null });
    onBusy && onBusy(false);
  };

  onUpdateFile = (file, data) => {
    const { onBusy, updater } = this.props;
    this.setState({ failedFiles: [] });
    if (updater) {
      onBusy && onBusy(true);
      updater({
        file: { ...file, ...data },
        onComplete: ({ successFiles, failedFiles }) => {
          onBusy && onBusy(false);
          if (successFiles.length) {
            this.onUpdateComplete(file, data);
          } else {
            this.setState({ failedFiles: [file] });
          }
        }
      });
    } else {
      this.onUpdateComplete(file, data);
    }
  };

  onDeleteFile = file => {
    const { confirmModal } = this.props;
    if (confirmModal) {
      confirmModal(() => {
        this.doFileDelete(file);
      });
    } else {
      this.doFileDelete(file);
    }
  };

  doFileDelete = file => {
    const { onBusy, deleter } = this.props;
    this.setState({ failedFiles: [] });
    if (deleter) {
      onBusy && onBusy(true);
      deleter({
        file,
        onComplete: ({ successFiles, failedFiles }) => {
          onBusy && onBusy(false);
          if (successFiles.length) {
            this.onDeleteComplete(file);
          } else {
            this.setState({ failedFiles: [file] });
          }
        }
      });
    } else {
      this.onDeleteComplete(file);
    }
  };

  onDeleteComplete = file => {
    const { onChange, value } = this.props;
    const newValue = [...value.filter(v => v.id !== file.id)];
    onChange && onChange(newValue);
  };

  onUpdateComplete = (file, data) => {
    const { onChange, value } = this.props;
    file = Object.assign(file, data);
    const newValue = [...value];
    onChange && onChange(newValue);
  };

  onFileSelect = file => {
    const { onFileSelectChange, multiple } = this.props;
    const { selectedFiles } = this.state;
    let newSelectedFiles;
    if (multiple) {
      if (!selectedFiles.find(f => f.id === file.id)) {
        newSelectedFiles = [...selectedFiles, file];
      } else {
        newSelectedFiles = selectedFiles.filter(f => f.id !== file.id);
      }
    } else {
      newSelectedFiles = [file];
    }

    this.setState(
      {
        selectedFiles: newSelectedFiles
      },
      () => {
        onFileSelectChange && onFileSelectChange(newSelectedFiles);
      }
    );
  };

  render() {
    const { value, ...props } = this.props;
    const {
      uploading,
      uploadingPercentage,
      failedFiles,
      selectedFiles
    } = this.state;
    return (
      <>
        <FileUpload
          {...props}
          onDrop={this.onDrop}
          onDeleteFile={this.onDeleteFile}
          onUpdateFile={this.onUpdateFile}
          onFileSelect={this.onFileSelect}
          files={value}
          selectedFiles={selectedFiles}
          uploading={uploading}
          uploadingPercentage={uploadingPercentage}
        />
        {failedFiles.length !== 0 && (
          <Notification errorMessage show>
            {failedFiles.map(ff =>
              defaultFormMessages[
                `ERROR_FILE_${
                  ff.action ? ff.action.toUpperCase() : 'UPLOAD'
                }_FAILED`
              ].replace('$filename', ff.label || ff.filename || ff.name)
            )}
          </Notification>
        )}
      </>
    );
  }
}
