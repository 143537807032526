import React from 'react';

import classNames from 'classnames';

class CardFooter extends React.Component {
  render() {
    const { children } = this.props;
    return <div className={classNames('c-card__footer')}>{children}</div>;
  }
}

export default CardFooter;
