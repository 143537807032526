import React, { useContext } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { StoresContext } from '../../../stores';
import { useQuery } from 'react-apollo-hooks';
import { QUERY_GET_PERSONS_BY_ENTITY_ID } from '../../../services/graphql';
import { Button, ButtonsGroup } from '../../../lib/components/button';
import { array } from '@dc-framework/js-utils';
import { ROUTE_USERS_ADD, ROUTE_USERS_EDIT } from '../../../routes/RouteList';
import {
  defaultFormButtons,
  defaultCardLabels
} from '../../../lib/helpers/copy/default-copy';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from './messages';
import { MUTATION_REMOVE_PERSON } from '../../../services/graphql/user-query';
import User from '../../../models/User';
import { Row } from '../../../lib/components/page-layout';
import { List, ListItem } from '../../../lib/components/list';
import Icon from '../../../lib/components/icon/Icon';
import ConfirmButton from '../../../lib/containers/modal/ConfirmButton';
import Loader from '../../../components/loader/Loader';

const { sort } = array;

const Users = ({ intl }) => {
  const { authStore, routing, uiState } = useContext(StoresContext);
  const entityId = authStore.user.rootEntityId;
  const queryProps = {
    query: QUERY_GET_PERSONS_BY_ENTITY_ID,
    variables: { entityId }
  };
  const { data, error, loading } = useQuery(queryProps.query, {
    variables: queryProps.variables
  });
  if (loading) return <Loader />;
  if (error) return <>{`Error! ${error.message}`}</>;

  let users = data && data.getEntity && data.getEntity.personEntities;
  if (users && users.length) {
    const persons = users.map(
      pe => new User({ ...pe.person, personEntityId: pe.id })
    );
    users = sort(persons, {
      keys: [{ key: 'firstname' }, { key: 'lastname' }]
    });
  } else {
    users = [];
  }
  return (
    <>
      <div className="o-flex o-flex--align-center o-flex--justify-space-between">
        <h2 className="u-margin-none">
          <FormattedMessage {...messages.titleLabelUsers} />
        </h2>
        <Button primary onClick={() => routing.push(ROUTE_USERS_ADD)}>
          <FormattedMessage {...messages.titleLabelAddUser} />
        </Button>
      </div>
      <Row>
        {users && (
          <List stripped extraClassNames="o-layout c-list--stripped-2-col">
            {users.map(u => (
              <ListItem
                key={u.id}
                extraClassNames={'o-layout__item u-1-of-2-at-medium'}
              >
                <span className="u-2-of-3">{`${u.firstname} ${
                  u.lastname
                }`}</span>
                <ButtonsGroup right extraClassNames="o-layout__item u-1-of-3">
                  <Button
                    small
                    actionPrimary
                    icon
                    onClick={() =>
                      routing.push(ROUTE_USERS_EDIT.replace(':personId', u.id))
                    }
                  >
                    <Icon id="edit" title={defaultFormButtons.EDIT} />
                  </Button>
                  <ConfirmButton
                    small
                    actionAlert
                    icon
                    mutation={MUTATION_REMOVE_PERSON}
                    refetchQueries={[queryProps]}
                    update={(cache, { data }) => {
                      if (data && data.archivePersonEntity) {
                        uiState.showNotification({
                          message: intl.formatMessage(
                            messages.notificationMessageUserRemoved
                          )
                        });
                      }
                    }}
                    variables={{ personEntityId: u.personEntityId }}
                    confirmTitle={intl.formatMessage(
                      messages.modalConfirmRemoveUserTitle
                    )}
                    confirmMessage={intl.formatMessage(
                      messages.modalConfirmRemoveUserMessage
                    )}
                  >
                    <Icon id="remove" title={defaultFormButtons.DELETE} />
                  </ConfirmButton>
                </ButtonsGroup>
              </ListItem>
            ))}
          </List>
        )}
      </Row>
    </>
  );
};
export default injectIntl(hooksObserver(Users));
