import React from 'react';
import { injectIntl } from 'react-intl';
import { inject } from 'mobx-react';
import FileUploader from '../../../../lib/containers/file-upload/FileUploader';
import { uploader } from '../../../../services/uploader';

import messages from '../messages';
import { file } from '@dc-framework/js-utils';
import CardItemFile from '../../../../models/CardItemFile';
import File, { FILE_TYPES } from '../../../../models/File';
import { defaultFormButtons } from '../../../../lib/helpers/copy';
import { MODAL_TYPES } from '../../../../models/ModalData';

const { MIME_TYPES_PRESETS, getMimeTypesByPreset } = file;

export const ContentBlockImage = injectIntl(
  inject('rootStore')(
    ({
      intl,
      contentBlock,
      onChange,
      onBrowseFileManager,
      rootStore: { uiState },
      disabled
    }) => {
      return (
        <>
          <FileUploader
            isGallery
            disabled={disabled}
            fileUploadIcon="image"
            id={contentBlock.id}
            placeholder={
              contentBlock.label
                ? contentBlock.label
                : intl.formatMessage(messages.articleEditorImagesPlaceholder)
            }
            label={
              contentBlock.label
                ? contentBlock.label
                : intl.formatMessage(messages.articleEditorImagesLabel)
            }
            value={contentBlock.files.map(v => new File(v.file))}
            multiple={true}
            uploader={uploader}
            labelFileManager={intl.formatMessage(
              messages.articleEditorFilesImageLabel
            )}
            onBrowseFileManager={() => {
              onBrowseFileManager && onBrowseFileManager(contentBlock);
            }}
            accept={getMimeTypesByPreset(MIME_TYPES_PRESETS.IMAGE)}
            onChange={e => {
              const files = e.map(file => {
                const currentCardItemFile = contentBlock.files.find(
                  cardItemFile => cardItemFile.file.id === file.id
                );
                if (currentCardItemFile) return currentCardItemFile;
                return new CardItemFile({
                  file,
                  fileId: file.id,
                  type: FILE_TYPES.IMAGE
                });
              });
              contentBlock.files = files;
              onChange && onChange(contentBlock);
            }}
            confirmModal={okHandler => {
              uiState.showModal({
                title: intl.formatMessage(messages.modalConfirmRemoveFileTitle),
                message: intl.formatMessage(
                  messages.modalConfirmRemoveFileMessage
                ),
                type: MODAL_TYPES.ALERT,
                okLabel: defaultFormButtons.REMOVE,
                okHandler
              });
            }}
            noLabelDropzone
          />
        </>
      );
    }
  )
);
