import React, { useContext } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { StoresContext } from '../../../stores';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

import { ROUTE_GROUPS } from '../../../routes/RouteList';
import GroupForm from './GroupForm';
import { refetchGroups } from '../../../services/graphql';

const AddGroup = () => {
  const { authStore, routing } = useContext(StoresContext);
  const rootEntityId = authStore.user.rootEntityId;
  const goBack = () => routing.push(ROUTE_GROUPS);
  return (
    <>
      <div className="o-layout">
        <div className="o-layout__item u-1-of-5-at-medium">
          <h2 className="u-margin-bottom-medium">
            <FormattedMessage {...messages.titleLabelAddGroup} />
          </h2>
        </div>
        <div className="o-layout__item u-4-of-5-at-medium">
          <GroupForm
            rootEntityId={rootEntityId}
            onMutationComplete={async (data, hasToRefetch) => {
              if (hasToRefetch) {
                await refetchGroups(rootEntityId);
              }
              goBack();
            }}
            onCancel={goBack}
          />
        </div>
      </div>
    </>
  );
};
export default hooksObserver(AddGroup);
