import React from 'react';
import { Provider, observer } from 'mobx-react';
import { IntlProvider } from 'react-intl';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import { syncHistoryWithStore } from 'mobx-react-router';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { RootStore, StoresContext } from '../../../stores';
import IntlWrapper from './IntlWrapper';
import AppModalWrapper from './AppModalWrapper';
import AppNotificationWrapper from './AppNotificationWrapper';

const AppContainer = ({
  rootStore = new RootStore(),
  children,
  translationMessages
}) => {
  const { locale } = rootStore.uiState;
  const browserHistory = createBrowserHistory();
  const history = syncHistoryWithStore(browserHistory, rootStore.routing);
  return (
    <Provider rootStore={rootStore}>
      <StoresContext.Provider value={rootStore}>
        <IntlProvider locale={locale} messages={translationMessages[locale]}>
          <IntlWrapper>
            <Router history={history}>
              <DragDropContextProvider backend={HTML5Backend}>
                {children}
                <AppModalWrapper />
                <AppNotificationWrapper />
              </DragDropContextProvider>
            </Router>
          </IntlWrapper>
        </IntlProvider>
      </StoresContext.Provider>
    </Provider>
  );
};

export default observer(AppContainer);
