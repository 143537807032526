import React from 'react';
import { Mutation } from 'react-apollo';
import Modal from '../../components/modal/Modal';
import { ButtonsGroup, Button } from '../../components/button';
import { defaultFormButtons } from '../../helpers/copy/default-copy';
import { CardBody, CardFooter, CardHeader } from '../../components/card';
import { MODAL_TYPES } from '../../../models/ModalData';

class BasicModal extends React.Component {
  render() {
    const {
      modalData: {
        title,
        message,
        okLabel,
        okHandler,
        dismissButton,
        dismissHandler,
        dismissLabel,
        mutationData,
        type
      }
    } = this.props;
    return (
      <Modal small isOpen={true} onClose={dismissHandler}>
        <CardHeader>
          <h3>{title}</h3>
        </CardHeader>
        <CardBody>
          <p>{message}</p>
        </CardBody>
        <CardFooter>
          <ButtonsGroup>
            {mutationData ? (
              <Mutation {...mutationData}>
                {mutation => (
                  <OKButton
                    type={type}
                    okLabel={okLabel}
                    onClick={() => {
                      okHandler();
                      mutation(mutationData.variables);
                    }}
                  />
                )}
              </Mutation>
            ) : (
              <OKButton
                type={type}
                okLabel={okLabel}
                onClick={() => {
                  okHandler();
                }}
              />
            )}
            {dismissButton && (
              <Button type="button" secondary onClick={dismissHandler}>
                {dismissLabel ? dismissLabel : defaultFormButtons.CANCEL}
              </Button>
            )}
          </ButtonsGroup>
        </CardFooter>
      </Modal>
    );
  }
}

const OKButton = ({ type, okLabel, onClick }) => (
  <Button
    type="button"
    primary={type === MODAL_TYPES.WARNING}
    alert={type === MODAL_TYPES.ALERT}
    onClick={onClick}
  >
    {okLabel}
  </Button>
);

export default BasicModal;
