import React from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { Switch, Route } from 'react-router-dom';
import {
  ROUTE_CARDS,
  ROUTE_CARD_ADD,
  ROUTE_CARD_EDIT,
  ROUTE_CARD
} from '../../../routes/RouteList';
import Cards from './Cards';
import AddCard from './AddCard';
import Card from './Card';

const CardsPage = () => {
  return (
    <Switch>
      <Route path={ROUTE_CARD_ADD} component={props => <AddCard />} />
      <Route
        path={ROUTE_CARD_EDIT}
        component={props => (
          <Card edit={true} cardSlug={props.match.params.cardSlug} />
        )}
      />
      <Route
        path={ROUTE_CARD}
        component={props => (
          <Card edit={false} cardSlug={props.match.params.cardSlug} />
        )}
      />
      <Route path={ROUTE_CARDS} component={props => <Cards />} />
    </Switch>
  );
};
export default hooksObserver(CardsPage);
