import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import AppRoute from './routes/AppRoute';
import { PageLayout, PageContent } from './lib/components/page-layout';
import Header from './containers/partials/header/Header';

/**
 * Entry point of the application
 * @class
 */
class App extends Component {
  render() {
    const {
      rootStore: { authStore }
    } = this.props;
    const PageWrapper = authStore.isLoggedIn ? PageContent : Fragment;
    return (
      <PageLayout oneElementFullyCentered={!authStore.isLoggedIn}>
        {authStore.isLoggedIn && <Header />}
        <PageWrapper>
          <AppRoute />
        </PageWrapper>
      </PageLayout>
    );
  }
}

// IMPORTANT!!!! withRouter before observer !!!!!
export default withRouter(inject('rootStore')(observer(App)));
