import React from 'react';
import { injectIntl } from 'react-intl';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ckEditorConfig } from '../../../../constants';
import messages from '../messages';

export const ContentBlockText = injectIntl(
  ({ intl, contentBlock, onChange, disabled }) => (
    <>
      <p className={'c-input__label c-input-ckeditor u-margin-bottom-medium'}>
        {contentBlock.label
          ? contentBlock.label
          : intl.formatMessage(messages.articleEditorLabelTextEditor)}
      </p>
      <CKEditor
        disabled={disabled}
        editor={ClassicEditor}
        config={ckEditorConfig}
        data={contentBlock.text}
        onChange={(e, editor) => {
          contentBlock.text = editor.getData();
          onChange && onChange(contentBlock);
        }}
      />
    </>
  )
);
