import React, { useRef, useImperativeHandle } from 'react';
import { DropTarget, DragSource } from 'react-dnd';
import './dnd.scss';
import classNames from 'classnames';

const DragAndDropContext = React.createContext();

const useToggleContext = () => {
  const context = React.useContext(DragAndDropContext);
  return context;
};

export const Grabzone = ({ children }) => {
  const { refDragSource } = useToggleContext();
  return (
    <div ref={refDragSource} className={'c-button c-button--dragger'}>
      {children ? children : 'Dragzone'}
    </div>
  );
};

export const DefaultDraggableItem = ({
  Component,
  parentStyle,
  placeholderOpacity,
  DropComponent
}) =>
  React.forwardRef(
    (
      {
        isDragging,
        connectDragSource,
        connectDragPreview,
        connectDropTarget,
        extraClassNames,
        children,
        ...props
      },
      ref
    ) => {
      const refDragSource = useRef(null);
      const refDragTarget = useRef(null);
      /*refDragSource.current
        ? connectDragSource(refDragSource)
        : connectDragSource(refDragTarget);*/
      connectDragSource(refDragSource);
      connectDropTarget(refDragTarget);
      connectDragPreview(refDragTarget);

      const opacity = isDragging
        ? placeholderOpacity !== undefined
          ? placeholderOpacity
          : 0
        : 1;

      useImperativeHandle(ref, () => ({
        getNode: () => refDragSource.current
      }));

      useImperativeHandle(ref, () => ({
        getNode: () => refDragTarget.current
      }));

      return (
        <DragAndDropContext.Provider value={{ refDragSource }}>
          <div
            className={classNames('c-draggable__item', extraClassNames)}
            ref={refDragTarget}
            style={Object.assign(
              {
                transform: 'translate3d(0,0,0)'
              },
              parentStyle,
              { opacity }
            )}
          >
            {isDragging && DropComponent ? (
              <DropComponent />
            ) : (
              <Component {...props}>{children}</Component>
            )}
          </div>
        </DragAndDropContext.Provider>
      );
    }
  );

export const createDraggableItem = ({
  type,
  Component,
  parentStyle,
  ...props
}) => {
  const DraggableComponent = DefaultDraggableItem({
    Component,
    parentStyle,
    ...props
  });
  return DropTarget(
    type,
    {
      hover(props, monitor, component) {
        if (!component) {
          return null;
        }
        // node = HTML Div element from imperative API
        const node = component.getNode();
        if (!node) {
          return null;
        }
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = node.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        // Time to actually perform the action
        props.onDragUpdate(dragIndex, hoverIndex);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
      }
    },
    connect => ({
      connectDropTarget: connect.dropTarget()
    })
  )(
    DragSource(
      type,
      {
        beginDrag: props => {
          props.onDragStart && props.onDragStart();
          return {
            id: props.id,
            index: props.index
          };
        },
        endDrag({ onDragEnd }, monitor, component) {
          onDragEnd && onDragEnd();
        }
      },
      (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
        connectDragPreview: connect.dragPreview()
      })
    )(DraggableComponent)
  );
};
