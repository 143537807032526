import React, { Component } from 'react';

import { List, ListItem } from '../../list';
import { LinkExternal } from '../../link';
import { Icon } from '../../icon';
import { Button } from '../../button';
import Image from '../../image/Image';
import Dropzone from '../Dropzone';
import Checkbox from '../../input-checkbox-radiobutton/Checkbox';

export default class FileUploadGallery extends Component {
  render() {
    const {
      id,
      isGallery,
      isSelectable,
      order,
      files,
      disabled,
      onDeleteFile,
      onFileSelect,
      selectedFiles,
      placeholderGallery,
      labelDeleteButton = 'Delete',
      canDelete = true,
      ...props
    } = this.props;
    return (
      <List
        fileupload
        style={{ order: order }}
        extraClassNames="c-file-upload__gallery"
      >
        {files.map(file =>
          !isSelectable ? (
            <ListItem key={file.id}>
              <LinkExternal
                target="_blank"
                href={file.url ? file.url : '/'}
                className="c-file-upload__gallery-link"
              >
                <Image
                  alt={file.alt || file.filename || file.name}
                  src={file.url}
                  extraClassNames="c-file-upload__gallery-image"
                />
              </LinkExternal>

              {canDelete && (
                <Button
                  small
                  actionAlert
                  icon
                  type="button"
                  disabled={disabled}
                  onClick={() => onDeleteFile && onDeleteFile(file)}
                >
                  <Icon id="remove" title={labelDeleteButton} />
                </Button>
              )}
            </ListItem>
          ) : (
            <ListItem key={file.id}>
              <Checkbox
                uploadedImage
                name={'files'}
                id={`file-${file.id}`}
                onChange={() => onFileSelect && onFileSelect(file)}
                checked={
                  selectedFiles && selectedFiles.find(f => f.id === file.id)
                }
              >
                <Image
                  alt={file.alt || file.filename || file.name}
                  src={file.url}
                  extraClassNames="c-file-upload__gallery-image"
                />
              </Checkbox>
            </ListItem>
          )
        )}

        {isGallery && !isSelectable && (
          <Dropzone id={id} disabled={disabled} {...props} />
        )}
      </List>
    );
  }
}
