import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';

const defaultAWSConfig = {
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools: process.env.REACT_APP_AWS_USER_POOLS,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
};

export const connectCognito = async (awsconfig) => {
  const config = { ...defaultAWSConfig, ...awsconfig };
  await Amplify.configure(config);
};

export const login = async (email, password) => {
  const user = await Auth.signIn(email, password);
  return user;
};

export const getCurrentSession = async () => {
  const currentSession = await Auth.currentSession();
  const currentAccessToken = await currentSession.accessToken;
  const currentJwtToken = await currentAccessToken.jwtToken;
  return {
    authenticated: true,
    jwtToken: currentJwtToken,
  };
};

export const setNewPassword = async (cognitoUser, password) => {
  const newUser = await Auth.completeNewPassword(cognitoUser, password);
  return newUser;
};

export const getCurrentAuthenticatedUser = async () => {
  const currentUser = await Auth.currentAuthenticatedUser();
  return currentUser;
};

export const forgotPassword = async (email) => {
  const response = await Auth.forgotPassword(email);
  return response;
};

export const logout = async () => {
  await Auth.signOut();
};
