import React, { useState } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { useFiles } from '../../../services/hooks/useFiles';
import Modal from '../../../lib/components/modal/Modal';
import { CardBody, CardFooter, CardHeader } from '../../../lib/components/card';
import FileUploader from '../../../lib/containers/file-upload/FileUploader';
import { file } from '@dc-framework/js-utils';
import { ButtonsGroup, Button } from '../../../lib/components';
import { defaultFormButtons } from '../../../lib/helpers/copy';
import { FILE_TYPES } from '../../../models/File';

const { getMimeTypesByPreset, MIME_TYPES_PRESETS } = file;

const SelectFileModal = ({
  onClose,
  onSelect,
  fileType,
  ignoreFiles = [],
  ...props
}) => {
  const { files } = useFiles(fileType);
  const filteredFiles = files.filter(
    f => !ignoreFiles.find(i => i.id === f.id)
  );
  const [selectedFiles, setSelectedFiles] = useState([]);
  return (
    <Modal isOpen={true} onClose={onClose}>
      <CardHeader>
        <h3>{defaultFormButtons.SELECT_FILES}</h3>
      </CardHeader>
      <CardBody>
        <FileUploader
          {...props}
          value={filteredFiles}
          isSelectable={true}
          isGallery={fileType === FILE_TYPES.IMAGE}
          accept={getMimeTypesByPreset(MIME_TYPES_PRESETS.IMAGE)}
          onFileSelectChange={files => setSelectedFiles(files)}
        />
      </CardBody>
      <CardFooter>
        <ButtonsGroup>
          <Button
            primary
            disabled={!selectedFiles.length}
            type="button"
            onClick={() => {
              onClose && onClose();
              onSelect(selectedFiles);
            }}
          >
            {defaultFormButtons.SELECT_FILES}
          </Button>
        </ButtonsGroup>
      </CardFooter>
    </Modal>
  );
};
export default hooksObserver(SelectFileModal);
