import React from 'react';
import { Route } from 'react-router';
import Login from '../containers/pages/login';
import { LOGIN_VIEW_STATES } from '../lib/containers/auth';
import {
  ROUTE_LOGIN,
  ROUTE_FORGOT,
  ROUTE_FORGOT_SUCCESS,
  ROUTE_SET_PASSWORD
} from './RouteList';

const {
  LOGIN,
  FORGOT_LOGIN,
  FORGOT_LOGIN_SUCCESS,
  SET_PASSWORD
} = LOGIN_VIEW_STATES;

const loginRoutes = ({ tmpCognitoUser, tmpEmail }) => [
  <Route
    key={LOGIN}
    exact
    path={ROUTE_LOGIN}
    component={() => <Login type={LOGIN} />}
  />,
  <Route
    key={FORGOT_LOGIN}
    exact
    path={ROUTE_FORGOT}
    component={() => <Login type={FORGOT_LOGIN} />}
  />,
  tmpEmail && (
    <Route
      key={FORGOT_LOGIN_SUCCESS}
      exact
      path={ROUTE_FORGOT_SUCCESS}
      component={() => <Login type={FORGOT_LOGIN_SUCCESS} />}
    />
  ),
  tmpCognitoUser && (
    <Route
      key={SET_PASSWORD}
      exact
      path={ROUTE_SET_PASSWORD}
      component={() => <Login type={SET_PASSWORD} />}
    />
  )
];
export default loginRoutes;
