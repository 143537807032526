import React from 'react';
import classNames from 'classnames';
import './Button.scss';

export const Button = React.forwardRef((props, ref) => {
  const {
    children,
    extraClassNames,
    disabled,
    primary,
    secondary,
    tertiary,
    alert,
    ghost,
    link,
    rounded,
    addBlock,
    icon,
    iconRight,
    iconLeft,
    toggler,
    small,
    smallUpload,
    actionPrimary,
    actionAlert,
    ...inputProps
  } = props;
  return (
    <button
      ref={ref}
      className={classNames('c-button', props.extraClassNames, {
        'c-button--disabled': disabled,
        'c-button--primary': primary,
        'c-button--secondary': secondary,
        'c-button--tertiary': tertiary,
        'c-button--ghost': ghost,
        'c-button--link': link,
        'c-button--small': small,
        'c-button--small c-button--small-upload': smallUpload,
        'c-button--action-primary': actionPrimary,
        'c-button--action-alert': actionAlert || alert,
        'c-button--rounded': rounded,
        'c-button--icon': icon,
        'c-button--icon-right': iconRight,
        'c-button--icon-left': iconLeft,
        'c-button--toggler': toggler,
        'c-button--add-block': addBlock
      })}
      disabled={disabled}
      {...inputProps}
    >
      {/* <span className="c-button__label">{children}</span> */}
      {children}
    </button>
  );
});
