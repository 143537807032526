import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { array, form } from '@dc-framework/js-utils';

import Form from './Form';
import { FORM_ITEM_TYPES, INPUT_TYPES } from './views/FormItem';

import { ButtonsGroup } from '../../components/button';
import { Notification } from '../../components/notification';

const { arrayToObject } = array;
const { isEmailValid, isPasswordValid, isRequired } = form;

const defaultFormItems = [
  {
    key: 'firstname',
    type: FORM_ITEM_TYPES.TEXTFIELD,
    inputType: INPUT_TYPES.TEXT,
    label: 'Firstname',
    value: 'Pieter'
  },
  {
    key: 'lastname',
    type: FORM_ITEM_TYPES.TEXTFIELD,
    inputType: INPUT_TYPES.TEXT,
    label: 'Lastname',
    value: 'Van Neste',
    required: true
  },
  {
    key: 'birthdate',
    type: FORM_ITEM_TYPES.TEXTFIELD,
    inputType: INPUT_TYPES.DATE,
    label: 'Birthdate',
    value: '1988-03-14',
    validator: isRequired
  },
  {
    key: 'age',
    type: FORM_ITEM_TYPES.TEXTFIELD,
    inputType: INPUT_TYPES.NUMBER,
    label: 'Age',
    value: 30,
    required: true
  },
  {
    key: 'email',
    type: FORM_ITEM_TYPES.TEXTFIELD,
    inputType: INPUT_TYPES.EMAIL,
    label: 'E-mail address',
    value: 'vannestepieter@gmail.com',
    validator: isEmailValid
  },
  {
    key: 'password',
    type: FORM_ITEM_TYPES.TEXTFIELD,
    inputType: INPUT_TYPES.PASSWORD,
    label: 'Password',
    value: 'pieter88',
    validator: isPasswordValid
  },
  {
    key: 'vegetarian',
    type: FORM_ITEM_TYPES.CHECKBOX,
    label: 'Vegetarian',
    value: true
  }
];

export default class BasicForm extends Component {
  static propTypes = {
    card: PropTypes.bool,
    edit: PropTypes.bool,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    hasCancelButton: PropTypes.bool,
    hasSubmitButton: PropTypes.bool,
    hasResetButton: PropTypes.bool,
    labelCancelButton: PropTypes.string,
    labelSubmitButton: PropTypes.string,
    labelResetButton: PropTypes.string,
    onCancel: PropTypes.func,
    onReset: PropTypes.func,
    onChange: PropTypes.func,
    onInit: PropTypes.func,
    onSubmit: PropTypes.func,
    onBlur: PropTypes.func,
    resetOnCancel: PropTypes.bool,
    enableSubmitOnValid: PropTypes.bool,
    enableSubmitOnChange: PropTypes.bool,
    formMessages: PropTypes.object,
    errorMessage: PropTypes.string,
    autoPlaceholder: PropTypes.bool,
    autoPlaceholderLowerCase: PropTypes.bool,
    formItems: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        required: PropTypes.bool,
        controlledValue: PropTypes.bool,
        keep: PropTypes.bool,
        type: PropTypes.oneOf(Object.values(FORM_ITEM_TYPES)),
        inputType: PropTypes.oneOf(Object.values(INPUT_TYPES)),
        accept: PropTypes.string,
        fileUploadIcon: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          })
        ),
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
          PropTypes.array
        ]),
        validator: PropTypes.oneOfType([PropTypes.func, PropTypes.array])
      })
    )
  };

  state = {
    ...(this.props.formItems
      ? arrayToObject(this.props.formItems, 'key', 'value')
      : arrayToObject(defaultFormItems, 'key', 'value'))
  };

  render() {
    const {
      edit = true,
      loading = false,
      formItems = defaultFormItems,
      hasCancelButton = true,
      hasSubmitButton = true,
      hasResetButton = false,
      labelSubmitButton,
      labelCancelButton,
      labelResetButton,
      errorMessage,
      ...props
    } = this.props;
    return (
      <Form loading={loading} edit={edit} formItems={formItems} {...props}>
        {errorMessage && !loading && edit && (
          <Notification errorMessage show>
            {errorMessage}
          </Notification>
        )}
        {(hasSubmitButton || hasCancelButton || hasResetButton) && (
          <ButtonsGroup>
            {hasSubmitButton && (
              <Form.SubmitButton primary label={labelSubmitButton} />
            )}
            {hasCancelButton && (
              <Form.CancelButton secondary label={labelCancelButton} />
            )}
            {hasResetButton && (
              <Form.ResetButton secondary label={labelResetButton} />
            )}
          </ButtonsGroup>
        )}
      </Form>
    );
  }
}
