import React from 'react';
import './Notification.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class Notification extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    extraClassNames: PropTypes.string,
    show: PropTypes.bool
  };

  render() {
    const {
      extraClassNames,
      children,
      errorMessage,
      notFound,
      success,
      views,
      show
    } = this.props;
    return (
      <div
        className={classNames('c-notification', extraClassNames, {
          'c-notification--input-error': errorMessage,
          'c-notification--visible': show,
          'c-notification--not-found': notFound,
          'c-notification--success': success,
          'c-notification--views': views
        })}
      >
        {children}
      </div>
    );
  }
}

export default Notification;
