import React from 'react';
import PropTypes from 'prop-types';

const SelectOption = ({
  children,
  value,
  onClick,
  hidden,
  disabled,
  selected,
  label
}) => (
  <option
    className={'c-input-select__option'}
    value={value}
    onClick={onClick}
    hidden={hidden}
    disabled={disabled}
    selected={selected}
  >
    {children ? children : label || value}
  </option>
);

SelectOption.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ])
};

export default SelectOption;
