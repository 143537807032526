import { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo-hooks';
import { QUERY_GET_FILES } from '../graphql/file-query';
import { array } from '@dc-framework/js-utils';
import File from '../../models/File';
const { sort } = array;

export const useFiles = fileType => {
  const [files, setFiles] = useState([]);
  const queryProps = {
    query: QUERY_GET_FILES,
    variables: { type: fileType }
  };
  const { data, error, loading, refetch } = useQuery(queryProps.query, {
    variables: queryProps.variables
  });

  useEffect(() => {
    let rawFiles = data && data.getFiles;
    if (!rawFiles || !rawFiles.length) {
      rawFiles = [];
    }
    rawFiles = rawFiles.map(f => new File(f));
    rawFiles = sort(rawFiles, { keys: [{ key: 'name' }] });
    setFiles(rawFiles);
  }, [data]);

  return {
    queryProps,
    error,
    loading,
    files,
    refetch
  };
};
