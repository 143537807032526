import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Modal.scss';
import { Button } from '../button/Button';
import Icon from '../icon/Icon';

ReactModal.setAppElement('#root');

class Modal extends React.Component {
  state = {
    showModal: false
  };

  handleCloseModal = e => {
    this.setState({ showModal: false });
    if (this.props.onClose) this.props.onClose();
  };

  componentDidMount() {
    this.setState({ showModal: this.props.isOpen });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.isOpen !== this.state.showModal) {
      this.setState({ showModal: newProps.isOpen });
    }
  }

  render() {
    const { children, small, noCloseButton, closeTimeoutMS } = this.props;
    const { showModal } = this.state;
    return (
      <ReactModal
        closeTimeoutMS={closeTimeoutMS !== undefined ? closeTimeoutMS : 100}
        isOpen={showModal}
        onRequestClose={this.handleCloseModal}
        className={classNames('c-card c-card--modal', {
          'c-card--modal-small': small,
          'ReactModal__Overlay--before-close': !showModal
        })}
        overlayClassName={classNames('c-modal__overlay', {})}
      >
        {!noCloseButton && (
          <Button
            type="button"
            extraClassNames="c-modal__close-button"
            onClick={e => this.handleCloseModal(e)}
          >
            <Icon id="close" />
          </Button>
        )}
        {children}
      </ReactModal>
    );
  }
}

Modal.propTypes = {
  alert: PropTypes.bool,
  autoHeight: PropTypes.bool,
  card: PropTypes.bool,
  cardPanel: PropTypes.bool,
  cardSmall: PropTypes.bool,
  children: PropTypes.any,
  isOpen: PropTypes.bool,
  noCloseButton: PropTypes.bool,
  onClose: PropTypes.func
};

export default Modal;
