import {
  awsConfigure,
  awsLogin,
  awsLogout,
  awsSetCurrentUser,
  awsLoginSetNewPassword,
  awsForgotPassword,
} from './aws';
import { gqlQuery, gqlMutation } from './aws/app-sync';
import { QUERY_GET_ME } from './graphql';
import { restForgotPassword } from './rest';

export const AUTH_TYPES = Object.freeze({
  COGNITO: 'cognito',
});

export const API_TYPE = Object.freeze({
  GRAPH_QL: 'gql',
  REST: 'rest',
});

export let defaultApiConfig = {
  autoLoginState: true,
  authType: AUTH_TYPES.COGNITO,
  apiType: API_TYPE.GRAPH_QL,
};

export let currentApiConfig = null;
export let isLoggedIn = false;
export let userData = null;

export const initialiseAPI = async (
  { apiConfig, thirdPartyConfig } = {
    apiConfig: defaultApiConfig,
    thirdPartyConfig: {},
  }
) => {
  currentApiConfig = { ...defaultApiConfig, ...apiConfig };
  switch (currentApiConfig.authType) {
    case AUTH_TYPES.COGNITO:
      await awsConfigure({ ...thirdPartyConfig });
      if (currentApiConfig.autoLoginState) {
        await setLoginState();
      }
      break;
    default:
      console.error(
        `initialiseAPI: unknown authType '${currentApiConfig.authType}'`
      );
  }
};
const showWarningNoApiConfig = () => {
  console.warn(
    `No api config defined. You need to configure the api through 'initialiseAPI(config);'`
  );
};

export const login = async (email, password) => {
  if (!currentApiConfig) return showWarningNoApiConfig();
  switch (currentApiConfig.authType) {
    case AUTH_TYPES.COGNITO:
      const loginResponse = await awsLogin(email, password);
      if (loginResponse.error) {
        return loginResponse;
      }
      return await setUserLoginData();
    default:
      console.error(`login: unknown authType '${currentApiConfig.authType}'`);
  }
};

export const setLoginState = async () => {
  if (!currentApiConfig) return showWarningNoApiConfig();

  if (userData) {
    return userData;
  }

  switch (currentApiConfig.authType) {
    case AUTH_TYPES.COGNITO:
      const userSessionResponse = await awsSetCurrentUser();
      if (userSessionResponse.error) {
        isLoggedIn = false;
        userData = null;
        return userSessionResponse;
      }
      return await setUserLoginData();
    default:
      console.error(
        `setLoginState: unknown authType '${currentApiConfig.authType}'`
      );
  }
};

export const setUserLoginData = async () => {
  if (!currentApiConfig) return showWarningNoApiConfig();
  switch (currentApiConfig.authType) {
    case AUTH_TYPES.COGNITO:
      const userDataResponse = await getUserData();
      if (userDataResponse.error) {
        isLoggedIn = false;
        userData = null;
        return userDataResponse;
      }

      isLoggedIn = true;
      userData = userDataResponse.data.getMe;
      return userData;
    default:
      console.error(
        `setUserLoginData: unknown authType '${currentApiConfig.authType}'`
      );
  }
};

export const getUserData = async () => {
  if (!currentApiConfig) return showWarningNoApiConfig();

  switch (currentApiConfig.apiType) {
    case API_TYPE.GRAPH_QL:
      return await doQuery({ query: QUERY_GET_ME });
    default:
      console.error(
        `getUserData: unknown apiType '${currentApiConfig.apiType}'`
      );
  }
};

export const setPassword = async (user, password) => {
  if (!currentApiConfig) return showWarningNoApiConfig();
  switch (currentApiConfig.authType) {
    case AUTH_TYPES.COGNITO:
      const response = await awsLoginSetNewPassword(user, password);
      if (response.error) {
        return response;
      }
      return await setUserLoginData();
    default:
      console.error(
        `setPassword: unknown authType '${currentApiConfig.authType}'`
      );
  }
};

export const logout = async () => {
  if (!currentApiConfig) return showWarningNoApiConfig();
  switch (currentApiConfig.authType) {
    case AUTH_TYPES.COGNITO:
      return await awsLogout();
    default:
      console.error(`logout: unknown authType '${currentApiConfig.authType}'`);
  }
};

export const forgotPassword = async (email) => {
  return await restForgotPassword(email);
};

export const doQuery = async ({ gqlQueryAPI = gqlQuery, ...queryData }) => {
  try {
    return await gqlQueryAPI({ ...queryData });
  } catch (error) {
    return { error };
  }
};

export const doMutation = async ({
  gqlMutationAPI = gqlMutation,
  ...mutationData
}) => {
  try {
    return await gqlMutationAPI({ ...mutationData });
  } catch (error) {
    return { error };
  }
};
