import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

class NavItem extends React.Component {
  render() {
    const { to, children, withSubNav, subNavItem } = this.props;

    return (
      <li
        className={classNames('c-nav__item', {
          'c-nav__item-has-children': withSubNav,
          'c-nav-subnav__item': subNavItem
        })}
      >
        {withSubNav ? (
          children
        ) : (
          <NavLink
            to={to}
            className={classNames('c-nav__link', {})}
            activeClassName="is-selected"
          >
            {children}
          </NavLink>
        )}
      </li>
    );
  }
}

export default NavItem;
