import { observable, decorate, action, computed } from 'mobx';
import { persist } from 'mobx-persist';
import ModalData from '../models/ModalData';
import NotificationData, {
  NOTIFICATION_TYPES,
} from '../models/NotificationData';

class UiState {
  locale = 'en';

  pendingRequestCount = 0;
  pageTitle = '';

  modalData = null;
  notificationData = null;

  notificationSuccess = false;
  notificationError = false;

  fileModal = null;

  isCardTable = false;

  setLocale(locale) {
    this.locale = locale;
  }

  setPageTitle(pageTitle) {
    this.pageTitle = pageTitle;
  }

  get hasPendingRequests() {
    return this.pendingRequestCount > 0;
  }

  increasePendingRequest() {
    this.pendingRequestCount += 1;
  }

  decreasePendingRequest() {
    if (this.pendingRequestCount > 0) {
      this.pendingRequestCount -= 1;
    }
  }

  showModal({
    type,
    title,
    message,
    okLabel,
    okHandler,
    dismissHandler,
    dismissLabel,
    dismissButton,
    mutationData,
  }) {
    const modalData = new ModalData({
      type,
      title,
      message,
      okLabel,
      okHandler: () => {
        if (okHandler && typeof okHandler === 'function') okHandler();
        this.hideModal();
      },
      dismissLabel,
      dismissButton,
      dismissHandler: () => {
        if (dismissHandler && typeof dismissHandler === 'function')
          dismissHandler();
        this.hideModal();
      },
      mutationData,
    });
    this.setModalData(modalData);
  }

  setModalData(modalData) {
    this.modalData = modalData;
  }

  hideModal() {
    this.modalData = null;
  }

  showNotification({ message, type }, delay = 3000) {
    const notification = new NotificationData({ message, type });
    this.setNotification(notification);

    if (this.notificationTimeout) {
      clearTimeout(this.notificationTimeout);
    }
    this.notificationTimeout = setTimeout(() => {
      this.hideNotification();
    }, delay);
  }

  setNotification(notificationData) {
    this.notificationData = notificationData;
    this.notificationSuccess =
      this.notificationData.type === NOTIFICATION_TYPES.SUCCESS;
    this.notificationError =
      this.notificationData.type === NOTIFICATION_TYPES.ERROR;
  }

  hideNotification() {
    this.notificationData = null;
  }

  showSelectFileModal({ type, onSelect, ...otherProps }) {
    const fileModal = {
      type,
      onSelect: (files) => {
        this.hideSelectFileModal();
        onSelect(files);
      },
      onClose: () => {
        this.hideSelectFileModal();
      },
      otherProps,
    };
    this.setSelectFileModal(fileModal);
  }

  setSelectFileModal(fileModal) {
    this.fileModal = fileModal;
  }

  hideSelectFileModal() {
    this.fileModal = null;
  }

  setCardTableView = (value) => {
    this.isCardTable = value;
  };
}

export default UiState;

decorate(UiState, {
  locale: [persist, observable],
  pageTitle: [observable],
  setLocale: action,
  setPageTitle: action,
  pendingRequestCount: observable,
  increasePendingRequest: action,
  decreasePendingRequest: action,
  hasPendingRequests: computed,
  setModalData: action,
  hideModal: action,
  setNotification: action,
  hideNotification: action,
  modalData: observable,
  notificationData: observable,
  notificationSuccess: observable,
  notificationError: observable,
  fileModal: observable,
  setSelectFileModal: action,
  hideSelectFileModal: action,
  isCardTable: [persist, observable],
  setCardTableView: action,
});
