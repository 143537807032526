import { doMutation } from './api';
import { MUTATION_ARCHIVE_FILE } from './graphql/file-query';

export const deleter = async ({ file, onComplete = () => {} }) => {
  try {
    await archiveFileMutation({
      fileId: file.id
    });
    onComplete && onComplete({ failedFiles: [], successFiles: [file] });
  } catch (error) {
    onComplete &&
      onComplete({
        failedFiles: [{ ...file, action: 'delete' }],
        successFiles: []
      });
  }
};

export const archiveFileMutation = async variables => {
  return new Promise(async (resolve, reject) => {
    await doMutation({
      mutation: MUTATION_ARCHIVE_FILE,
      variables,
      update: (cache, { data: { archiveFile } }) => {
        if (archiveFile === true) {
          resolve(archiveFile);
        }
      }
    });
  });
};
