import React from 'react';
import classNames from 'classnames';

class Row extends React.Component {
  render() {
    const { children, tiny, small, large, huge, ...props } = this.props;
    return (
      <div
        className={classNames('o-row', {
          'o-row-tiny': tiny,
          'o-row-small': small,
          'o-row-large': large,
          'o-row-huge': huge
        })}
        {...props}
      >
        {/* a Wrapper could be use as children */}
        {children}
      </div>
    );
  }
}

export default Row;
