import React from 'react';
import classNames from 'classnames';
import './Input-Select.scss';
import { Notification } from '../notification';
// import ErrorMessage from './ErrorMessage';

const Select = React.forwardRef((props, ref) => {
  const {
    id,
    name,
    label,
    onChange,
    onBlur,
    children,
    disabled,
    secondary,
    tertiary,
    inverted,
    hasError,
    errorMessage,
    readOnly,
    extraClassNames,
    ...inputProps
  } = props;
  return (
    <div
      className={classNames('c-input c-input-select', extraClassNames, {
        'c-input--disabled': disabled,
        'c-input--readonly': readOnly,
        'c-input--error': hasError
      })}
    >
      <label htmlFor={id} className={classNames('c-input__label')}>
        {label}
      </label>
      <select
        {...inputProps}
        ref={ref}
        id={id}
        name={name}
        onChange={onChange ? onChange : e => e.stopPropagation()}
        onBlur={onBlur}
        className={classNames('c-input-select__field')}
        disabled={disabled || readOnly}
      >
        {children}
      </select>

      {errorMessage && <Notification errorMessage>{errorMessage}</Notification>}
    </div>
  );
});

export default Select;
