import React from 'react';
import classNames from 'classnames';

import './Breadcrumb.scss';

const Breadcrumb = ({ children, inTable }) => (
  <div
    className={classNames('c-breadcrumb', {
      'c-breadcrumb--in-table': inTable
    })}
  >
    {children}
  </div>
);
export default Breadcrumb;
