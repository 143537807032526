export const APP_LOCALES = {
  EN: 'en',
  NL: 'nl'
};

export const CLIENT_URL = process.env.REACT_APP_CLIENT_URL;
export const EXPORT_URL = process.env.REACT_APP_EXPORT_URL;

export const DEFAULT_LOCALE = APP_LOCALES.EN;

export const MAX_DESCRIPTION_LENGTH = 300;

export const PERSON_ROLES = Object.freeze({
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_USER: 'ROLE_USER'
});

export const CardItemType = Object.freeze({
  VIDEO: 'video',
  IMAGE: 'image',
  TEXT: 'text',
  FILE: 'file',
  YOUTUBE: 'youtube',
  MULTISKILLZ: 'multiskillz',
});

export const CardItemFileType = Object.freeze({
  VIDEO: 'video',
  IMAGE: 'image',
  FILE: 'file',
  YOUTUBE: 'youtube',
  MULTISKILLZ: 'multiskillz',
  LINK: 'link'
});

export const ckEditorConfig = {
  toolbar: [
    'heading',
    '|',
    'bold',
    'italic',
    'link',
    'blockQuote',
    '|',
    'numberedList',
    'bulletedList',
    '|',
    'undo',
    'redo'
  ],
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      {
        model: 'heading2',
        view: 'h3',
        title: 'Title',
        class: 'ck-heading_heading2'
      },
      {
        model: 'heading3',
        view: 'h4',
        title: 'Subtitle',
        class: 'ck-heading_heading3'
      }
    ]
  }
};
