export const NOTIFICATION_TYPES = Object.freeze({
  SUCCESS: 'success',
  ERROR: 'error'
});

export default class NotificationData {
  constructor({ message = '', type = NOTIFICATION_TYPES.SUCCESS }) {
    this.type = type;
    this.message = message;
  }
}
