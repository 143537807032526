import React, { useContext, useEffect } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { useCategoryTree } from '../../../services/hooks/useCategoryTree';
import { StoresContext } from '../../../stores/contexts';
import { List } from '../../../lib/components/list/List';
import { ListItem } from '../../../lib/components/list/list-item/ListItem';
import { Checkbox } from '../../../lib/components/input-checkbox-radiobutton/index';
import Loader from '../../../components/loader/Loader';
import { cahCategories } from '../../../coachathome_data';

const CategoryTree = ({ value, onChange, readOnly }) => {
  const { authStore } = useContext(StoresContext);
  const { categories, loading, error } = useCategoryTree(
    authStore.user.rootEntityId
  );
  const cardCategories = value || [];

  const isChecked = (category) => {
    return !!cardCategories.find((c) => c === category);
  };

  const onCheckUpdate = (id) => {
    let newValue = [];
    if (cardCategories.find((c) => c === id)) {
      newValue = cardCategories.filter((cd) => cd !== id);
    } else {
      newValue = [...cardCategories, id];
    }
    onChange && onChange(newValue);
  };

  // if (loading) return <Loader />;
  // if (error) return <>{`Error! ${error.message}`}</>;

  if (!categories) return <></>;

  return (
    <List extraClassNames="c-list--groups">
      {categories.map((gt) => {
        return (
          <ListItem key={gt.id}>
            <div className="c-list__group-name">{gt.name}</div>

            {gt.children.length > 0 && (
              <div className="c-list__categories">
                {gt.children.map((category) => {
                  return (
                    <Category
                      category={category}
                      key={category.id}
                      onChange={onCheckUpdate}
                      checked={isChecked(category.id)}
                      isChecked={isChecked}
                    />
                  );
                })}
              </div>
            )}
          </ListItem>
        );
      })}
    </List>
  );
};

export default hooksObserver(CategoryTree);

const Category = ({ category, checked, isChecked, onChange }) => {
  return (
    <div key={category.id} className="c-category">
      <Checkbox
        key={category.id}
        name={`category_${category.id}`}
        id={category.id}
        checked={checked}
        onChange={() => onChange(category.id)}
      >
        {category.name}
      </Checkbox>
      {category.children.length > 0 &&
        category.children.map((subCategory) => (
          <Category
            key={subCategory.id}
            category={subCategory}
            checked={isChecked(subCategory.id)}
            onChange={() => onChange(subCategory.id)}
          />
        ))}
    </div>
  );
};
