import React, { useContext, useEffect, useState } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { FormattedMessage, injectIntl } from 'react-intl';
import { array } from '@dc-framework/js-utils';
import { StoresContext } from '../../../../stores';
import { Button } from '../../../../lib/components/button/Button';
import { Row } from '../../../../lib/components/page-layout';
import { Link } from '../../../../lib/components/link';
import {
  ROUTE_GROUPS,
  ROUTE_SLUG_CARDS,
  ROUTE_TOPIC_ADD,
  ROUTE_TOPIC_EDIT
} from '../../../../routes/RouteList';
import {
  defaultFormButtons,
  defaultCardLabels
} from '../../../../lib/helpers/copy/default-copy';
import {
  MUTATION_REMOVE_TOPIC,
  updateTopicsOrder
} from '../../../../services/graphql/topic-query';
import { List, ListItem } from '../../../../lib/components/list';
import { ButtonsGroup } from '../../../../lib/components/button/ButtonsGroup';
import {
  Card,
  CardHeader,
  CardThumbnail,
  CardFooter,
  CardBody,
  CardContent,
  CardActions
} from '../../../../lib/components/card';
import { DndWrapper } from '../../../../lib/containers/dnd';
import { getPath } from '../path-generator';
import Icon from '../../../../lib/components/icon/Icon';
import { useGroup } from './useGroup';
import messages from './messages';
import mainMessages from '../../../../messages';
import ConfirmButton from '../../../../lib/containers/modal/ConfirmButton';
import { Tag } from '../../../../lib/components/input-tag';
import Image from '../../../../lib/components/image/Image';
import Loader from '../../../../components/loader/Loader';

const { sort, SORT_DATA_TYPES } = array;

const getMetaDescription = meta => {
  let description = '';
  if (meta) {
    try {
      description = JSON.parse(meta).description;
    } catch (er) {}
  }
  return description;
};

const Topics = ({ intl, groupSlug }) => {
  const { routing, uiState } = useContext(StoresContext);
  const { groupTitle, loading, error, topics, queryProps } = useGroup(
    groupSlug
  );
  const [stateTopics, setStateTopics] = useState([]);

  useEffect(() => uiState.setPageTitle(groupTitle));
  useEffect(() => setStateTopics(topics));

  if (loading) return <Loader />;
  if (error) return <>{`Error! ${error.message}`}</>;

  const sortedTopics = sort(stateTopics, {
    keys: [{ key: 'order', dataType: SORT_DATA_TYPES.NUMBER }]
  });

  return (
    <>
      <div className="o-flex o-flex--align-center o-flex--justify-space-between">
        <h2 className="u-margin-none u-h4">
          <FormattedMessage {...messages.titleLabelTopics} /> in group:{' '}
          <span className="c-page__title c-page__title--primary-dark">
            {groupSlug}
          </span>
        </h2>
        <ButtonsGroup>
          <Button ghost iconLeft onClick={() => routing.push(ROUTE_GROUPS)}>
            <Icon id="left" title="{defaultFormButtons.BACK}" />
            {defaultFormButtons.BACK}
          </Button>
          <Button
            primary
            onClick={() => routing.push(getPath(ROUTE_TOPIC_ADD, groupSlug))}
          >
            <FormattedMessage {...messages.titleLabelAddTopic} />
          </Button>
        </ButtonsGroup>
      </div>
      <Row>
        {sortedTopics && (
          <List extraClassNames={'o-layout'} draggable>
            <DndWrapper
              type="topic"
              items={sortedTopics}
              onDragUpdate={items => {
                items.forEach((item, i) => {
                  item.order = i;
                });

                items = sort(items, {
                  keys: [{ key: 'order', dataType: SORT_DATA_TYPES.NUMBER }]
                });
                setStateTopics(items);
              }}
              onDragEnd={async () => {
                await updateTopicsOrder(
                  {
                    sortItemInput: topics.map(i => ({
                      id: i.id,
                      order: i.order
                    }))
                  },
                  [queryProps]
                );
              }}
              dropComponent={() => (
                <Card horizontal empty>
                  <CardBody>
                    <FormattedMessage {...mainMessages.listDragDropHereLabel} />
                  </CardBody>
                </Card>
              )}
              placeholderOpacity={1}
            >
              {sortedTopics.map((t, i) => (
                <ListItem
                  key={t.id}
                  extraClassNames={
                    'o-layout__item u-1-of-2-at-small u-1-of-1-at-medium o-flex'
                  }
                >
                  <DndWrapper.Item id={t.id} index={i}>
                    <Card horizontal draggable>
                      <CardActions>
                        <ButtonsGroup>
                          <Link
                            buttonSmallActionPrimaryIcon
                            to={getPath(ROUTE_TOPIC_EDIT, groupSlug, t.slug)}
                          >
                            <Icon id="edit" title={defaultFormButtons.EDIT} />
                          </Link>
                          <ConfirmButton
                            small
                            actionAlert
                            icon
                            mutation={MUTATION_REMOVE_TOPIC}
                            refetchQueries={[queryProps]}
                            variables={{ entityId: t.id }}
                            confirmTitle={intl.formatMessage(
                              messages.modalConfirmRemoveTopicTitle
                            )}
                            confirmMessage={intl.formatMessage(
                              messages.modalConfirmRemoveTopicMessage
                            )}
                            update={(cache, { data }) => {
                              if (data && data.archiveEntity) {
                                uiState.showNotification({
                                  message: intl.formatMessage(
                                    messages.notificationMessageTopicRemoved
                                  )
                                });
                              }
                            }}
                          >
                            <Icon
                              id="remove"
                              title={defaultFormButtons.DELETE}
                            />
                          </ConfirmButton>
                        </ButtonsGroup>
                      </CardActions>

                      <CardHeader>
                        <Link
                          extraClassNames="c-link--images"
                          to={getPath(ROUTE_SLUG_CARDS, groupSlug, t.slug)}
                        >
                          {t.thumbnail ? (
                            <CardThumbnail
                              src={t.thumbnail}
                              alt="This is the main image"
                            />
                          ) : (
                            <Image placeholder />
                          )}
                        </Link>
                      </CardHeader>
                      <CardBody>
                        <CardContent>
                          {t.tags.length > 0 && (
                            <div className="c-card__tags u-margin-vertical-tiny">
                              {t.tags.map(tag => (
                                <Tag key={tag.id}>{tag.name}</Tag>
                              ))}
                            </div>
                          )}
                          <h3 className="c-card__title u-margin-top-small">
                            <Link
                              to={getPath(ROUTE_SLUG_CARDS, groupSlug, t.slug)}
                            >
                              {t.name}
                            </Link>
                          </h3>
                          {t.meta && (
                            <p className="c-card__description">
                              {getMetaDescription(t.meta)}
                            </p>
                          )}
                        </CardContent>
                        <CardFooter>
                          <ButtonsGroup>
                            <Link
                              breadcrumb
                              to={getPath(ROUTE_SLUG_CARDS, groupSlug, t.slug)}
                            >
                              <FormattedMessage {...messages.buttonGotoCards} />
                            </Link>
                          </ButtonsGroup>
                        </CardFooter>
                      </CardBody>
                    </Card>
                    <DndWrapper.Grabzone>
                      <Icon id="drag" small secondary />
                    </DndWrapper.Grabzone>
                  </DndWrapper.Item>
                </ListItem>
              ))}
            </DndWrapper>
          </List>
        )}
      </Row>
    </>
  );
};

export default injectIntl(hooksObserver(Topics));
