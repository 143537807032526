import React, { useContext, useEffect } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import { StoresContext } from '../../../../stores';
import { ROUTE_TOPICS } from '../../../../routes/RouteList';
import TopicForm from './TopicForm';
import messages from './messages';
import { getPath } from '../path-generator';
import { useGroup } from './useGroup';
import { refetchTopics } from '../../../../services/graphql';

const AddGroup = ({ groupSlug }) => {
  const { routing, uiState } = useContext(StoresContext);
  const { groupTitle } = useGroup(groupSlug);
  useEffect(() => uiState.setPageTitle(groupTitle));

  const goBack = () => routing.push(getPath(ROUTE_TOPICS, groupSlug));
  return (
    <>
      <div className="o-layout">
        <div className="o-layout__item u-1-of-5-at-medium">
          <h2 className="u-margin-bottom-medium">
            <FormattedMessage {...messages.titleLabelAddTopic} />
          </h2>
        </div>
        <div className="o-layout__item u-4-of-5-at-medium">
          <TopicForm
            groupSlug={groupSlug}
            onMutationComplete={async (data, hasToRefetch) => {
              if (hasToRefetch) {
                await refetchTopics(groupSlug);
              }
              goBack();
            }}
            onCancel={goBack}
          />
        </div>
      </div>
    </>
  );
};
export default hooksObserver(AddGroup);
