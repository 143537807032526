import React from 'react';
import { Input } from '../../../../lib/components/input';
import classNames from 'classnames';
import messages from '../messages';
import { injectIntl } from 'react-intl';
import { CardItemFileType } from '../../../../constants';

function YouTubeGetID(url) {
  var ID = '';
  url = url
    .replace(/(>|<)/gi, '')
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    //eslint-disable-next-line
    ID = url[2].split(/[^0-9a-z_\-]/i);
    ID = ID[0];
  } else {
    ID = url;
  }
  const ytIdCheck = new RegExp('[a-zA-Z0-9_-]{11}');
  if (ytIdCheck.test(ID)) {
    return `https://www.youtube.com/embed/${ID}?rel=0&modestbranding=1`;
  } else {
    return false;
  }
}

export const ContentBlockYouTube = injectIntl(
  ({ intl, contentBlock, width = 640, height = 360, onChange, disabled }) => {
    let url =
      contentBlock &&
      contentBlock.files &&
      contentBlock.files.length &&
      contentBlock.files[0].url;
    url = url || '';
    return (
      <>
        <Input
          disabled={disabled}
          id={contentBlock.id}
          label={intl.formatMessage(messages.articleEditorYoutubeLabel)}
          placeholder={intl.formatMessage(
            messages.articleEditorYoutubePlaceholder
          )}
          value={url}
          onChange={e => {
            contentBlock.files = [
              {
                url: e.target.value || undefined,
                type: CardItemFileType.YOUTUBE
              }
            ];
            onChange && onChange(contentBlock);
          }}
        />
        {url && (
          <div className={classNames('o-ratio o-ratio--16-by-9')}>
            <iframe
              title={contentBlock.title}
              src={YouTubeGetID(url) || ''}
              width={width}
              height={height}
              frameBorder="0"
              allowFullScreen={true}
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
            />
          </div>
        )}
      </>
    );
  }
);
