import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { setMessages } from '../../helpers/copy/default-copy';

class IntlWrapper extends Component {
  constructor(props) {
    super(props);
    setMessages(props.intl);
  }
  render() {
    const { children } = this.props;
    return <>{children}</>;
  }
}

export default injectIntl(IntlWrapper);
