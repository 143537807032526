import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Checkbox from './Checkbox';
import { Notification } from '../notification';

const callAll = (...fns) => (...args) => fns.forEach(fn => fn && fn(...args));

const CheckboxGroupContext = React.createContext();

export default class CheckboxGroup extends Component {
  static propTypes = {
    children: PropTypes.any,
    edit: PropTypes.bool,
    errorMessage: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    noTitle: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    options: PropTypes.array,
    title: PropTypes.string,
    value: PropTypes.array,
    readOnly: PropTypes.bool,
    valueError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    disabled: PropTypes.bool
  };

  static Item = ({ children, optionValue, optionLabel, ...props }) => (
    <CheckboxGroupContext.Consumer>
      {({ readOnly, isChecked, onChange, onBlur, values, id }) => (
        <Checkbox
          {...props}
          id={optionValue}
          key={optionValue}
          name={id}
          readOnly={readOnly}
          checked={isChecked(values, optionValue)}
          onChange={callAll(props.onChange, e =>
            onChange(e, optionValue, e.target.checked)
          )}
          onBlur={callAll(props.onBlur, onBlur)}
        >
          {children ? children : optionLabel}
        </Checkbox>
      )}
    </CheckboxGroupContext.Consumer>
  );

  onChange = (e, optionValue, checked) => {
    const { onChange, value } = this.props;
    const newValue = checked
      ? [...value, optionValue]
      : value.filter(v => v !== optionValue);
    onChange && onChange({ ...e, target: { checked: newValue } });
  };

  onBlur = e => {
    const { onBlur } = this.props;
    onBlur && onBlur(e);
  };

  isChecked = (values, value) =>
    values && values.find(o => o === value) ? true : false;

  render() {
    const {
      readOnly,
      options,
      children,
      id,
      value,
      valueError,
      errorMessage,
      title,
      label,
      noTitle,
      disabled
    } = this.props;
    return (
      <fieldset
        className={classNames('c-input--checkbox-group', {
          'c-input--checkbox-group-error': valueError || errorMessage
        })}
      >
        <CheckboxGroupContext.Provider
          value={{
            readOnly,
            onChange: this.onChange,
            onBlur: this.onBlur,
            isChecked: this.isChecked,
            values: value,
            id
          }}
        >
          {!noTitle && <legend>{title || label}</legend>}
          {children
            ? children
            : options &&
              options.length &&
              options.map(o => (
                <CheckboxGroup.Item
                  key={o.value}
                  optionValue={o.value}
                  optionLabel={o.label}
                  disabled={disabled}
                >
                  {o.label}
                </CheckboxGroup.Item>
              ))}
        </CheckboxGroupContext.Provider>
        {errorMessage && (
          <Notification errorMessage>{errorMessage}</Notification>
        )}
      </fieldset>
    );
  }
}
