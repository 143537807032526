import Item from './Item';
import CardItemFile from './CardItemFile';

export default class CardItem extends Item {
  type;
  constructor({ type, cardId, text, files, label, url, ...props }) {
    super({ ...props });
    this.cardId = cardId;
    this.type = type;
    this.text = text;
    this.label = label;
    this.url = url;
    this.files =
      files && files.length ? files.map(t => new CardItemFile(t)) : [];
  }
}
