import React from 'react';
import ReactDOM from 'react-dom';
import { create } from 'mobx-persist';

import { initialiseAPI } from './services/api';
import { translationMessages } from './i18n';
import * as serviceWorker from './serviceWorker';

import './index.scss';
import App from './App';
import { appSyncClient } from './services/aws/app-sync';
import {
  SimpleAppContainer,
  ApolloAppContainer
} from './lib/containers/app-containers';
import { RootStore } from './stores';
import Loader from './components/loader/Loader';
// Setup stores
const rootStore = new RootStore();

const persistStores = async () => {
  const hydrate = create();
  await hydrate('uiState', rootStore.uiState);
  // await hydrate('authStore', rootStore.authStore);
};

const preloadRender = () => {
  ReactDOM.render(<Loader />, document.getElementById('root'));
};

// Render function included as default in App
// - react-intl
// - react-router
// - react-apollo
// Will use simple app container when no credentials are found.
const renderApolloApp = props => {
  if (!appSyncClient) {
    console.log(
      'dc-framework: Simple container app used, no credentials vars found.'
    );
    renderSimpleApp(props);
    return;
  }
  ReactDOM.render(
    <ApolloAppContainer
      client={appSyncClient}
      rootStore={rootStore}
      translationMessages={translationMessages}
      {...props}
    >
      {/* inject of stores for react hooks combined with mobx */}
      <App {...props} />
    </ApolloAppContainer>,
    document.getElementById('root')
  );
};

// Render function included as default in App
// - react-intl
// - react-router
const renderSimpleApp = props => {
  rootStore.authStore.isLoggedIn = true;
  ReactDOM.render(
    <SimpleAppContainer
      rootStore={rootStore}
      translationMessages={translationMessages}
      {...props}
    >
      <App {...props} />
    </SimpleAppContainer>,
    document.getElementById('root')
  );
};

const init = async () => {
  // Show preloading before init api or other loadings
  preloadRender();

  // Hydrate stores
  await persistStores();

  // Initialises the API, use as default cognito auth and graphql calls
  // Credentials will be automatically read from env
  // All api config can be overwritten by initialiseAPI({...})
  await initialiseAPI();

  // Setup login state before first render
  rootStore.authStore.setLoginState();

  // Render the main app
  renderApolloApp();
  //renderSimpleApp();
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
init();
