import Item from './Item';
import CardItem from './CardItem';
import Tag from './Tag';

export default class Card extends Item {
  featured;
  status;
  cardItems;
  constructor({
    cardItems,
    cardEntity,
    cardEntities,
    featured,
    status,
    tags,
    date_created,
    categories,
    ...props
  }) {
    super({ ...props });
    this.cardEntity = cardEntity;
    this.cardEntities = cardEntities || [];
    this.featured = featured;
    this.status = status;
    this.cardItems =
      cardItems && cardItems.length ? cardItems.map(t => new CardItem(t)) : [];
    this.tags = tags && tags.length ? tags.map(t => new Tag(t)) : [];
    this.private = props.private || false;
    this.categories = categories || [];
    this.date_created = date_created;
  }
}
