import React from 'react';
import './Card.scss';
import classNames from 'classnames';
// import { ContentBlock } from '../content-block/ContentBlock';

class Card extends React.Component {
  render() {
    const {
      extraClassNames,
      children,
      horizontal,
      empty,
      contentBlock,
      draggable,
      login,
      modal,
      unpublished,
      published,
      modalSmall
    } = this.props;
    return (
      <div
        className={classNames('c-card', extraClassNames, {
          'c-card--horizontal': horizontal,
          'c-card--empty': empty,
          'c-card--content-block': contentBlock,
          'c-card--draggable': draggable,
          'c-card--login': login,
          'c-card--modal': modal,
          'c-card--modal c-card--modal-small': modalSmall,
          'c-card--unpublished': unpublished,
          'c-card--published': published
        })}
      >
        {children}
      </div>
    );
  }
}

export default Card;
