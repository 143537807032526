import { defineMessages } from 'react-intl';

export default defineMessages({
  titleLabelAddUser: {
    id: 'title.label.add.user',
    defaultMessage: 'Add user',
  },
  titleLabelEditUser: {
    id: 'title.label.edit.user',
    defaultMessage: 'Edit user',
  },
  titleLabelUsers: {
    id: 'title.label.users',
    defaultMessage: 'Users',
  },
  notificationMessageUserSaved: {
    id: 'notification.message.user.saved',
    defaultMessage: 'User saved',
  },
  notificationMessageUserRemoved: {
    id: 'notification.message.user.removed',
    defaultMessage: 'User removed',
  },
  modalConfirmRemoveUserTitle: {
    id: 'modal.confirm.remove.user.title',
    defaultMessage: 'Remove user',
  },
  modalConfirmRemoveUserMessage: {
    id: 'modal.confirm.remove.user.message',
    defaultMessage: 'Are you sure you want to delete this user?',
  },
  notificationMessageCategorySaved: {
    id: 'notification.message.category.saved',
    defaultMessage: 'Category saved',
  },
});
