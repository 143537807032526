import React, { Component } from 'react';
import classNames from 'classnames';
import 'date-input-polyfill';
import './Input.scss';
import { browser } from '@dc-framework/js-utils';
const { isDesktopSafari } = browser;

class BaseInput extends Component {
  constructor(props) {
    super(props);
    this.ref = props.innerref || React.createRef();
  }
  componentDidMount() {
    const input = this.ref.current;

    if (input && isDesktopSafari()) {
      input.addEventListener('change', this.dateChangeHandler);
    }
  }

  componentDidUpdate(prevProps) {
    const { type } = this.props;
    const input = this.ref.current;
    if (input && isDesktopSafari() && prevProps.type !== type) {
      if (type === 'date') {
        input.addEventListener('change', this.dateChangeHandler);
      } else {
        input.removeEventListener('change', this.dateChangeHandler);
      }
    }
  }

  componentWillUnmount() {
    const input = this.ref.current;

    if (input && isDesktopSafari()) {
      input.removeEventListener('change', this.dateChangeHandler);
    }
  }

  dateChangeHandler = event => {
    if (this.props.onChange) this.props.onChange(event);
  };

  render() {
    const {
      id,
      type,
      children,
      placeholder,
      autoFocus,
      readOnly,
      disabled,
      hasError,
      label,
      inputIconLeft,
      inputIconRight,
      inputIconId,
      extraClassNames,
      ...inputProps
    } = this.props;

    return (
      <div
        className={classNames('c-input', extraClassNames, {
          'c-input--readonly': readOnly,
          'c-input--disabled': disabled,
          'c-input--error': hasError,
          'c-input--icon c-input--icon-right': inputIconRight,
          'c-input--icon c-input--icon-left': inputIconLeft
          /* 'c-input--with-button': inputButton,
          'c-input--with-button c-input--with-button-merged': inputButtonMerged */
        })}
      >
        {id && label && (
          <label htmlFor={id} className={classNames('c-input__label', {})}>
            {label}
          </label>
        )}
        <div className="c-input__wrapper">
          <input
            id={id}
            readOnly={readOnly}
            disabled={disabled}
            type={type}
            ref={this.ref}
            className={classNames('c-input__field', {})}
            placeholder={placeholder}
            autoFocus={autoFocus}
            {...inputProps}
          />

          {children}
        </div>
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <BaseInput innerref={ref} {...props} />
));
