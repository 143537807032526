import React, { forwardRef } from 'react';
import classNames from 'classnames';
import './List.scss';
import { ref } from 'yup';

export const List = forwardRef(
  ({ children, extraClassNames, fileupload, draggable, stripped }, ref) => (
    <ul
      ref={ref}
      className={classNames('o-list-bare', extraClassNames, {
        'c-list--stripped': stripped,
        'c-list--file-upload': fileupload,
        'c-list--draggable': draggable,
      })}
    >
      {children}
    </ul>
  )
);
