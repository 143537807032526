import gql from 'graphql-tag';
import { doMutation } from '../api';

export const QUERY_GET_CATEGORIES = gql`
  query getCategories {
    getCategories {
      id
      parentId
      name
      slug
      order
    }
  }
`;

export const QUERY_GET_CATEGORY_BY_SLUG = gql`
  query getCategoryBySlug($slug: String!) {
    getCategoryBySlug(slug: $slug) {
      id
      parentId
      name
      slug
    }
  }
`;

export const MUTATION_ADD_CATEGORY = gql`
  mutation createCategory(
    $id: ID
    $parentId: ID
    $name: String
    $order: Int
    $status: CategoryStatus
  ) {
    createCategory(
      input: {
        id: $id
        parentId: $parentId
        name: $name
        order: $order
        status: $status
      }
    ) {
      id
      parentId
      slug
      name
    }
  }
`;

export const addCategory = async ({ refetchQueries, variables }) => {
  const hasRefetchQueries = refetchQueries && refetchQueries.length;

  return new Promise(async (resolve, reject) => {
    const mutation = MUTATION_ADD_CATEGORY;
    return await doMutation({
      mutation,
      variables,
      update: (cache, { data, data: { createCategory } }) => {
        if (createCategory && createCategory.id) {
          resolve(createCategory);
        }
      },
      refetchQueries: hasRefetchQueries ? refetchQueries : undefined,
    });
  });
};

export const MUTATION_UPDATE_CATEGORY = gql`
  mutation editCategory(
    $id: ID!
    $parentId: ID
    $name: String
    $order: Int
    $status: CategoryStatus
  ) {
    editCategory(
      input: {
        id: $id
        parentId: $parentId
        name: $name
        order: $order
        status: $status
      }
    ) {
      id
      parentId
      name
      slug
    }
  }
`;

export const updateCategory = async ({ refetchQueries, variables }) => {
  const hasRefetchQueries = refetchQueries && refetchQueries.length;

  return new Promise(async (resolve, reject) => {
    const mutation = MUTATION_UPDATE_CATEGORY;
    return await doMutation({
      mutation,
      variables,
      update: (cache, { data, data: { editCategory } }) => {
        if (editCategory && editCategory.id) {
          resolve(editCategory.id);
        }
      },
      refetchQueries: hasRefetchQueries ? refetchQueries : undefined,
    });
  });
};

export const updateCategoryOrder = async (categories) => {
  return Promise.all(
    categories.map((cat) => {
      updateCategory({
        refetchQueries: [{ query: QUERY_GET_CATEGORIES }],
        variables: { id: cat.id, order: cat.order },
      });
    })
  );
};

export const handleCardCategories = async ({
  previousCategories,
  currentCategories,
  entityId,
  cardId,
  refetchQueries,
}) => {
  const hasRefetchQueries = refetchQueries && refetchQueries.length;

  const categoriesToLink = currentCategories.filter(
    (st) => !previousCategories.find((pt) => pt === st)
  );

  const categoriesToRemove = previousCategories.filter(
    (st) => !currentCategories.find((pt) => pt === st)
  );

  await Promise.all(
    categoriesToLink.map(async (t, i) => {
      const hasToRefetch =
        i === categoriesToLink.length - 1 &&
        categoriesToRemove.length === 0 &&
        hasRefetchQueries;
      return await addCategoryCard({
        variables: {
          categoryId: t,
          cardId,
        },
        refetchQueries: hasToRefetch ? refetchQueries : undefined,
      });
    })
  );

  await Promise.all(
    categoriesToRemove.map(async (t, i) => {
      const hasToRefetch =
        i === categoriesToRemove.length - 1 && hasRefetchQueries;
      return await removeCategoryCard({
        variables: {
          categoryId: t,
          cardId,
        },
        refetchQueries: hasToRefetch ? refetchQueries : undefined,
      });
    })
  );
};

export const addCategories = async (newCategories) => {
  return await Promise.all(
    newCategories.map(async (t) => {
      return await addCategory({
        id: t.value,
        name: t.label,
      });
    })
  );
};

export const MUTATION_REMOVE_CATEGORY = gql`
  mutation archiveCategory($categoryId: ID!) {
    archiveCategory(categoryId: $categoryId)
  }
`;

export const MUTATION_ADD_CATEGORY_CARD = gql`
  mutation createCategoryCard($categoryId: ID!, $cardId: ID) {
    createCategoryCard(input: { categoryId: $categoryId, cardId: $cardId }) {
      id
    }
  }
`;

export const addCategoryCard = async ({ refetchQueries, variables }) => {
  return new Promise(async (resolve, reject) => {
    const mutation = MUTATION_ADD_CATEGORY_CARD;
    return await doMutation({
      mutation,
      variables,
      update: (cache, { data, data: { createCategoryCard } }) => {
        if (createCategoryCard && createCategoryCard.id) {
          resolve(createCategoryCard.id);
        }
      },
      refetchQueries,
    });
  });
};

export const MUTATION_REMOVE_CATEGORY_CARD = gql`
  mutation archiveCategoryCard($categoryId: ID!, $cardId: ID!) {
    archiveCategoryCard(categoryId: $categoryId, cardId: $cardId)
  }
`;

export const removeCategoryCard = async ({ refetchQueries, variables }) => {
  return new Promise(async (resolve, reject) => {
    const mutation = MUTATION_REMOVE_CATEGORY_CARD;
    return await doMutation({
      mutation,
      variables,
      update: (cache, { data, data: { archiveCategoryCard } }) => {
        if (archiveCategoryCard === true) {
          resolve(archiveCategoryCard);
        }
      },
      refetchQueries,
    });
  });
};
