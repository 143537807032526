import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import ReactDropzone from 'react-dropzone';
import Icon from '../../components/icon/Icon';
import { Button } from '../button';

export const FILE_UPLOAD_ICON = Object.freeze({
  FILE: 'icon-add-file',
  DOC: 'icon-doc-file'
});

export const FILE_UPLOAD_DROP_STATE = Object.freeze({
  DEFAULT: 'default',
  DRAGGING: 'dragging',
  REJECT: 'reject',
  UPLOADING: 'uploading'
});

export default class Dropzone extends Component {
  onFilesSelected = selectedFiles => {
    const { onFilesSelected } = this.props;
    this.setState({ selectedFiles: [] });
    onFilesSelected && onFilesSelected(selectedFiles);
  };

  render() {
    const {
      id,
      order,
      placeholder,
      accept = '',
      fileUploadIcon = 'doc',
      uploading,
      uploadingPercentage,
      multiple = false,
      onDrop,
      disabled,
      onBrowseFileManager,
      labelFileManager
    } = this.props;
    return (
      <>
        <ReactDropzone
          className="c-file-upload__dropzone"
          inputProps={{ id }}
          accept={accept}
          onDrop={onDrop}
          multiple={multiple}
          disabled={disabled}
          style={{ order: order }}
          onFocus={e => e.target.parentNode.classList.add('has-focus')}
          onBlur={e => e.target.parentNode.classList.remove('has-focus')}
        >
          {({ isDragActive, isDragReject }) => {
            let dropState = FILE_UPLOAD_DROP_STATE.DEFAULT;

            if (isDragActive) {
              dropState = FILE_UPLOAD_DROP_STATE.DRAGGING;
            }
            if (isDragReject) {
              dropState = FILE_UPLOAD_DROP_STATE.REJECT;
            }

            if (uploading) {
              dropState = FILE_UPLOAD_DROP_STATE.UPLOADING;
            }

            return (
              <div
                className={classNames('c-file-upload--state', {
                  'c-file-upload--rejected':
                    dropState === FILE_UPLOAD_DROP_STATE.REJECT,
                  'c-file-upload--uploading':
                    dropState === FILE_UPLOAD_DROP_STATE.UPLOADING,
                  'c-file-upload--dragging':
                    dropState === FILE_UPLOAD_DROP_STATE.DRAGGING
                })}
              >
                {dropState === FILE_UPLOAD_DROP_STATE.UPLOADING ? (
                  <>
                    <div
                      className="c-file-upload--upload-status"
                      style={{
                        maxHeight: `${Math.round(uploadingPercentage * 100)}%`
                      }}
                    />
                    {uploadingPercentage !== null && (
                      <span className="c-file-upload--upload-percentage">
                        {Math.round(uploadingPercentage * 100)} %
                      </span>
                    )}
                    {/* <Icon id="up" large /> */}
                  </>
                ) : (
                  <Fragment>
                    <Icon
                      id={fileUploadIcon}
                      large
                      secondary
                      extraClassNames="u-margin-top-small"
                    />
                    {placeholder && <span>{placeholder}</span>}
                    {onBrowseFileManager && (
                      <Button
                        type="button"
                        disabled={disabled}
                        smallUpload
                        rounded
                        ghost
                        primary
                        extraClassNames="u-margin-top-medium u-margin-bottom-small"
                        onClick={e => {
                          e.preventDefault();
                          onBrowseFileManager && onBrowseFileManager(e);
                        }}
                      >
                        {labelFileManager
                          ? labelFileManager
                          : 'Choose file(s) from the file manager'}
                      </Button>
                    )}
                  </Fragment>
                )}
              </div>
            );
          }}
        </ReactDropzone>
      </>
    );
  }
}
