export default class Person {
  id;
  lastname;
  firstname;
  email;
  entityId;
  personEntities;
  rootEntityId;
  rootEntityRoles;
  personEntityId;

  constructor(
    {
      id,
      lastname = '',
      firstname = '',
      email = '',
      personEntityId,
      personEntities = []
    },
    entityId
  ) {
    this.id = id;
    this.lastname = lastname;
    this.firstname = firstname;
    this.email = email;
    this.entityId = entityId;
    this.personEntityId = personEntityId;
    if (personEntities && personEntities.length > 0) {
      this.personEntities = personEntities;
      this.rootEntityRoles = this.activeEntity.roles;
      this.rootEntityId = this.activeEntity.entityId;
    }
  }

  getPersonEntityIdByEntityId(entityId = this.rootEntityId) {
    const personEntity = this.getPersonEntityByEntityId(entityId);
    if (personEntity) {
      return personEntity.id || null;
    }
    return null;
  }

  getPersonEntityByEntityId(entityId) {
    return this.personEntities.find(e => e.entityId === entityId);
  }

  get activeEntity() {
    if (this.entityId) {
      const entity = this.personEntities.find(
        entity => entity.entity.id === this.entityId
      );
      if (entity) {
        return entity;
      }
    }
    return this.personEntities[0];
  }
}
