import React, { Fragment, useContext, useEffect, useState } from 'react';
import { observer as hooksObserver } from 'mobx-react-lite';
import { useCategoryTree } from '../../../services/hooks/useCategoryTree';
import { StoresContext } from '../../../stores/contexts';
import { List, ListItem } from '../../../lib/components/list';
import Loader from '../../../components/loader/Loader';
import { array } from '@dc-framework/js-utils';
import CategoryGroup from './CategoryGroup';
import { closestCenter, DndContext, useDroppable } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Button, ButtonsGroup, Icon } from '../../../lib/components';
import svgUrl from '../../../assets/images/svg-symbols.svg';
import { defaultFormButtons } from '../../../lib/helpers';
import { updateCategoryOrder } from '../../../services/graphql/categories-query';
import messages from '../../pages/categories/messages';
import { injectIntl } from 'react-intl';
const { SORT_DATA_TYPES, sort } = array;

const EditableCategoryTree = ({ intl }) => {
  const { uiState, authStore } = useContext(StoresContext);
  const [reorder, setReorder] = useState(false);
  const [sortedCategories, setSortedCategories] = useState([]);
  const { categories, loading, error } = useCategoryTree(
    authStore.user.rootEntityId
  );

  useEffect(() => {
    setSortedCategories(
      sort([...categories], {
        keys: [{ key: 'order', dataType: SORT_DATA_TYPES.NUMBER }],
      })
    );
  }, [categories]);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setSortedCategories((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        return arrayMove(items, oldIndex, newIndex).map((cat, i) => ({
          ...cat,
          order: i,
        }));
      });
    }
  };

  const updatecategoryOrderHandler = async () => {
    await updateCategoryOrder(sortedCategories);
    uiState.showNotification({
      message: intl.formatMessage(messages.notificationMessageCategorySaved),
    });
  };

  if (loading) return <Loader />;
  if (error) return <>{`Error! ${error.message}`}</>;

  if (!categories) return <></>;

  return (
    <DndContext onDragEnd={handleDragEnd} collisionDetection={closestCenter}>
      <SortableContext
        items={sortedCategories}
        strategy={verticalListSortingStrategy}
      >
        <ButtonsGroup
          right
          style={reorder ? { right: 16 } : {}}
          extraClassNames="u-margin-left-medium"
        >
          <Button
            small
            actionPrimary
            icon
            extraClassNames="u-margin-vertical-none"
            onClick={() => {
              setReorder(!reorder);
              if (reorder) {
                updatecategoryOrderHandler();
              }
            }}
          >
            {reorder ? (
              <Icon
                svgLocalPath={`${svgUrl}#checkmark`}
                title={defaultFormButtons.SAVE}
              />
            ) : (
              <Icon
                svgLocalPath={`${svgUrl}#reorder`}
                title={defaultFormButtons.EDIT}
              />
            )}
          </Button>
          {reorder && (
            <Button
              small
              actionPrimary
              icon
              extraClassNames="u-margin-vertical-none"
              onClick={() => {
                setReorder(false);
              }}
            >
              <Icon
                svgLocalPath={`${svgUrl}#cancel`}
                title={defaultFormButtons.EDIT}
                small
              />
            </Button>
          )}
        </ButtonsGroup>
        <List extraClassNames="c-list--groups">
          {sortedCategories.map((cg, index) => {
            return (
              <CategoryGroup
                key={cg.id}
                categoryGroup={cg}
                index={index}
                parentReorder={reorder}
              />
            );
          })}
        </List>
      </SortableContext>
    </DndContext>
  );
};

export default injectIntl(hooksObserver(EditableCategoryTree));
