import { defineMessages } from 'react-intl';

export default defineMessages({
  titleLabelTags: {
    id: 'title.label.tags',
    defaultMessage: 'Tags'
  },
  titleLabelAddTag: {
    id: 'title.label.add.tag',
    defaultMessage: 'Add tag'
  },
  titleLabelEditTag: {
    id: 'title.label.edit.tag',
    defaultMessage: 'Edit tag'
  },
  buttonGoToTags: {
    id: 'button.goto.tags',
    defaultMessage: 'Tags'
  },
  modalConfirmRemoveTagTitle: {
    id: 'modal.confirm.remove.tag.title',
    defaultMessage: 'Remove tag'
  },
  modalConfirmRemoveTagMessage: {
    id: 'modal.confirm.remove.tag.message',
    defaultMessage: 'Are you sure you want to delete this tag?'
  },
  notificationMessageTagSaved: {
    id: 'notification.message.tag.saved',
    defaultMessage: 'Tag saved'
  },
  notificationMessageTagRemoved: {
    id: 'notification.message.tag.removed',
    defaultMessage: 'Tag removed'
  }
});
