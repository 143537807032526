import React from 'react';
import { Switch } from 'react-router';
import { inject } from 'mobx-react';

const PublicRoutes = ({
  rootStore: {
    authStore: { isLoggedIn }
  },
  children
}) => {
  if (!isLoggedIn) return <Switch>{children}</Switch>;
  return null;
};

export default inject('rootStore')(PublicRoutes);
