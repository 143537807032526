import React, { Component } from 'react';
import classNames from 'classnames';
import CreatableSelect from 'react-select/lib/Creatable';
import './InputTag.scss';

import { Notification } from '../notification';

export default class InputTag extends Component {
  handleChange = (newValue, actionMeta) => {
    const { onChange, onCreate } = this.props;
    if (actionMeta.action === 'create-option') {
      onCreate && onCreate(newValue);
    }
    let value = newValue.filter(v => v.value && v.value.trim());
    value = value.map(v => {
      v.value = v.value.trim();
      return v;
    });
    onChange && onChange(value);
  };

  render() {
    const {
      id,
      label,
      extraClassNames,
      disabled,
      readOnly,
      hasError,
      options,
      value,
      onBlur,
      onChange,
      errorMessage,
      labelCreateValue = 'Create $value',
      labelNoOptions = 'No options',
      placeholder = '',
      ...props
    } = this.props;
    return (
      <div
        className={classNames('c-input c-input-select', extraClassNames, {
          'c-input--disabled': disabled,
          'c-input--readonly': readOnly,
          'c-input--error': hasError
        })}
      >
        {label && (
          <label htmlFor={id} className={classNames('c-input__label')}>
            {label}
          </label>
        )}
        <CreatableSelect
          classNamePrefix="p-input-select"
          className="p-input-select-container"
          {...props}
          isMulti
          inputId={id}
          value={value}
          onChange={this.handleChange}
          onBlur={onBlur}
          options={options}
          isDisabled={disabled || readOnly}
          formatCreateLabel={v => labelCreateValue.replace('$value', v)}
          noOptionsMessage={() => labelNoOptions}
          placeholder={placeholder}
        />
        {errorMessage && (
          <Notification errorMessage>{errorMessage}</Notification>
        )}
      </div>
    );
  }
}
